/**
 * aliases to global functions will go in here to allow for mocking in tests.
 * please add mock to jest.setup.ts
 * ex: window.location.reload
 */

/**
 * @function isStagingLocation allows for us to write env. specific code. Be extremely careful when using this, as testing / QA could be given false positives.
 */
export const isStagingLocation = () =>
  window.location.host.split('.')[0] === 'stportal' ||
  window.location.host.split('.')[0] === 'stage'

export const getOrgslugFromLocation = () =>
  window.location.pathname.split('/')[2]

export const getLocationHost = () => window.location.host

export const getTeamsFilterOutOfLocalStorage = (
  orgSlug: string,
  username: string
) => window.localStorage.getItem(`${orgSlug}:${username}:teamsfilter`)

export const getStatusFilterOutOfLocalStorage = (
  orgSlug: string,
  username: string
) => window.localStorage.getItem(`${orgSlug}:${username}:statusfilter`)

export const getHasDismissedSHTemplateOverwriteConfirmation = ():
  | 'TRUE'
  | 'FALSE' =>
  window.localStorage.getItem('dismissedSHTemplateOverwriteConfirmation') ===
  'TRUE'
    ? 'TRUE'
    : 'FALSE'

export const getClientEnvironment = () => {
  const domain = window.location.host

  if (domain.endsWith('.dev') || domain.endsWith('.local')) {
    return 'local'
  }

  if (domain.startsWith('stportal')) {
    return 'stage'
  }

  if (domain.startsWith('plportal')) {
    return 'play'
  }

  if (
    domain.startsWith('play') ||
    domain.startsWith('stage') ||
    domain.startsWith('production')
  ) {
    return 'sandcastle'
  }

  return 'production'
}

export const getCurrentDate = () => Date.now()

export const getDate = (input: string | number) => new Date(input)
