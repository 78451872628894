import React, { memo, FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { normalizeMonitorTool } from 'utils'
import { integrationMap } from 'integration-icons/integration-map'
import { State } from 'app/reducer'
import { CardChat } from '../../cards'

export interface RowChatProps {
  dataSetID: string
  id: number
}

export const RowChat: FunctionComponent<RowChatProps> = memo(
  ({ dataSetID, id }) => {
    const date = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].date
    )
    const message = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].message
    )
    const service = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].service
    )
    const titleText = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].userIdDispName
    )

    const serviceLower = service?.toLowerCase()
    const normalizedService = normalizeMonitorTool(serviceLower)
    const iconPath = integrationMap[normalizedService]?.icon

    return (
      <CardChat
        {...{
          date,
          iconPath,
          message,
          service,
          titleText,
        }}
      />
    )
  }
)

RowChat.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
