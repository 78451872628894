import { fetchAPI } from '../../../common'
import { SPIORetryCount, getStatusPageErrorMessage } from './common'
import { StatusPageTemplateDTO } from '../types'

const fetchStatusPageTemplatesWithRetry = (
  retryCount: number,
  orgSlug: string
): Promise<StatusPageTemplateDTO[]> =>
  fetchAPI(`v1/org/${orgSlug}/statuspage/templates`).then((response) => {
    if (!response.ok) {
      if (response.status === 400) {
        if (retryCount < SPIORetryCount) {
          return fetchStatusPageTemplatesWithRetry(retryCount + 1, orgSlug)
        }
      }
      throw new Error(
        getStatusPageErrorMessage(response.status, 'GET', 'templates')
      )
    }
    return response.json()
  })

const fetchStatusPageTemplates = (orgSlug: string) =>
  fetchStatusPageTemplatesWithRetry(0, orgSlug)

export const Templates = {
  fetchStatusPageTemplates,
}
