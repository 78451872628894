import { fetchAPI, getFullPath } from '../../../common'
import { SPIORetryCount, getStatusPageErrorMessage } from './common'
import { StatusPageIncidentDTO, StatusPageIncidentPostDTO } from '../types'

const fetchStatusPageIncidentsWithRetry = (
  retryCount: number,
  orgSlug: string
): Promise<StatusPageIncidentDTO[]> =>
  fetchAPI(`v1/org/${orgSlug}/statuspage/incidents`).then((response) => {
    if (!response.ok) {
      if (response.status === 400) {
        if (retryCount < SPIORetryCount) {
          return fetchStatusPageIncidentsWithRetry(retryCount + 1, orgSlug)
        }
      }
      throw new Error(
        getStatusPageErrorMessage(response.status, 'GET', 'incidents')
      )
    }
    return response.json()
  })

const fetchStatusPageIncidents = (orgSlug: string) =>
  fetchStatusPageIncidentsWithRetry(0, orgSlug)

const postStatusPageIncidentWithRetry = (
  retryCount: number,
  orgSlug: string,
  spioIncident: StatusPageIncidentPostDTO
): Promise<StatusPageIncidentDTO> => {
  const path = 'api'
  const relUrl = `v1/org/${orgSlug}/statuspage/incidents`
  return fetch(getFullPath(path, relUrl), {
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      name: spioIncident.name,
      message: spioIncident.message,
      status: spioIncident.status,
      component_ids: spioIncident.component_ids, // eslint-disable-line camelcase
    }),
    method: 'POST',
  }).then((response) => {
    if (!response.ok) {
      if (response.status === 400) {
        if (retryCount < SPIORetryCount) {
          return postStatusPageIncidentWithRetry(
            retryCount + 1,
            orgSlug,
            spioIncident
          )
        }
      }
      throw new Error(
        getStatusPageErrorMessage(response.status, 'POST', 'incidents')
      )
    }
    return response.json()
  })
}

const postStatusPageIncident = (
  orgSlug: string,
  spioIncident: StatusPageIncidentPostDTO
) => postStatusPageIncidentWithRetry(0, orgSlug, spioIncident)

const putStatusPageIncidentWithRetry = (
  retryCount: number,
  orgSlug: string,
  spioIncident: StatusPageIncidentPostDTO
): Promise<StatusPageIncidentDTO> => {
  const path = 'api'
  const relUrl = `v1/org/${orgSlug}/statuspage/incidents/${spioIncident.id}`
  return fetch(getFullPath(path, relUrl), {
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      name: spioIncident.name,
      message: spioIncident.message,
      status: spioIncident.status,
      component_ids: spioIncident.component_ids, // eslint-disable-line camelcase
    }),
    method: 'PUT',
  }).then((response) => {
    if (!response.ok) {
      if (response.status === 400) {
        if (retryCount < SPIORetryCount) {
          return putStatusPageIncidentWithRetry(
            retryCount + 1,
            orgSlug,
            spioIncident
          )
        }
      }
      throw new Error(
        getStatusPageErrorMessage(response.status, 'PUT', 'incidents')
      )
    }
    return response.json()
  })
}

const putStatusPageIncident = (
  orgSlug: string,
  spioIncident: StatusPageIncidentPostDTO
) => putStatusPageIncidentWithRetry(0, orgSlug, spioIncident)

export const Incidents = {
  fetchStatusPageIncidents,
  postStatusPageIncident,
  putStatusPageIncident,
}
