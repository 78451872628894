import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  font-family: 'Splunk Data Sans', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.palette.gray1};
`

const Segment = styled.div`
  display: flex;
  flex-direction: row;
`

const Count = styled.div`
  display: block;
  margin-right: 8px;
`

const Icon = styled.div`
  display: block;
  margin-right: 3px;
`

export const StyledCounterIcons = {
  Container,
  Count,
  Icon,
  Segment,
}
