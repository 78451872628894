import { fetchAPI, postAPI } from './common'

export interface RouteKeyDTO {
  id: number
  routeKey: string
  targets?: {
    teamName: string
    teamSlug: string
    policyName: string
    policySlug: string
  }[]
  isDefault: boolean
}
/**
 * function fetchOrgRouteKeys -- calls REST endpoint to list current routing keys for a given org
 * @param orgSlug required - org in question
 * @return Promise resolving to RouteKeyDTO[]
 */
export const fetchOrgRouteKeys = (orgSlug: string): Promise<RouteKeyDTO[]> =>
  fetchAPI(`v2/org/${orgSlug}/routes`).then((response) => response.json())

/**
 * function postMaintenanceModeStartAPI -- calls REST endpoint to start maintanenance mode for the given org
 * @param orgSlug required - org to put into maintenance mode
 * @param names required - a list of routing keys to put into muted mode.  If it is an empty list, it will put the entire
 *     org into maintenance mode.
 * @param purpose required - the reason for going into maintenance mode.  Freeform text.
 */
export const postMaintenanceModeStartAPI = (
  orgSlug: string,
  names: string[],
  purpose: string
) =>
  postAPI(`v1/org/${orgSlug}/maintenancemode/start`, {
    type: 'RoutingKeys',
    names,
    purpose,
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
