import {
  captureException,
  captureMessage,
  setExtras,
  setTags,
  withScope,
  Severity,
} from '@sentry/browser'
import isString from 'lodash/isString'

export interface LogErrorSentryOptions {
  level?: Severity
  info?: Parameters<typeof setExtras>[0]
  tags?: Parameters<typeof setTags>[0]
}

/** Send to error reporting service
 * @param error - JS Error or string message
 * @param options - {
 *   @param level - Severity of log. Import from 'sentry/browser'.
 *   @param info - Object containing extra information
 *   @param tags - Object containing custom tags
 * }
 */
export const logError = (
  error: Error | string,
  { level, info, tags }: LogErrorSentryOptions = {}
) => {
  let eventId: string

  withScope((scope) => {
    if (info) scope.setExtras(info)
    if (tags) scope.setTags(tags)

    eventId = isString(error)
      ? captureMessage(error, level)
      : captureException(error)
  })

  return eventId
}
