import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'app/reducer'
import { useWebsocket, websocketChatUrl, websocketMessages } from 'utils'
import { flushNewDataAction, setIsLoading } from 'slices'

const transformMessages = (
  dataSet: { [key: string]: Record<string, unknown> },
  messagesKey: string
) =>
  dataSet?.[messagesKey]
    ? Object.keys(dataSet[messagesKey])
        .map((id) => parseInt(id, 10))
        .reverse()
    : []

export const useTimeline = (dataSetID: string) => {
  const dispatch = useDispatch()

  const hasOpened = useSelector((state: State) => state.websocketChat.hasOpened)
  const websocketIsReady = useSelector((state: State) =>
    Boolean(state.websocketChat.isReady)
  )
  const hasInitialized = useSelector((state: State) =>
    Boolean(state.websocketChat[`initialized-${dataSetID}`])
  )
  const isLoading = useSelector((state: State) =>
    Boolean(state.websocketChat[`isLoading-${dataSetID}`])
  )
  const hasReachedEnd = useSelector((state: State) =>
    Boolean(state.websocketChat[`hasReachedEnd-${dataSetID}`])
  )

  const dataIDs = useSelector((state: State) =>
    transformMessages(state.websocketChat[dataSetID], 'messages')
  )
  const newDataIDs = useSelector((state: State) =>
    transformMessages(state.websocketChat[dataSetID], 'newMessages')
  )

  const { sendMessage } = useWebsocket(websocketChatUrl)

  useEffect(() => {
    const requestTimelineMessage = websocketMessages.getRequestTimelineInit(
      dataSetID
    )

    if (websocketIsReady && !hasInitialized) sendMessage(requestTimelineMessage)

    if (!hasInitialized) dispatch(setIsLoading({ dataSetID, value: true }))
  }, [dataSetID, dispatch, hasInitialized, sendMessage, websocketIsReady])

  const fetchMore = (from: number) => {
    if (hasOpened) {
      sendMessage(websocketMessages.getRequestTimelineFrom(dataSetID, from))
      dispatch(setIsLoading({ dataSetID, value: true }))
    }
  }

  const flushNewData = () => {
    dispatch(flushNewDataAction({ roomId: dataSetID }))
  }

  return {
    dataIDs,
    dataSetID,
    fetchMore,
    flushNewData,
    hasReachedEnd,
    isLoading,
    newDataCount: newDataIDs.length,
    newDataIDs,
  }
}
