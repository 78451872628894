import React, {
  FunctionComponent,
  memo,
  MouseEvent as ReactMouseEvent,
  PropsWithoutRef,
} from 'react'
import PropTypes from 'prop-types'

import { translate } from 'utils'
import { StyledHeader } from 'styles'
import { Annotations, AnnotationsProps } from '../annotations'
import { EmptyState, Header } from './alert-details-styles'
import { TableDisplay, TableProp } from '../table-display'

export interface AlertDetailsProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  annotations?: AnnotationsProps['annotations']
  id: string
  isShowingNullFields?: boolean
  nullFieldCount?: number
  onToggleNullFieldsClick?(
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ): void
  tables?: TableProp[]
}

export const AlertDetails: FunctionComponent<AlertDetailsProps> = memo(
  ({
    annotations,
    id,
    isShowingNullFields,
    nullFieldCount,
    onToggleNullFieldsClick,
    tables,
    ...rest
  }) => (
    <div {...rest}>
      <StyledHeader.Header>
        {translate('VO.AlertDetails.AlertData')}
      </StyledHeader.Header>
      {annotations && annotations.length > 0 && (
        <>
          <Header>{translate('VO.Global.Annotations')}</Header>
          <Annotations
            annotations={annotations}
            data-ext={`${id}-annotations`}
          />
        </>
      )}
      {tables ? (
        tables.map((table, index) => (
          <TableDisplay
            fields={table.fields}
            // The first table gets the show/hide button
            hasShowHide={onToggleNullFieldsClick && index === 0}
            header={table.header}
            id={id}
            isShowingNullFields={isShowingNullFields}
            key={`${id}-${table.header}`}
            nullFieldCount={nullFieldCount}
            onNullFieldClick={onToggleNullFieldsClick}
          />
        ))
      ) : (
        <EmptyState> {translate('VO.AlertDetails.PayloadIsEmpty')}</EmptyState>
      )}
    </div>
  )
)

AlertDetails.defaultProps = {
  annotations: undefined,
  isShowingNullFields: false,
  nullFieldCount: 0,
  onToggleNullFieldsClick: undefined,
  tables: undefined,
}

AlertDetails.propTypes = {
  annotations: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string.isRequired,
  isShowingNullFields: PropTypes.bool,
  nullFieldCount: PropTypes.number,
  onToggleNullFieldsClick: PropTypes.func,
  tables: PropTypes.arrayOf(PropTypes.any),
}
