/**
 * aliases to global setter functions will go in here to allow for mocking in tests.
 * please add mock to jest.setup.ts
 * ex: window.location.reload
 */

export const setTeamsFilterInLocalStorage = (
  orgSlug: string,
  username: string,
  filter: string
) => {
  window.localStorage.setItem(`${orgSlug}:${username}:teamsfilter`, filter)
}

export const setStatusFilterInLocalStorage = (
  orgSlug: string,
  username: string,
  filter: string
) => {
  window.localStorage.setItem(`${orgSlug}:${username}:statusfilter`, filter)
}

// Basic flag for if current user is a stakeholder so when they logout of the app membership
// can be aware of latest role and not show the mobile download page based on that.
export const setIsStakeholderInLocalStorage = (isStakeholder: boolean) => {
  window.localStorage.setItem(
    'isStakeholderLocalStorage',
    JSON.stringify(isStakeholder)
  )
}

export const setHasDismissedSHTemplateOverwriteConfirmation = (
  value: 'TRUE' | 'FALSE'
) =>
  window.localStorage.setItem('dismissedSHTemplateOverwriteConfirmation', value)
