import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from '@victorops/victoria'

import { State } from 'app/reducer'
import { logError } from 'entry/utils'
import { RowIncident, RowMessage } from './rows'

export interface RowComponentProps {
  dataSetID: string
  id: number
}

const RowComponent: FunctionComponent<RowComponentProps> = ({
  dataSetID,
  id,
}) => {
  const dataSet = useSelector((state: State) => state.websocketChat[dataSetID])

  const { type } = dataSet.messages[id] || dataSet.newMessages[id]

  if (type === 'STAKEHOLDER_MESSAGE') {
    return <RowMessage {...{ dataSetID, id }} />
  }
  if (type === 'INCIDENT') {
    return <RowIncident {...{ dataSetID, id }} />
  }

  throw Error('unknown message row type')
}

RowComponent.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}

export const Row = (props: RowComponentProps) => (
  <ErrorBoundary {...{ logError }}>
    <RowComponent {...props} />
  </ErrorBoundary>
)
