import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TeamDTO } from 'utils'

const initialState = {}

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state, { payload }: PayloadAction<TeamDTO[]>) => {
      return {
        ...state,
        ...payload,
      }
    },
  },
})

export const { setTeams } = teamsSlice.actions
export const { reducer: teams } = teamsSlice
