import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Message, TitleProps } from '../styles'

export const StateMessage = styled(Message)`
  color: ${({ theme }) => theme.palette.grays[1]};
`

export const AlertType = styled.span<TitleProps>`
  color: ${({ color = 'info', theme }) => theme.paletteStatus[color]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
AlertType.defaultProps = {
  color: 'info',
}

AlertType.propTypes = {
  color: PropTypes.oneOf([
    'acknowledged',
    'critical',
    'info',
    'neutral',
    'resolved',
    'recovery',
    'resolvedAlt',
    'snoozed',
    'snoozedAlt',
    'triggered',
    'triggeredAlt',
    'warning',
    'warningAlt',
  ]),
}

export const Footer = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  justify-content: flex-end;
`
