import styled from 'styled-components'

const Description = styled.div`
  color: ${({ theme }) => theme.palette.gray2};
  margin-bottom: ${({ theme }) => theme.functions.getSpace(3)};
`

const Header = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.palette.gray};
`
export const StyledHeader = {
  Header,
  Description,
}
