import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'

export interface IncidentIconProps {
  height: number
  width: number
}

export const IncidentIcon: FunctionComponent<IncidentIconProps> = ({
  height,
  width,
}) => {
  return (
    <svg className='incident-icon' fill='none' height={height} width={width}>
      <path
        clipRule='evenodd'
        d='M3.787 3.06a.665.665 0 00-.496.221A6.643 6.643 0 001.334 8a6.643 6.643 0 001.956 4.718.667.667 0 10.948-.935l.001-.001A5.315 5.315 0 012.666 8a5.314 5.314 0 011.606-3.814H4.27a.667.667 0 00-.484-1.125zm8.426 0c.163 0 .313.058.428.156l.001-.001.01.01A6.646 6.646 0 0114.666 8a6.646 6.646 0 01-2.016 4.775l-.012.012v-.001a.667.667 0 01-.9-.98l-.002-.002A5.317 5.317 0 0013.333 8a5.318 5.318 0 00-1.611-3.82l.001-.001a.667.667 0 01.49-1.119zM8.8 11.2a.8.8 0 11-1.6 0 .8.8 0 011.6 0zM7.333 4.66v4.007a.667.667 0 001.334 0V4.66a.667.667 0 10-1.334 0z'
        fill='#818285'
        fillRule='evenodd'
      />
    </svg>
  )
}

IncidentIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

IncidentIcon.defaultProps = {
  height: 16,
  width: 16,
}
