import { createSlice } from '@reduxjs/toolkit'

import { decodehtml, UserDTO } from 'utils'
import { OrgMap, OrgUserData } from './types'

const initialState: OrgMap = {
  name: '',
  slug: '',
  users: {},
  billingState: '',
  trialEnd: 0,
}

const transformUsersForOrg = (userlist: UserDTO[]) => {
  const list: OrgUserData = {}
  userlist.forEach((user: UserDTO) => {
    list[user.username] = user
  })
  return list
}

const currentOrgSlice = createSlice({
  name: 'currentOrg',
  initialState,
  reducers: {
    setUsersForOrg: (draft, { payload }: { payload: UserDTO[] }) => {
      draft.users = transformUsersForOrg(
        payload
          .filter(
            // Filter out people who have been invited but not yet accepted
            (user: UserDTO) => !user.username.includes('invited')
          )
          .map((user: UserDTO) => {
            const decodedName = decodehtml(
              `${user.firstName}:::${user.lastName}`
            ).split(':::')
            return Object.assign(user, {
              firstName: decodedName[0],
              lastName: decodedName[1],
            })
          })
      )
    },
  },
})

export const { setUsersForOrg } = currentOrgSlice.actions

export const { reducer: currentOrg } = currentOrgSlice
