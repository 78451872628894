import { init } from '@sentry/browser'

import { getClientEnvironment } from 'utils'

/**
 * Initialize error reporting client.
 */
export const initErrorReporter = () =>
  init({
    dsn:
      'https://943818d851d54c34aaee60968faf2325@o378897.ingest.sentry.io/5313186',
    environment: getClientEnvironment(),
  })
