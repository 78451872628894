import styled from 'styled-components'
import { Table, TableRow } from '@victorops/victoria'

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;

  > :first-child {
    border-top: thin solid ${({ theme }) => theme.palette.grays[4]};
  }

  table {
    margin-bottom: ${({ theme }) => theme.functions.getSpace(3)};
  }

  tr {
    height: 32px;
  }

  td {
    border-right: none;
    line-height: ${({ theme }) => theme.functions.getSpace(2)};
    min-width: ${({ theme }) => theme.functions.getSpace(25)};
  }

  /** 
   * Fix for IE11 in some cases the div is given a height of 0 and collapses into the tables around it. 
   */
  table + div {
    flex: 0 0 ${({ theme }) => theme.functions.getSpace(6)};
    box-sizing: border-box;
  }
  /** end fix for IE11 **/
`

export const AlertDetailsTable = styled(Table)`
  table-layout: fixed;
`

export const AlertDetailsTableRow = styled(TableRow)`
  word-wrap: break-word;
  white-space: pre-wrap;

  > td:first-child {
    width: 33%;
  }
`

export const Header = styled.span`
  display: block;
  border-top: thin solid ${({ theme }) => theme.palette.grays[4]};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.grays[5]};

  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: ${({ theme }) =>
    `${theme.functions.getSpace(2)} ${theme.functions.getSpace(2, -2)}`};
`

export const Footer = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  justify-content: flex-end;
  padding: ${({ theme }) =>
    `${theme.functions.getSpace(2)} ${theme.functions.getSpace(2, -2)}`};
`
