import React, {
  memo,
  FunctionComponent,
  useEffect,
  useState,
  ReactElement,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Multiselect from '@splunk/react-ui/Multiselect'

import {
  Params,
  translate,
  fetchTeamsWithPolicies,
  PolicyDTO,
  PagedPolicy,
} from 'utils'

import { setTeams } from 'slices'
import { State } from 'app/reducer'
import {
  RespondersControlGroup,
  NestedMultiselectOption,
} from './escalation-policies-select-styles'

interface EscalationPoliciesMultiSelectProps {
  selectionUpdated: (values: string[]) => void
  labelPosition?: LabelPosition
  className?: string
  hasError?: boolean
}
interface Team {
  slug: string
  name: string
  policies: PolicyDTO[]
}
type LabelPosition = 'top' | 'left'

export const EscalationPoliciesMultiSelect: FunctionComponent<EscalationPoliciesMultiSelectProps> = memo(
  ({ selectionUpdated, labelPosition, className, hasError }) => {
    const { incidentNumber, orgSlug } = useParams<Params>()
    const [escalationPolicies, setEscalationPolicies] = useState([])
    const dispatch = useDispatch()

    const teamsState = useSelector((state: State) => {
      return state.teams
    })

    const policiesPaged = useSelector(
      (state: State) =>
        state.websocketChat.incidentAckData[incidentNumber]?.policiesPaged || []
    )

    useEffect(() => {
      fetchTeamsWithPolicies(orgSlug).then((teams) => dispatch(setTeams(teams)))
    }, [dispatch, orgSlug])

    function handlePolicies(e: Event, { values }: { values: Array<string> }) {
      setEscalationPolicies(values)
      selectionUpdated(values)
    }

    function createPolicyOption(policy: PolicyDTO) {
      const policySlugs = policiesPaged.map((policyPaged: PagedPolicy) => {
        return policyPaged.POLICY.SLUG
      })
      const isPolicyInvolvedAlready = policySlugs.includes(policy.slug)
      const labelAlreadyInvolved = translate(
        'VO.PolicySelect.PolicyAlreadyInvolved',
        policy.name
      )

      return (
        <NestedMultiselectOption
          data-ext='vo-escalation-policies-select-option'
          key={policy.slug}
          disabled={isPolicyInvolvedAlready}
          label={isPolicyInvolvedAlready ? labelAlreadyInvolved : policy.name}
          value={policy.slug}
        />
      )
    }

    function generatePolicyOptions(teams: { [key: string]: Team }) {
      const options: Array<ReactElement> = []
      Object.keys(teams).forEach((index) => {
        const i = parseInt(index, 10)
        const team = teams[i]
        if (!team.policies.length) return

        if (i > 1) {
          options.push(<Multiselect.Divider key={team.slug} />)
        }

        options.push(
          <Multiselect.Heading
            data-ext={`vo-escalation-policies-select-heading-${team.name}`}
            key={team.slug}
          >
            {team.name}
          </Multiselect.Heading>
        )

        team.policies.forEach((policy) => {
          options.push(createPolicyOption(policy))
        })
      })
      return options
    }

    // Multiselect does not work with react fragments. This is built around that restriction.
    const options = generatePolicyOptions(teamsState)
    return (
      <RespondersControlGroup
        label={translate('VO.PolicySelect.TeamsPolicies')}
        labelPosition={labelPosition}
        className={className}
        error={hasError}
      >
        <Multiselect
          data-ext='vo-escalation-policies-select'
          placeholder={translate('VO.PolicySelect.SelectAnEscalationPolicy')}
          values={escalationPolicies}
          onChange={handlePolicies}
          error={hasError}
          inline
        >
          {options}
        </Multiselect>
      </RespondersControlGroup>
    )
  }
)

EscalationPoliciesMultiSelect.propTypes = {
  selectionUpdated: PropTypes.func.isRequired,
  labelPosition: PropTypes.oneOf(['left', 'top']),
  className: PropTypes.string,
  hasError: PropTypes.bool,
}
EscalationPoliciesMultiSelect.defaultProps = {
  labelPosition: 'left',
  className: '',
  hasError: false,
}
