import { createSlice } from '@reduxjs/toolkit'
import { getCurrentDate, getUserDisplayNames, getUserDisplayName } from 'utils'

export interface UserDetailsDTO {
  displayName: string
}

export interface PagingUserDTO {
  username: string
}

export interface PagingUserData {
  username: string
  dispName: string
}
export interface SimilarIncidentWithScore {
  incident: SimilarIncidentDTO
  score: number
  userDetails: { [username: string]: UserDetailsDTO }
}

export interface CorrelatedIncidentWrapper {
  incident: SimilarIncidentDTO
  userDetails: { [username: string]: UserDetailsDTO }
}

interface SimilarIncidentPolicyDTO {
  IS_DISPLAYABLE: boolean
  POLICY: {
    NAME: string
    SLUG: string
  }
  TEAM: {
    NAME: string
    SLUG: string
  }
  ACKED_BY: string
  ACKED_AT: number
  REQUIRES_ACK: boolean
}

export interface SimilarIncidentDTO {
  incidentId: number
  entityId: string
  incidentPhase: string
  displayName: string
  stateMessage: string
  entityType: string
  entityState: string
  monitorType: string
  monitorName: string
  serviceName: string
  ackedBy: string
  ackedAt: number
  resolvedBy?: string
  isMultiResponder: boolean
  isMuted: boolean
  isSnoozed: boolean
  annotationCount: number
  snoozedBy: string
  snoozedUntil: number
  alertCount: number
  paging: PagingUserDTO[]
  policies: SimilarIncidentPolicyDTO[]
  firstAlertAt: number
  ackData: {
    ACKS_EXPECTED: number
    ACKS_RECEIVED: number
    ACK_USERS: string[]
  }
}

export interface SimilarIncidentData {
  incidentId: number
  entityId: string
  incidentPhase: string
  displayName: string
  stateMessage: string
  entityType: string
  entityState: string
  monitorType: string
  monitorName: string
  manualFrom: string
  manualFromDispName: string
  serviceName: string
  ackedBy: string
  ackedByDispName: string
  ackedAt: number
  resolvedBy?: string
  resolvedByDispName?: string
  isMultiResponder: boolean
  isMuted: boolean
  isSnoozed: boolean
  annotationCount: number
  snoozedBy: string
  snoozedByDispName: string
  snoozedUntil: number
  alertCount: number
  paging: PagingUserData[]
  policies: SimilarIncidentPolicyDTO[]
  firstAlertAt: number
  ackData: {
    ACKS_EXPECTED: number
    ACKS_RECEIVED: number
    ACK_USERS: string[]
    ackedUsers: string[]
    ackedUsersDispNames: string[]
  }
}

export interface SimilarIncidents {
  similarIncidents: SimilarIncidentDTO[]
}

export interface CorrelatedIncidents {
  correlatedIncidents: SimilarIncidentDTO[]
}

interface SimilarIncidentsInitialState {
  currentIncidents: { [incidentNumber: string]: SimilarIncidentData[] }
  pastIncidents: { [incidentNumber: string]: SimilarIncidentData[] }
  lastUpdatedTimestamp: number
}

const similarIncidentsInitialState: SimilarIncidentsInitialState = {
  currentIncidents: {},
  pastIncidents: {},
  lastUpdatedTimestamp: 0,
}
const similarIncidentsSlice = createSlice({
  name: 'similarIncidents',
  initialState: similarIncidentsInitialState,
  reducers: {
    setPastIncidents(state, { payload: { incidentNumber, similarIncidents } }) {
      return {
        ...state,
        lastUpdatedTimestamp: getCurrentDate(),
        pastIncidents: {
          ...state.pastIncidents,
          [incidentNumber]:
            similarIncidents &&
            similarIncidents.map(
              // preserve the order for similar incidents as sent from the endpoint
              (similarIncident: SimilarIncidentWithScore) => {
                const { score, userDetails: userMap } = similarIncident
                const {
                  ackedBy,
                  snoozedBy,
                  resolvedBy,
                  paging,
                  monitorType,
                  monitorName,
                } = similarIncident.incident

                const {
                  ACKS_EXPECTED,
                  ACKS_RECEIVED,
                  ACK_USERS: ackedUsers,
                } = similarIncident.incident.ackData

                const ackData = {
                  ACKS_EXPECTED,
                  ACKS_RECEIVED,
                  ackedUsers,
                  ackedUsersDispNames: getUserDisplayNames(userMap, ackedUsers),
                }

                const ackedByDispName = getUserDisplayName(userMap, ackedBy)
                const snoozedByDispName = getUserDisplayName(userMap, snoozedBy)
                const resolvedByDispName = getUserDisplayName(
                  userMap,
                  resolvedBy
                )

                const pagingWithDispName: PagingUserData[] = paging.map(
                  (p: PagingUserDTO) => {
                    const dispName = getUserDisplayName(userMap, p.username)
                    return {
                      ...p,
                      dispName,
                    }
                  }
                )

                const manualFrom =
                  monitorType === 'manual'
                    ? monitorName.replace('vouser-', '')
                    : null
                const manualFromDispName = getUserDisplayName(
                  userMap,
                  manualFrom
                )

                return {
                  ...similarIncident.incident,
                  ackData,
                  score,
                  ackedByDispName,
                  snoozedByDispName,
                  resolvedByDispName,
                  paging: pagingWithDispName,
                  manualFrom,
                  manualFromDispName,
                }
              }
            ),
        },
      }
    },
    setCurrentIncidents(
      state,
      { payload: { incidentNumber, correlatedIncidents } }
    ) {
      return {
        ...state,
        lastUpdatedTimestamp: getCurrentDate(),
        currentIncidents: {
          ...state.currentIncidents,
          [incidentNumber]:
            correlatedIncidents &&
            correlatedIncidents.map(
              // preserve the order for correlated incidents as sent from the endpoint
              (correlatedIncident: CorrelatedIncidentWrapper) => {
                const {
                  ackedBy,
                  snoozedBy,
                  resolvedBy,
                  paging,
                  monitorName,
                  monitorType,
                } = correlatedIncident.incident
                const userMap = correlatedIncident.userDetails

                const {
                  ACKS_EXPECTED,
                  ACKS_RECEIVED,
                  ACK_USERS: ackedUsers,
                } = correlatedIncident.incident.ackData

                const ackData = {
                  ACKS_EXPECTED,
                  ACKS_RECEIVED,
                  ackedUsers,
                  ackedUsersDispNames: getUserDisplayNames(userMap, ackedUsers),
                }

                const ackedByDispName = getUserDisplayName(userMap, ackedBy)
                const snoozedByDispName = getUserDisplayName(userMap, snoozedBy)
                const resolvedByDispName = getUserDisplayName(
                  userMap,
                  resolvedBy
                )
                const pagingWithDispName: PagingUserData[] = paging.map(
                  (p: PagingUserDTO) => {
                    const dispName = getUserDisplayName(userMap, p.username)
                    return {
                      ...p,
                      dispName,
                    }
                  }
                )

                const manualFrom =
                  monitorType === 'manual'
                    ? monitorName.replace('vouser-', '')
                    : null
                const manualFromDispName = getUserDisplayName(
                  userMap,
                  manualFrom
                )

                return {
                  ...correlatedIncident.incident,
                  ackData,
                  ackedByDispName,
                  snoozedByDispName,
                  resolvedByDispName,
                  paging: pagingWithDispName,
                  manualFrom,
                  manualFromDispName,
                }
              }
            ),
        },
      }
    },
  },
})

export const {
  setPastIncidents,
  setCurrentIncidents,
} = similarIncidentsSlice.actions
export const { reducer: similarIncidents } = similarIncidentsSlice
