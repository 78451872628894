import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useWebsocket, websocketChatUrl, WebsocketChatMessage } from 'utils'
import {
  receivedAlertDetailReplyMessage,
  receivedChatCountUpdateMessage,
  receivedChatNotifyMessage,
  receivedConferenceBridgeUpdateMessage,
  receivedEntityStateNotifyMessage,
  receivedGroupListStateNotifyMessage,
  receivedIncidentDetailReplyMessage,
  receivedIncidentReplyMessage,
  receivedMaintenanceModeStateNotify,
  receivedPagingNotifyMessage,
  receivedStakeholderNotifyMessage,
  receivedStateNotifyMessage,
  receivedTimelineListReplyMessage,
  setChatHasOpened,
  setChatIsReady,
  setOnCallShifts,
  updateOnCallShifts,
} from 'slices'
import { State } from 'app/reducer'

export const WebsocketProvider = (): null => {
  const { connect } = useWebsocket(websocketChatUrl)
  const dispatch = useDispatch()
  const username = useSelector((state: State) => state.user.username)
  const orgSlug = useSelector((state: State) => state.currentOrg.slug)

  useEffect(() => {
    const dispatchMessage = (payload: WebsocketChatMessage) => {
      if (payload.MESSAGE === 'ENTITY_STATE_NOTIFY_MESSAGE') {
        dispatch(receivedEntityStateNotifyMessage({ payload, username }))
      } else if (
        payload.MESSAGE === 'STATE_NOTIFY_MESSAGE' &&
        payload.PAYLOAD.SYSTEM_INCIDENT_STATE
      ) {
        dispatch(receivedStateNotifyMessage(payload))
      } else if (
        payload.MESSAGE === 'STATE_NOTIFY_MESSAGE' &&
        payload.PAYLOAD.GROUP_LIST
      ) {
        dispatch(
          receivedGroupListStateNotifyMessage({ payload, username, orgSlug })
        )
      } else if (
        payload.MESSAGE === 'STATE_NOTIFY_MESSAGE' &&
        payload.PAYLOAD.ONCALL_LIST
      ) {
        dispatch(setOnCallShifts(payload.PAYLOAD))
      } else if (
        payload.MESSAGE === 'STATE_NOTIFY_MESSAGE' &&
        payload.PAYLOAD.ON_CALL_STATE_CHANGE
      ) {
        dispatch(updateOnCallShifts(payload.PAYLOAD))
      } else if (
        payload.MESSAGE === 'STATE_NOTIFY_MESSAGE' &&
        payload.PAYLOAD.MAINTENANCE_MODE_STATE
      ) {
        dispatch(receivedMaintenanceModeStateNotify(payload))
      } else if (payload.MESSAGE === 'CHAT_NOTIFY_MESSAGE') {
        dispatch(receivedChatNotifyMessage(payload))
      } else if (payload.MESSAGE === 'TIMELINE_LIST_REPLY_MESSAGE') {
        dispatch(receivedTimelineListReplyMessage(payload))
      } else if (payload.MESSAGE === 'INCIDENT_DETAIL_REPLY_MESSAGE') {
        dispatch(receivedIncidentDetailReplyMessage(payload))
      } else if (payload.MESSAGE === 'INCIDENT_REPLY') {
        dispatch(receivedIncidentReplyMessage(payload))
      } else if (payload.MESSAGE === 'ALERT_DETAIL_REPLY_MESSAGE') {
        dispatch(receivedAlertDetailReplyMessage(payload))
      } else if (payload.MESSAGE === 'PAGING_NOTIFY_MESSAGE') {
        dispatch(receivedPagingNotifyMessage(payload))
      } else if (payload.MESSAGE === 'INCIDENT_DATA_NOTIFY_MESSAGE') {
        if (
          payload.PAYLOAD.INCIDENT_DATA.some(
            // fix with https://jira.splunk.com/browse/VOI-116
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data: any) => data.CATEGORY === 'chat_count'
          )
        ) {
          dispatch(receivedChatCountUpdateMessage(payload))
        }

        if (
          payload.PAYLOAD.INCIDENT_DATA.some(
            // fix with https://jira.splunk.com/browse/VOI-116
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data: any) => data.CATEGORY === 'conferencebridge'
          )
        ) {
          dispatch(receivedConferenceBridgeUpdateMessage(payload))
        }
      } else if (payload.MESSAGE === 'STAKEHOLDER_MESSAGE_NOTIFY_MESSAGE') {
        dispatch(receivedStakeholderNotifyMessage(payload))
      }
    }

    const dispatchSetHasOpened = () => {
      dispatch(setChatHasOpened())
    }

    const dispatchSetIsReady = () => {
      dispatch(setChatIsReady({ isReady: true }))
    }

    const dispatchSetIsNotReady = () => {
      dispatch(setChatIsReady({ isReady: false }))
    }

    connect(
      dispatchMessage,
      dispatchSetHasOpened,
      dispatchSetIsReady,
      dispatchSetIsNotReady
    )
  })

  return null
}
