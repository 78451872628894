import { fetchAPI, putAPI, deleteAPI } from './common'

export interface PresetConferenceBridgeDTO {
  id: number
  orgId: string
  name: string
  isInUse: boolean
  inUseBy?: number[]
  url?: string
  phone?: string
  notes?: string
  createdAt: number
  modifiedAt: number
}

export const fetchConferenceBridgeDetails = (
  orgSlug: string,
  incidentNumber: string
) =>
  fetchAPI(
    `v1/org/${orgSlug}/incidents/${incidentNumber}/conference-bridge`
  ).then((response) => {
    if (response.status === 204) {
      return Promise.resolve({})
    }
    return response.json()
  })

export const putConferenceBridgeAPI = (
  orgSlug: string,
  incidentNumber: string,
  url: string,
  phone: string,
  notes: string
) =>
  putAPI(`v1/org/${orgSlug}/incidents/${incidentNumber}/conference-bridge`, {
    url,
    phone,
    notes,
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })

export const deleteConferenceBridgeAPI = (
  orgSlug: string,
  incidentNumber: string
) =>
  deleteAPI(
    `v1/org/${orgSlug}/incidents/${incidentNumber}/conference-bridge`
  ).then((response) => response.json())

export const fetchPresetConferenceBridges = (orgSlug: string) =>
  fetchAPI(`v1/org/${orgSlug}/conferencebridges`).then((response) =>
    response.json()
  )
