import React, { memo, useCallback, useEffect, FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import startCase from 'lodash/startCase'

import {
  websocketMessages,
  useWebsocket,
  websocketChatUrl,
  normalizeMonitorTool,
  translate,
  Params,
} from 'utils'
import { toggleIsExpanded, toggleIsShowingNullFields } from 'slices'

import { integrationMap } from 'integration-icons/integration-map'
import { State } from 'app/reducer'
import { useParams } from 'react-router-dom'
import { CardAlert } from '../../cards'

export interface RowAlertProps {
  dataSetID: string
  id: number
  resize(id: number): null
}

export const RowAlert: FunctionComponent<RowAlertProps> = memo(
  ({ dataSetID, id, resize }) => {
    const incidentInfo = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id]
    )
    const { incidentNumber } = useParams<Params>()
    const hostDisplayName = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.alertIncidentData
          ?.alertIncidentDetails?.HOSTDISPLAYNAME
    )
    const hostState = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.alertIncidentData
          ?.alertIncidentDetails?.HOSTSTATE
    )
    const hostOutput = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.alertIncidentData
          ?.alertIncidentDetails?.HOSTOUTPUT
    )

    const {
      alertType,
      annotations,
      date,
      from,
      isExpanded,
      isShowingNullFields,
      message,
      monitorTool,
      stateMessage,
      voUuid,
    } = incidentInfo

    const monitorToolLower = monitorTool?.toLowerCase()
    const normalizedMonitorTool = normalizeMonitorTool(monitorToolLower)
    const monitorToolDisplayName =
      integrationMap[normalizedMonitorTool]?.displayName
    const iconPath = integrationMap[normalizedMonitorTool]?.icon

    const alertTypeDisplay = startCase(alertType) as
      | 'Critical'
      | 'Info'
      | 'Recovery'
      | 'Warning'

    const alertInfo =
      useSelector((state: State) => state.websocketChat.alerts?.[voUuid]) || {}

    const { nullFieldCount } = alertInfo

    const alertDetailsFields = alertInfo.alertDetails

    const alertDetailsTable = alertDetailsFields
      ? [
          {
            header: translate('VO.Global.AlertFields'),
            fields: alertDetailsFields,
          },
        ]
      : []
    const strippedAlertDetailsFields = alertInfo.strippedAlertDetails
    const strippedAlertDetailsTable = strippedAlertDetailsFields
      ? [
          {
            header: translate('VO.Global.AlertFields'),
            fields: strippedAlertDetailsFields,
          },
        ]
      : []

    const voAlertDetailsFields = alertInfo.voAlertDetails

    const voAlertDetailsTable = voAlertDetailsFields
      ? [
          {
            header: translate('VO.Global.VictorOpsFields'),
            fields: voAlertDetailsFields,
          },
        ]
      : []

    const strippedVoAlertDetailsFields = alertInfo.strippedVoAlertDetails
    const strippedVoAlertDetailsTable = strippedVoAlertDetailsFields
      ? [
          {
            header: translate('VO.Global.VictorOpsFields'),
            fields: strippedVoAlertDetailsFields,
          },
        ]
      : []

    const tables = alertDetailsTable.concat(voAlertDetailsTable)
    const strippedTables = strippedAlertDetailsTable.concat(
      strippedVoAlertDetailsTable
    )

    const resizeRow = useCallback(() => {
      resize(id)
    }, [id, resize])

    const { sendMessage } = useWebsocket(websocketChatUrl)
    const dispatch = useDispatch()
    const onExpandClick = useCallback(() => {
      if (!isExpanded && !tables.length) {
        sendMessage(websocketMessages.getRequestAlertData(voUuid))
      }
      dispatch(toggleIsExpanded({ dataSetID, id }))
    }, [
      dataSetID,
      dispatch,
      id,
      isExpanded,
      sendMessage,
      tables.length,
      voUuid,
    ])
    const onToggleNullFieldsClick = useCallback(() => {
      dispatch(toggleIsShowingNullFields({ dataSetID, id }))
    }, [dataSetID, dispatch, id])

    // resize for change in displayed alert fields
    useEffect(() => {
      resizeRow()
    }, [
      alertDetailsFields,
      isExpanded,
      isShowingNullFields,
      resizeRow,
      strippedAlertDetailsFields,
      strippedVoAlertDetailsFields,
      voAlertDetailsFields,
    ])

    return (
      <CardAlert
        onLoad={resizeRow}
        {...{
          hostDisplayName,
          hostState,
          hostOutput,
          alertType,
          alertTypeDisplay,
          annotations,
          date,
          from,
          iconPath,
          id: id.toString(), // the "id" prop ends up on html elements as an attribute, which is why we turn it into a string
          isExpanded,
          isShowingNullFields,
          message,
          monitorTool,
          nullFieldCount,
          onExpandClick,
          onToggleNullFieldsClick,
          stateMessage,
          strippedTables,
          tables,
          titleText: monitorToolDisplayName,
        }}
      />
    )
  }
)

RowAlert.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  resize: PropTypes.func.isRequired,
}
