import { postAPI } from './common'

export interface ResponderDTO {
  slug: string
  type: string
}

export interface ConferenceBridgeDTO {
  id?: number
  url?: string
  phone?: string
  notes?: string
}

export const postRerouteAPI = (
  orgSlug: string,
  incident: string,
  targets: ResponderDTO[],
  addTargets = false
) =>
  postAPI(`v1/org/${orgSlug}/incidents/reroute`, {
    incident,
    targets,
    addTargets,
  })

export const postAddRespondersAPI = (
  orgSlug: string,
  incident: string,
  targets: ResponderDTO[],
  conferenceBridge: ConferenceBridgeDTO
) =>
  postAPI(`v1/org/${orgSlug}/incidents/reroute`, {
    incident,
    targets,
    addTargets: true,
    conferenceBridge,
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
