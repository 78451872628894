import { PropsWithoutRef } from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

export const gradientSlide = keyframes`
  0% { background-position: 100% 50%; } /* stylelint-disable-line plugin/no-low-performance-animation-properties */
  to { background-position: 0% 50%; } /* stylelint-disable-line plugin/no-low-performance-animation-properties */
`

export interface GradientBarProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  width?: string
}

// FIXME: fix style props https://victorops.atlassian.net/browse/PBJ-2252
export const GradientBar = styled.div<GradientBarProps>`
  animation: ${gradientSlide} 2.2s ease infinite;
  background: ${({ theme }) =>
    `0 0/300% 300% linear-gradient(-61deg, ${theme.palette.grays[4]} 40%, ${theme.palette.grays[5]} 50%, ${theme.palette.grays[4]} 60%)`};
  border-radius: ${({ theme }) => theme.functions.getSpace()};
  height: ${({ theme }) => theme.functions.getSpace(2, -2)};
  margin: ${({ theme }) => theme.functions.getSpace(0.5)} 0;
  width: ${({ width = '100%' }) => width};
`

GradientBar.defaultProps = {
  width: '100%',
}

GradientBar.propTypes = {
  width: PropTypes.string,
}
