import styled from 'styled-components'

import { Container } from '../styles'

export const ChatContainer = styled(Container)`
  border-radius: ${({ theme }) => theme.functions.getSpace(1, -2)};

  &::after {
    background: white;
    border-right: ${({ theme }) => `1px solid ${theme.palette.grays[3]}`};
    border-top: ${({ theme }) => `1px solid ${theme.palette.grays[3]}`};
    content: '';
    height: 10px;
    position: absolute;
    right: 0;
    top: 10px;
    transform: rotate(45deg) translateX(9px);
    width: 10px;
  }
`
