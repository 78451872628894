import styled from 'styled-components'
import SplunkMultiselect from '@splunk/react-ui/Multiselect'

export const DisplayUsers = styled.button`
  margin-left: 140px; /* 140 is the space of the modal padding plus the width splunk-ui applies to a controlGroup label. */
`

export const DisplayUsername = styled.span`
  color: ${({ theme }) => theme.palette.gray3};
`
export const Instructions = styled.span`
  display: block;
  text-transform: lowercase; /* splunk-ui defaults to all caps, this is overriding that. */
  color: ${({ theme }) => theme.palette.gray1};
  margin-left: ${({ theme }) => theme.functions.getSpace(2)};
`

export const Multiselect = styled(SplunkMultiselect)`
  flex-grow: 1; /* not sure why splunk-ui is not adding it here but is in escalation-policies. This brings them to parity. */
`

export const AddEntireTeamLabel = styled.span`
  float: right;
  color: ${({ theme }) => theme.palette.link}; /* #0086FF */
`
