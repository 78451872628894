import alertmanagerSVG from 'assets/integration-icons/svgs/alertmanager.svg'
import alertsiteSVG from 'assets/integration-icons/svgs/alertsite.svg'
import apiSVG from 'assets/integration-icons/svgs/api.svg'
import appdynamicsSVG from 'assets/integration-icons/svgs/appdynamics.svg'
import appopticsSVG from 'assets/integration-icons/svgs/appoptics.svg'
import apteligentSVG from 'assets/integration-icons/svgs/apteligent.svg'
import atatusSVG from 'assets/integration-icons/svgs/atatus.svg'
import awscloudwatchSVG from 'assets/integration-icons/svgs/awscloudwatch.svg'
import azureSVG from 'assets/integration-icons/svgs/azure.svg'
import azureomsSVG from 'assets/integration-icons/svgs/azureoms.svg'
import bigpandaSVG from 'assets/integration-icons/svgs/bigpanda.svg'
import bmctruesitepulseSVG from 'assets/integration-icons/svgs/bmctruesitepulse.svg'
import bugsnagSVG from 'assets/integration-icons/svgs/bugsnag.svg'
import cabotSVG from 'assets/integration-icons/svgs/cabot.svg'
import catchpointSVG from 'assets/integration-icons/svgs/catchpoint.svg'
import circonusSVG from 'assets/integration-icons/svgs/circonus.svg'
import cloudwSVG from 'assets/integration-icons/svgs/cloudw.svg'
import cloudwatchSVG from 'assets/integration-icons/svgs/cloudwatch.svg'
import cloudwisdomSVG from 'assets/integration-icons/svgs/cloudwisdom.svg'
import connectwiseautomateSVG from 'assets/integration-icons/svgs/connectwiseautomate.svg'
import coralogixSVG from 'assets/integration-icons/svgs/coralogix.svg'
import datadogSVG from 'assets/integration-icons/svgs/datadog.svg'
import deadmanssnitchSVG from 'assets/integration-icons/svgs/deadmanssnitch.svg'
import deliveryinsightsSVG from 'assets/integration-icons/svgs/deliveryinsights.svg'
import deskcomSVG from 'assets/integration-icons/svgs/deskcom.svg'
import devendpointSVG from 'assets/integration-icons/svgs/devendpoint.svg'
import dnscheckSVG from 'assets/integration-icons/svgs/dnscheck.svg'
import dotcommonitorSVG from 'assets/integration-icons/svgs/dotcommonitor.svg'
import dripstatSVG from 'assets/integration-icons/svgs/dripstat.svg'
import dsncheckSVG from 'assets/integration-icons/svgs/dsncheck.svg'
import dynatraceSVG from 'assets/integration-icons/svgs/dynatrace.svg'
import dynatracesyntheticSVG from 'assets/integration-icons/svgs/dynatracesynthetic.svg'
import elasticsearchwatcherSVG from 'assets/integration-icons/svgs/elasticsearchwatcher.svg'
import emailSVG from 'assets/integration-icons/svgs/email.svg'
import fusionreactorSVG from 'assets/integration-icons/svgs/fusionreactor.svg'
import githubSVG from 'assets/integration-icons/svgs/github.svg'
import googlevoiceSVG from 'assets/integration-icons/svgs/googlevoice.svg'
import grafanaSVG from 'assets/integration-icons/svgs/grafana.svg'
import graylogSVG from 'assets/integration-icons/svgs/graylog.svg'
import healthchecksioSVG from 'assets/integration-icons/svgs/healthchecksio.svg'
import hipchatSVG from 'assets/integration-icons/svgs/hipchat.svg'
import honeybadgerSVG from 'assets/integration-icons/svgs/honeybadger.svg'
import hostedgraphiteSVG from 'assets/integration-icons/svgs/hostedgraphite.svg'
import hpsitescopeSVG from 'assets/integration-icons/svgs/hpsitescope.svg'
import hubotSVG from 'assets/integration-icons/svgs/hubot.svg'
import humioSVG from 'assets/integration-icons/svgs/humio.svg'
import icingaSVG from 'assets/integration-icons/svgs/icinga.svg'
import instanaSVG from 'assets/integration-icons/svgs/instana.svg'
import jenkinsSVG from 'assets/integration-icons/svgs/jenkins.svg'
import jenkins2SVG from 'assets/integration-icons/svgs/jenkins2.svg'
import jiraSVG from 'assets/integration-icons/svgs/jira.svg'
import jirawebhookSVG from 'assets/integration-icons/svgs/jirawebhook.svg'
import kapacitorSVG from 'assets/integration-icons/svgs/kapacitor.svg'
import libratoSVG from 'assets/integration-icons/svgs/librato.svg'
import librenmsSVG from 'assets/integration-icons/svgs/librenms.svg'
import loadsterSVG from 'assets/integration-icons/svgs/loadster.svg'
import logentriesSVG from 'assets/integration-icons/svgs/logentries.svg'
import logglySVG from 'assets/integration-icons/svgs/loggly.svg'
import logicmonitorSVG from 'assets/integration-icons/svgs/logicmonitor.svg'
import logicmonitor2SVG from 'assets/integration-icons/svgs/logicmonitor2.svg'
import logzioSVG from 'assets/integration-icons/svgs/logzio.svg'
import mailhopSVG from 'assets/integration-icons/svgs/mailhop.svg'
import metriclySVG from 'assets/integration-icons/svgs/metricly.svg'
import monitisSVG from 'assets/integration-icons/svgs/monitis.svg'
import msteamsSVG from 'assets/integration-icons/svgs/msteams.svg'
import nagiosSVG from 'assets/integration-icons/svgs/nagios.svg'
import newrelicSVG from 'assets/integration-icons/svgs/newrelic.svg'
import observiumSVG from 'assets/integration-icons/svgs/observium.svg'
import ociSVG from 'assets/integration-icons/svgs/oci.svg'
import oktaSVG from 'assets/integration-icons/svgs/okta.svg'
import opsdashSVG from 'assets/integration-icons/svgs/opsdash.svg'
import opsviewSVG from 'assets/integration-icons/svgs/opsview.svg'
import outlyerSVG from 'assets/integration-icons/svgs/outlyer.svg'
import panoptaSVG from 'assets/integration-icons/svgs/panopta.svg'
import papertrailSVG from 'assets/integration-icons/svgs/papertrail.svg'
import phantomSVG from 'assets/integration-icons/svgs/phantom.svg'
import pingdomSVG from 'assets/integration-icons/svgs/pingdom.svg'
import pingdomemailSVG from 'assets/integration-icons/svgs/pingdomemail.svg'
import pingdomwebhookSVG from 'assets/integration-icons/svgs/pingdomwebhook.svg'
import pingometerSVG from 'assets/integration-icons/svgs/pingometer.svg'
import prometheusSVG from 'assets/integration-icons/svgs/prometheus.svg'
import prtgSVG from 'assets/integration-icons/svgs/prtg.svg'
import prtgemailSVG from 'assets/integration-icons/svgs/prtgemail.svg'
import prtgwebhookSVG from 'assets/integration-icons/svgs/prtgwebhook.svg'
import puppetenterpriseSVG from 'assets/integration-icons/svgs/puppetenterprise.svg'
import puppettasksSVG from 'assets/integration-icons/svgs/puppettasks.svg'
import rackspaceSVG from 'assets/integration-icons/svgs/rackspace.svg'
import raygunSVG from 'assets/integration-icons/svgs/raygun.svg'
import rayguncomSVG from 'assets/integration-icons/svgs/rayguncom.svg'
import rapid7SVG from 'assets/integration-icons/svgs/rapid7.svg'
import restSVG from 'assets/integration-icons/svgs/rest.svg'
import riemannSVG from 'assets/integration-icons/svgs/riemann.svg'
import rollbarSVG from 'assets/integration-icons/svgs/rollbar.svg'
import runscopeSVG from 'assets/integration-icons/svgs/runscope.svg'
import salesforceSVG from 'assets/integration-icons/svgs/salesforce.svg'
import scalyrSVG from 'assets/integration-icons/svgs/scalyr.svg'
import scoutSVG from 'assets/integration-icons/svgs/scout.svg'
import sensuSVG from 'assets/integration-icons/svgs/sensu.svg'
import sentrySVG from 'assets/integration-icons/svgs/sentry.svg'
import sentryioSVG from 'assets/integration-icons/svgs/sentryio.svg'
import servicenowSVG from 'assets/integration-icons/svgs/servicenow.svg'
import servicenow2SVG from 'assets/integration-icons/svgs/servicenow2.svg'
import servicenowexpressSVG from 'assets/integration-icons/svgs/servicenowexpress.svg'
import signalfxSVG from 'assets/integration-icons/svgs/signalfx.svg'
import signalsciencesSVG from 'assets/integration-icons/svgs/signalsciences.svg'
import site24x7SVG from 'assets/integration-icons/svgs/site24x7.svg'
import slackSVG from 'assets/integration-icons/svgs/slack.svg'
import slack2SVG from 'assets/integration-icons/svgs/slack2.svg'
import solarwindsSVG from 'assets/integration-icons/svgs/solarwinds.svg'
import splunkSVG from 'assets/integration-icons/svgs/splunk.svg'
import splunkesSVG from 'assets/integration-icons/svgs/splunkes.svg'
import splunkitsiSVG from 'assets/integration-icons/svgs/splunkitsi.svg'
import splunksiiSVG from 'assets/integration-icons/svgs/splunksii.svg'
import stackdriverSVG from 'assets/integration-icons/svgs/stackdriver.svg'
import statuscakeSVG from 'assets/integration-icons/svgs/statuscake.svg'
import statuspageSVG from 'assets/integration-icons/svgs/statuspage.svg'
import sumologicSVG from 'assets/integration-icons/svgs/sumologic.svg'
import sysdigSVG from 'assets/integration-icons/svgs/sysdig.svg'
import thousandeyesSVG from 'assets/integration-icons/svgs/thousandeyes.svg'
import threatstackSVG from 'assets/integration-icons/svgs/threatstack.svg'
import transmogSVG from 'assets/integration-icons/svgs/transmog.svg'
import transparentSVG from 'assets/integration-icons/svgs/transparent.svg'
import twilioSVG from 'assets/integration-icons/svgs/twilio.svg'
import uptimeSVG from 'assets/integration-icons/svgs/uptime.svg'
import uptimerobotSVG from 'assets/integration-icons/svgs/uptimerobot.svg'
import uservoiceSVG from 'assets/integration-icons/svgs/uservoice.svg'
import victoropsSVG from 'assets/integration-icons/svgs/victorops.svg'
import vividcortexSVG from 'assets/integration-icons/svgs/vividcortex.svg'
import wavefrontSVG from 'assets/integration-icons/svgs/wavefront.svg'
import webhooksSVG from 'assets/integration-icons/svgs/webhooks.svg'
import whatsupgoldSVG from 'assets/integration-icons/svgs/whatsupgold.svg'
import wormlySVG from 'assets/integration-icons/svgs/wormly.svg'
import zabbixSVG from 'assets/integration-icons/svgs/zabbix.svg'
import zendeskSVG from 'assets/integration-icons/svgs/zendesk.svg'
import zenossSVG from 'assets/integration-icons/svgs/zenoss.svg'
// Manually added voBlack not created by script
import voBlack from 'assets/integration-icons/svgs/victoropsblack.svg'

interface Integration {
  displayName: string
  icon: string
}
// copied from icons.js output
export const integrationMap: Record<string, Integration> = {
  alertmanager: { displayName: 'Alertmanager', icon: alertmanagerSVG },
  alertsite: { displayName: 'AlertSite', icon: alertsiteSVG },
  api: { displayName: 'API', icon: apiSVG },
  appdynamics: { displayName: 'AppDynamics', icon: appdynamicsSVG },
  appoptics: { displayName: 'AppOptics', icon: appopticsSVG },
  apteligent: { displayName: 'Apteligent', icon: apteligentSVG },
  atatus: { displayName: 'Atatus', icon: atatusSVG },
  awscloudwatch: { displayName: 'AWS CloudWatch', icon: awscloudwatchSVG },
  azure: { displayName: 'Azure', icon: azureSVG },
  azureoms: { displayName: 'Microsoft Azure OMS', icon: azureomsSVG },
  bigpanda: { displayName: 'BigPanda', icon: bigpandaSVG },
  bmctruesitepulse: {
    displayName: 'BMC Truesight Pulse',
    icon: bmctruesitepulseSVG,
  },
  bugsnag: { displayName: 'Bugsnag', icon: bugsnagSVG },
  cabot: { displayName: 'Cabot', icon: cabotSVG },
  catchpoint: { displayName: 'Catchpoint', icon: catchpointSVG },
  circonus: { displayName: 'Circonus', icon: circonusSVG },
  cloudw: { displayName: 'AWS CloudWatch', icon: cloudwSVG },
  cloudwatch: { displayName: 'AWS CloudWatch', icon: cloudwatchSVG },
  cloudwisdom: { displayName: 'CloudWisdom', icon: cloudwisdomSVG },
  connectwiseautomate: {
    displayName: 'ConnectWise Automate',
    icon: connectwiseautomateSVG,
  },
  coralogix: { displayName: 'Coralogix', icon: coralogixSVG },
  datadog: { displayName: 'Datadog', icon: datadogSVG },
  deadmanssnitch: {
    displayName: "Dead Man's Snitch",
    icon: deadmanssnitchSVG,
  },
  deliveryinsights: {
    displayName: 'Delivery Insights',
    icon: deliveryinsightsSVG,
  },
  deskcom: { displayName: 'Desk.com', icon: deskcomSVG },
  devendpoint: { displayName: 'Delivery Insights', icon: devendpointSVG },
  dnscheck: { displayName: 'DNSCheck', icon: dnscheckSVG },
  dotcommonitor: { displayName: 'Dotcom-Monitor', icon: dotcommonitorSVG },
  dripstat: { displayName: 'DripStat', icon: dripstatSVG },
  dsncheck: { displayName: 'DNSCheck', icon: dsncheckSVG },
  dynatrace: { displayName: 'Dynatrace', icon: dynatraceSVG },
  dynatracesynthetic: {
    displayName: 'Dynatrace Synthetic',
    icon: dynatracesyntheticSVG,
  },
  elasticsearchwatcher: {
    displayName: 'Elasticsearch Watcher',
    icon: elasticsearchwatcherSVG,
  },
  email: { displayName: 'Email', icon: emailSVG },
  fusionreactor: {
    displayName: 'Fusion Reactor Cloud',
    icon: fusionreactorSVG,
  },
  github: { displayName: 'GitHub', icon: githubSVG },
  googlevoice: { displayName: 'Google Voice', icon: googlevoiceSVG },
  grafana: { displayName: 'Grafana', icon: grafanaSVG },
  graylog: { displayName: 'Graylog', icon: graylogSVG },
  healthchecksio: { displayName: 'Healthchecks.io', icon: healthchecksioSVG },
  hipchat: { displayName: 'HipChat', icon: hipchatSVG },
  honeybadger: { displayName: 'HoneyBadger', icon: honeybadgerSVG },
  hostedgraphite: { displayName: 'Hosted Graphite', icon: hostedgraphiteSVG },
  hpsitescope: { displayName: 'HP SiteScope', icon: hpsitescopeSVG },
  hubot: { displayName: 'Hubot', icon: hubotSVG },
  humio: { displayName: 'Humio', icon: humioSVG },
  icinga: { displayName: 'Icinga/Icinga2', icon: icingaSVG },
  instana: { displayName: 'Instana', icon: instanaSVG },
  jenkins: { displayName: 'Jenkins', icon: jenkinsSVG },
  jenkins2: { displayName: 'Jenkins', icon: jenkins2SVG },
  jira: { displayName: 'Jira', icon: jiraSVG },
  jirawebhook: { displayName: 'Jira Webhook', icon: jirawebhookSVG },
  kapacitor: { displayName: 'Kapacitor', icon: kapacitorSVG },
  librato: { displayName: 'Librato', icon: libratoSVG },
  librenms: { displayName: 'LibreNMS', icon: librenmsSVG },
  loadster: { displayName: 'Loadster', icon: loadsterSVG },
  logentries: { displayName: 'Logentries', icon: logentriesSVG },
  loggly: { displayName: 'Loggly', icon: logglySVG },
  logicmonitor: { displayName: 'LogicMonitor', icon: logicmonitorSVG },
  logicmonitor2: { displayName: 'LogicMonitor', icon: logicmonitor2SVG },
  logzio: { displayName: 'Logz.io', icon: logzioSVG },
  mailhop: { displayName: 'Mailhop', icon: mailhopSVG },
  metricly: { displayName: 'Metricly', icon: metriclySVG },
  monitis: { displayName: 'Monitis', icon: monitisSVG },
  msteams: { displayName: 'Microsoft Teams', icon: msteamsSVG },
  nagios: { displayName: 'Nagios', icon: nagiosSVG },
  newrelic: { displayName: 'New Relic', icon: newrelicSVG },
  observium: { displayName: 'Observium', icon: observiumSVG },
  oci: { displayName: 'Oracle Cloud Infrastructure', icon: ociSVG },
  okta: { displayName: 'Okta', icon: oktaSVG },
  opsdash: { displayName: 'OpsDash', icon: opsdashSVG },
  opsview: { displayName: 'OpsView', icon: opsviewSVG },
  outlyer: { displayName: 'Outlyer', icon: outlyerSVG },
  panopta: { displayName: 'Panopta', icon: panoptaSVG },
  papertrail: { displayName: 'papertrail', icon: papertrailSVG },
  phantom: { displayName: 'Phantom', icon: phantomSVG },
  pingdom: { displayName: 'Pingdom', icon: pingdomSVG },
  pingdomemail: { displayName: 'Pingdom Email', icon: pingdomemailSVG },
  pingdomwebhook: { displayName: 'Pingdom Webhook', icon: pingdomwebhookSVG },
  pingometer: { displayName: 'Pingometer', icon: pingometerSVG },
  prometheus: { displayName: 'Prometheus', icon: prometheusSVG },
  prtg: { displayName: 'PRTG', icon: prtgSVG },
  prtgemail: { displayName: 'PRTG Email', icon: prtgemailSVG },
  prtgwebhook: { displayName: 'PRTG Webhook', icon: prtgwebhookSVG },
  puppetenterprise: {
    displayName: 'Puppet Enterprise',
    icon: puppetenterpriseSVG,
  },
  puppettasks: { displayName: 'Puppet Tasks', icon: puppettasksSVG },
  rackspace: { displayName: 'Rackspace', icon: rackspaceSVG },
  raygun: { displayName: 'Raygun', icon: raygunSVG },
  rayguncom: { displayName: 'Raygun', icon: rayguncomSVG },
  rapid7: { displayName: 'Rapid7', icon: rapid7SVG },
  rest: { displayName: 'REST', icon: restSVG },
  riemann: { displayName: 'Riemann', icon: riemannSVG },
  rollbar: { displayName: 'Rollbar', icon: rollbarSVG },
  runscope: { displayName: 'Runscope', icon: runscopeSVG },
  salesforce: { displayName: 'Salesforce', icon: salesforceSVG },
  scalyr: { displayName: 'Scalyr', icon: scalyrSVG },
  scout: { displayName: 'Scout', icon: scoutSVG },
  sensu: { displayName: 'Sensu', icon: sensuSVG },
  sentry: { displayName: 'Sentry', icon: sentrySVG },
  sentryio: { displayName: 'Sentry.io', icon: sentryioSVG },
  servicenow: { displayName: 'ServiceNow', icon: servicenowSVG },
  servicenow2: { displayName: 'ServiceNow', icon: servicenow2SVG },
  servicenowexpress: {
    displayName: 'ServiceNow Express',
    icon: servicenowexpressSVG,
  },
  signalfx: { displayName: 'Splunk Observability Cloud', icon: signalfxSVG },
  signalsciences: { displayName: 'Signal Sciences', icon: signalsciencesSVG },
  site24x7: { displayName: 'Site 24x7', icon: site24x7SVG },
  slack: { displayName: 'Slack', icon: slackSVG },
  slack2: { displayName: 'Slack', icon: slack2SVG },
  solarwinds: { displayName: 'SolarWinds', icon: solarwindsSVG },
  splunk: { displayName: 'Splunk Enterprise', icon: splunkSVG },
  splunkes: { displayName: 'Splunk ES', icon: splunkesSVG },
  splunkitsi: { displayName: 'Splunk ITSI', icon: splunkitsiSVG },
  splunksii: { displayName: 'Splunk SII', icon: splunksiiSVG },
  stackdriver: { displayName: 'Stackdriver', icon: stackdriverSVG },
  statuscake: { displayName: 'StatusCake', icon: statuscakeSVG },
  statuspage: { displayName: 'StatusPage', icon: statuspageSVG },
  sumologic: { displayName: 'Sumo Logic', icon: sumologicSVG },
  sysdig: { displayName: 'Sysdig', icon: sysdigSVG },
  thousandeyes: { displayName: 'ThousandEyes', icon: thousandeyesSVG },
  threatstack: { displayName: 'Threat Stack', icon: threatstackSVG },
  transmog: { displayName: 'VictorOps Transmogrifier', icon: transmogSVG },
  transparent: { displayName: 'Transparent', icon: transparentSVG },
  twilio: { displayName: 'Twilio', icon: twilioSVG },
  uptime: { displayName: 'Uptime', icon: uptimeSVG },
  uptimerobot: { displayName: 'Uptime Robot', icon: uptimerobotSVG },
  uservoice: { displayName: 'UserVoice', icon: uservoiceSVG },
  victorops: { displayName: 'VictorOps', icon: victoropsSVG },
  vividcortex: { displayName: 'VividCortex', icon: vividcortexSVG },
  wavefront: { displayName: 'Wavefront', icon: wavefrontSVG },
  webhooks: { displayName: 'Webhooks', icon: webhooksSVG },
  whatsupgold: { displayName: 'WhatsUp Gold', icon: whatsupgoldSVG },
  wormly: { displayName: 'Wormly', icon: wormlySVG },
  zabbix: { displayName: 'Zabbix', icon: zabbixSVG },
  zendesk: { displayName: 'Zendesk', icon: zendeskSVG },
  zenoss: { displayName: 'Zenoss', icon: zenossSVG },
  // adding for manual
  manual: { displayName: 'Manual', icon: voBlack },
  // slack2app manually added to support slack icon for chat message in timeline.
  slack2app: { displayName: 'Slack', icon: slack2SVG },
}
