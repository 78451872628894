import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    fullName: '',
    username: '',
    usernameDisplay: '',
    roles: {
      isGlobalAdmin: false,
      isAlertAdmin: false,
      isStakeholder: false,
      teamsAdministered: [],
    },
    shift: {
      current: [],
      next: [],
    },
  },
  reducers: {},
})

export const { reducer: user } = userSlice
