import React, { memo, FunctionComponent, ReactElement, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { ErrorBoundary } from '@victorops/victoria'
import { logError } from 'utils'
import { setSnackbarMessage } from 'slices'

export interface SnackbarErrorBoundaryProps {
  children: ReactElement | ReactElement[]
  errorMessage?: string
  Fallback?: ReactNode
}

/**
 * FunctionComponent SnackbarErrorBoundary
 *   Wraps Victoria's <ErrorBoundary> handling the logError and dispatching an action to set the snackbar error
 * @param children (required) - one or more child nodes that could generate errors
 * @param errorMessage (optional) - human-readable error message appropriate to the component(s) being wrapped
 *   by the boundary.  If missing, uses the default props error message.
 */
export const SnackbarErrorBoundary: FunctionComponent<SnackbarErrorBoundaryProps> = memo(
  ({ children, errorMessage, Fallback }) => {
    const dispatch = useDispatch()

    const showSnackbarError = () => {
      dispatch(
        setSnackbarMessage({
          message: errorMessage,
          severity: 'error',
        })
      )
    }

    return (
      <ErrorBoundary notify={showSnackbarError} {...{ Fallback, logError }}>
        {children}
      </ErrorBoundary>
    )
  }
)

SnackbarErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  errorMessage: PropTypes.string,
  Fallback: PropTypes.node,
}

SnackbarErrorBoundary.defaultProps = {
  errorMessage: 'An error has occurred.',
  Fallback: undefined,
}
