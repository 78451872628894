import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FlushNewDataAction,
  InitialState,
  IncidentData,
  ReceiveChatMessage,
  ReceiveSystemIncidentStateMessage,
  SetIsLoading,
  ToggleIsExpanded,
  ToggleIsShowingNullFields,
} from 'utils/websocket/websocket-chat-types'
import {
  transformAlertDetailMessageData,
  transformChatNotifyMessage,
  transformEntityStateNotifyIntoIncidentsUpdate,
  transformEntityStateNotifyMessage,
  transformChatCountUpdateMessage,
  transformIncidentCountByUser,
  transformIncidentCountByUserUpdate,
  transformIncidentDetailReply,
  transformIncidentReply,
  transformPagingNotifyMessage,
  transformStateNotifyMessage,
  transformTimelineListReply,
  transformStakeholderNotifyMessage,
  payloadHasIncidents,
} from 'utils/websocket/websocket-transforms'

const initialState: InitialState = {
  incidentAckData: {},
  incidentDetails: {},
  incidents: [],
  isLoading: true,
  hasIncidents: false,
  incidentCountByUser: {},
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMostRecentMessageID = (messages: any = {}) =>
  Object.keys(messages)
    .map((id) => parseInt(id, 10))
    .reverse()[0]

const updateIncidents = (incidents: InitialState['incidents']) =>
  incidents.map((incident) => ({
    ...incident,
    isNew: false,
    isSeen: !incident.isNew,
  }))

const websocketChatSlice = createSlice({
  name: 'websocketChat',
  initialState,
  reducers: {
    flushUpdates: (state) => ({
      ...state,
      incidents: updateIncidents(state.incidents),
    }),
    flushNewDataAction: (
      state,
      { payload: { roomId } }: PayloadAction<FlushNewDataAction>
    ) => ({
      ...state,
      [roomId]: {
        ...state[roomId],
        messages: {
          ...state[roomId].messages,
          ...state[roomId].newMessages,
        },
        newMessages: {},
      },
    }),
    /**
     * This reducer signifies that the websocket connection has opened at least once.
     */
    setChatHasOpened: (state) => ({
      ...state,
      hasOpened: true,
    }),
    /**
     * This reducer signifies that the websocket connection is ready to be used (i.e. the LOGIN_REPLY_MESSAGE has been received).
     */
    setChatIsReady: (
      draft,
      { payload: { isReady } }: PayloadAction<{ isReady: boolean }>
    ) => {
      draft.isReady = isReady
    },
    /**
     * This message signals a change in state of an incident; this handler pulls that state out and puts in store
     */
    receivedEntityStateNotifyMessage: (
      draft,
      // We have not shaped incident payloads from server yet
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { payload: { payload, username } }: PayloadAction<any>
    ) => {
      const data = transformEntityStateNotifyMessage(payload)

      const ackData = {
        ...draft.incidentAckData,
      }
      const detailsData = {
        ...draft.incidentDetails,
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.forEach((incomingIncident: any) => {
        ackData[incomingIncident.incidentId] = incomingIncident.ackData
        detailsData[incomingIncident.incidentId] = {
          ...draft.incidentDetails[incomingIncident.incidentId],
          ...incomingIncident,
        }
      })

      const tableData = transformEntityStateNotifyIntoIncidentsUpdate(
        payload,
        username,
        draft
      )

      draft.incidentAckData = ackData
      draft.incidentDetails = detailsData
      draft.incidents = [...tableData]
      draft.hasIncidents = tableData.length > 0

      draft.incidentCountByUser = transformIncidentCountByUserUpdate(
        draft.incidents
      )
    },

    receivedChatCountUpdateMessage: (
      draft,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { payload }: PayloadAction<any>
    ) => {
      const data = transformChatCountUpdateMessage(payload)

      /* eslint-disable no-param-reassign */
      draft.incidents.forEach((incident: IncidentData) => {
        if (incident.id === data.id) {
          incident.counts.chatCount = data.chatCount
        }
      })
      /* eslint-enable no-param-reassign */
    },

    /** @TODO this needs to be separated out into the StateNotify for incidentsList and the StateNotify for AckData.  This is the problem
     ** behind the "cannot read property 'ACK_DATA'" bug that comes up with the incident table
     */
    receivedStateNotifyMessage: (
      state,
      { payload }: PayloadAction<ReceiveSystemIncidentStateMessage>
    ) => ({
      ...state,
      isLoading: false,
      hasIncidents: payloadHasIncidents(payload),
      incidents: transformStateNotifyMessage(payload),
      incidentCountByUser: transformIncidentCountByUser(payload),
    }),
    setIsLoading: (
      state,
      { payload: { dataSetID, value } }: PayloadAction<SetIsLoading>
    ) => ({
      ...state,
      [`isLoading-${dataSetID}`]: value,
      [dataSetID]: {
        ...state[dataSetID],
      },
    }),
    toggleIsExpanded: (
      state,
      { payload: { dataSetID, id } }: PayloadAction<ToggleIsExpanded>
    ) => ({
      ...state,
      [dataSetID]: {
        ...state[dataSetID],
        messages: {
          ...state[dataSetID].messages,
          [id]: {
            ...state[dataSetID].messages[id],
            isExpanded: !state[dataSetID].messages[id].isExpanded,
          },
        },
      },
    }),
    toggleIsShowingNullFields: (
      state,
      { payload: { dataSetID, id } }: PayloadAction<ToggleIsShowingNullFields>
    ) => ({
      ...state,
      [dataSetID]: {
        ...state[dataSetID],
        messages: {
          ...state[dataSetID].messages,
          [id]: {
            ...state[dataSetID].messages[id],
            isShowingNullFields: !state[dataSetID].messages[id]
              .isShowingNullFields,
          },
        },
      },
    }),

    alertToggleIsShowingNullFields: (
      state,
      { payload: { incidentNumber } }: PayloadAction<{ incidentNumber: string }>
    ) => ({
      ...state,
      incidentDetails: {
        ...state.incidentDetails,
        [incidentNumber]: {
          ...state.incidentDetails[incidentNumber],
          alertIncidentData: {
            ...state.incidentDetails[incidentNumber].alertIncidentData,
            isShowingNullFields: !state.incidentDetails[incidentNumber]
              .alertIncidentData.isShowingNullFields,
          },
        },
      },
    }),

    receivedPagingNotifyMessage: (
      state,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      const roomId = payload.PAYLOAD.PAGE.ROOM_ID

      const pagingNotifyList = transformPagingNotifyMessage(payload)

      const currentDataID = getMostRecentMessageID(state[roomId].messages)
      const pagingNotifyListID = getMostRecentMessageID(pagingNotifyList)

      const messageKey =
        pagingNotifyListID > currentDataID ? 'newMessages' : 'messages'

      return {
        ...state,
        [`isLoading-${roomId}`]: false,
        [roomId]: {
          ...state[roomId],
          [messageKey]: {
            ...state[roomId][messageKey],
            ...pagingNotifyList,
          },
        },
      }
    },
    receivedStakeholderNotifyMessage: (
      state,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      const roomId = payload.PAYLOAD.STAKEHOLDER_MESSAGE.ROOM_ID

      const stakeholderNotifyList = transformStakeholderNotifyMessage(payload)

      const currentDataID = getMostRecentMessageID(state[roomId].messages)
      const stakeholderNotifyListID = getMostRecentMessageID(
        stakeholderNotifyList
      )

      const messageKey =
        stakeholderNotifyListID > currentDataID ? 'newMessages' : 'messages'

      return {
        ...state,
        [`isLoading-${roomId}`]: false,
        [roomId]: {
          ...state[roomId],
          [messageKey]: {
            ...state[roomId][messageKey],
            ...stakeholderNotifyList,
          },
        },
      }
    },
    receivedAlertDetailReplyMessage: (
      state,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      const data = transformAlertDetailMessageData(payload)

      return {
        ...state,
        alerts: data,
      }
    },
    receivedChatNotifyMessage: (
      state,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      const roomId = payload.PAYLOAD.CHAT.ROOM_ID
      const chat = transformChatNotifyMessage(payload)

      return {
        ...state,
        [roomId]: {
          ...state[roomId],
          newMessages: {
            ...state[roomId].newMessages,
            ...chat,
          },
        },
      }
    },

    receivedTimelineListReplyMessage: (
      draft,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      // key each set of timeline data to websocket room of each timeline

      const roomId = payload.PAYLOAD.ROOM_ID

      if (!payload.PAYLOAD.TIMELINE_LIST.length) {
        draft[`hasReachedEnd-${roomId}`] = true
      }
      const timelineList = transformTimelineListReply(payload)

      // some backchannels with MESSAGE === TIMELINE_LIST_REPLY_MESSAGE will be to notify us of a new chat.
      // Because of that, this needs to sometimes show the user a notification there is a new message.
      // We can tell that is the case if the most recentID we have in redux is lower then the messageID(s) that just came in.
      const currentDataID = getMostRecentMessageID(draft[roomId].messages)
      const timelineListID = getMostRecentMessageID(timelineList)

      const isNew = timelineListID > currentDataID
      const messages = !isNew ? timelineList : {}
      const newMessages = isNew ? timelineList : {}

      draft[`initialized-${roomId}`] = true
      draft[`isLoading-${roomId}`] = false
      draft[roomId].messages = {
        ...draft[roomId].messages,
        ...messages,
      }
      draft[roomId].newMessages = {
        ...draft[roomId].newMessages,
        ...newMessages,
      }
    },
    receivedIncidentReplyMessage: (
      draft,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      const incidentId = payload.PAYLOAD.INCIDENTS[0].INCIDENT_NAME
      const { ackData, ...rest } = transformIncidentReply(payload)

      draft.incidentAckData[incidentId] = ackData
      draft.incidentDetails[incidentId] = {
        ...draft.incidentDetails[incidentId],
        ...rest,
      }
    },
    receivedIncidentDetailReplyMessage: (
      state,
      { payload }: PayloadAction<ReceiveChatMessage>
    ) => {
      // INCIDENT_ID is expected in VO_INCIDENT_DETAILS which is not sent by backend
      const incidentDetailReplyPayload = payload
      incidentDetailReplyPayload.PAYLOAD.VO_INCIDENT_DETAILS.INCIDENT_ID = String(
        incidentDetailReplyPayload.PAYLOAD.INCIDENT_ID
      )

      return {
        ...state,
        incidentDetails: {
          [incidentDetailReplyPayload.PAYLOAD.INCIDENT_ID]: {
            ...state.incidentDetails[
              incidentDetailReplyPayload.PAYLOAD.INCIDENT_ID
            ],
            ...transformIncidentDetailReply(incidentDetailReplyPayload),
          },
        },
      }
    },
  },
})

export const {
  flushNewDataAction,
  flushUpdates,
  receivedEntityStateNotifyMessage,
  receivedStateNotifyMessage,
  receivedChatCountUpdateMessage,
  receivedChatNotifyMessage,
  receivedTimelineListReplyMessage,
  receivedPagingNotifyMessage,
  receivedStakeholderNotifyMessage,
  receivedAlertDetailReplyMessage,
  receivedIncidentDetailReplyMessage,
  receivedIncidentReplyMessage,
  setChatHasOpened,
  setChatIsReady,
  setIsLoading,
  toggleIsExpanded,
  toggleIsShowingNullFields,
  alertToggleIsShowingNullFields,
} = websocketChatSlice.actions
export const { reducer: websocketChat } = websocketChatSlice
