import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'

import { ListItem } from './annotation-styles'

export interface LinkAnnotationProps
  extends PropsWithoutRef<JSX.IntrinsicElements['li']> {
  fieldName: string
  fieldValue: string
  dataExt: string
}

export const LinkAnnotation: FunctionComponent<LinkAnnotationProps> = memo(
  ({ fieldName, fieldValue, dataExt }) => {
    return (
      <ListItem data-ext={`${dataExt}--annotation-link`}>
        <a
          href={fieldValue}
          target='_blank'
          rel='noopener noreferrer'
          data-ext={`${dataExt}--annotation-link--link`}
        >
          {fieldName}
        </a>
      </ListItem>
    )
  }
)

LinkAnnotation.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  dataExt: PropTypes.string.isRequired,
}
