import React, {
  memo,
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  ChangeEvent,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import {
  websocketMessages,
  Params,
  translate,
  useWebsocket,
  fetchSuggestedResponders,
  fetchTeams,
  fetchTeamMembers,
  fetchUsers,
  websocketChatUrl,
  TeamDTO,
  UserDTO,
} from 'utils'
import { State } from 'app/reducer'

import uniq from 'lodash/uniq'
import { NestedMultiselectOption } from '../escalation-policies-select/escalation-policies-select-styles'
import {
  AddEntireTeamLabel,
  Multiselect,
  Instructions,
} from './users-select-styles'

interface UserSearchMultiSelectProps {
  incidentNumber?: string
  defaultUsers?: UserDTO[]
  excludeStakeholders?: boolean
  allowInvolvedUsers?: boolean
  hasError?: boolean
  selectionUpdated: (values: UserDTO[]) => void
  teams?: TeamDTO[]
  addEntireTeamFeature: boolean
}

export const UserSearchMultiSelect: FunctionComponent<UserSearchMultiSelectProps> = memo(
  ({
    incidentNumber,
    hasError,
    excludeStakeholders,
    allowInvolvedUsers,
    selectionUpdated,
    defaultUsers = [],
    teams = [],
    addEntireTeamFeature = false,
  }) => {
    const isMounted = useRef(null)

    const { orgSlug } = useParams<Params>()
    const [usersInDropdown, setUsers] = useState<UserDTO[]>(defaultUsers)
    const [teamsInDropdown, setTeams] = useState<TeamDTO[]>(teams)
    const [suggestedResponders, setSuggestedResponders] = useState<UserDTO[]>(
      []
    )
    const [usersCache, setUsersCache] = useState<UserDTO[]>(defaultUsers)

    const defaultUserNames = defaultUsers.map(
      (defaultUser) => defaultUser.username
    )
    const [selectedUsers, setSelectedUsers] = useState<string[]>(
      defaultUserNames
    )

    const [isLoadingTeams, setLoadingTeams] = useState(false)

    const [isLoadingUsers, setLoadingUsers] = useState(false)
    const [inputRef, setInputRef] = useState(null)

    const pagedUsers = useSelector(
      (state: State) =>
        state.websocketChat.incidentAckData[incidentNumber]?.pagedUsers || []
    )

    const monitorTool = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.monitorTool || ''
    )

    const entityDescription = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]
          ?.entityDescription || ''
    )

    const entityId = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.entityId || ''
    )

    const hostName = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.hostName || ''
    )

    const isIncidentAckDataPresent = useSelector(
      (state: State) =>
        state.websocketChat.incidentAckData[incidentNumber] !== undefined
    )

    const websocketIsReady = useSelector(
      (state: State) => state.websocketChat.isReady
    )

    const routingKey = useSelector(
      (state: State) =>
        state.websocketChat.incidentDetails[incidentNumber]?.routingKey || ''
    )

    const { sendMessage } = useWebsocket(websocketChatUrl)

    const stakeholderFilter = (user: UserDTO) =>
      user.roles ? !user.roles.includes('stakeholder') : true

    useEffect(() => {
      isMounted.current = true

      setLoadingUsers(true)
      fetchSuggestedResponders(
        orgSlug,
        entityDescription,
        entityId,
        hostName,
        monitorTool,
        routingKey
      )
        .then((responders) => {
          if (isMounted.current) {
            setLoadingUsers(false)
            setSuggestedResponders(responders)
            setUsersCache(usersCache.concat(responders))
          }
        })
        .catch(() => setLoadingUsers(false))
      return () => {
        isMounted.current = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     *
     * @function handleUsers - this is called when a new user is selected or when a user is removed from the select.
     */
    const handleUsers = (e: ChangeEvent, { values }: { values: string[] }) => {
      // building cache for looking up the full user info when
      // generating the options in the dropdown that were already selected.

      // 'values' could be a mix of teamslugs and usernames
      // team slugs look like this: "team-s6SyfYCYyCPHHbNX"
      // yes, I agree that this could backfire if a user chooses the wrong username
      const re = new RegExp(/^team-.*/)
      const teamSlugs = values.filter((item) => re.test(item))
      const users = values.filter((item) => !re.test(item))

      const membersEventually = teamSlugs.map((teamSlug) =>
        fetchTeamMembers(orgSlug, teamSlug)
      )

      // got all members of all teams selected. now aggregate them together,
      // get the usernames of those team members of all selected teams into a flat list,
      // join that list with the list of non-team-selected usernames,
      // de-dupe the entire thing and add them all to the incident.
      Promise.all(membersEventually).then((all) => {
        const teamMembers = all
          .map((i) => i.members)
          .flat()
          .map((u) =>
            Object.create({
              username: u.username,
              firstName: u.firstName,
              lastName: u.lastName,
            })
          )

        setUsersCache(usersCache.concat(teamMembers).concat(usersInDropdown))
        setSelectedUsers(uniq(users.concat(teamMembers.map((u) => u.username))))
      })
    }

    useEffect(() => {
      // It is important to note that components need to perform this check before
      // attempting to send messages across the websocket.
      if (incidentNumber && websocketIsReady && !isIncidentAckDataPresent) {
        sendMessage(websocketMessages.getRequestIncident(incidentNumber))
      }
    }, [
      incidentNumber,
      sendMessage,
      websocketIsReady,
      isIncidentAckDataPresent,
    ])

    useEffect(() => {
      if (usersCache.length === 0) {
        return
      }
      const users: UserDTO[] = selectedUsers.map((selectedUser) => {
        const userObj = usersCache.find((cachedUser) => {
          return cachedUser.username === selectedUser
        })
        return userObj
      })
      // letting parent know what is selected.
      selectionUpdated(users)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersCache, selectedUsers])

    function searchingForUsers(
      e: KeyboardEvent,
      { keyword }: { keyword: string }
    ) {
      if (keyword.length < 2) return

      if (isMounted.current) {
        setLoadingUsers(true)
        fetchUsers(orgSlug, keyword).then((users) => {
          setLoadingUsers(false)
          if (excludeStakeholders) {
            setUsers(users.filter(stakeholderFilter))
          } else {
            setUsers(users)
          }
        })
        setLoadingTeams(true)
        fetchTeams(orgSlug, keyword).then((teems) => {
          setLoadingTeams(false)
          setTeams(teems)
        })
      }
    }

    const searchingForUsersDebounced = debounce(searchingForUsers, 200)

    useEffect(
      () => searchingForUsersDebounced.cancel,
      // cleanup when component unmounts
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    function createRespondersHeader() {
      return (
        <Multiselect.Heading key='heading-main'>
          {translate('VO.UserSelect.Responders')}
          {!isLoadingUsers && !usersInDropdown.length && (
            <Instructions>
              {!usersInDropdown.length && inputRef && inputRef.value
                ? translate('VO.UserSelect.NoUsersFound')
                : translate('VO.UserSelect.SearchByTypingAbove')}
            </Instructions>
          )}
        </Multiselect.Heading>
      )
    }

    function createTeamsHeader() {
      return (
        <Multiselect.Heading key='heading-main-sub'>
          {translate('VO.TeamSelect.Teams')}
          {!isLoadingTeams && !teamsInDropdown.length && (
            <Instructions>
              {!teamsInDropdown.length && inputRef && inputRef.value
                ? translate('VO.TeamSelect.NoTeamsFound')
                : ''}
            </Instructions>
          )}
        </Multiselect.Heading>
      )
    }

    /**
     * @function: createHiddenOption
     * multiselect.heading will not show if there are no options underneath it. This allows us to override that behavior.
     * note: there are instructions that show below the header as well. In the DOM, they are part of the header. So in order to show those, we need an option underneath.
     */
    function createHiddenOption() {
      return (
        <NestedMultiselectOption
          style={{ display: 'none' }}
          label='' // required field
          value='' // required field
          key='selected'
          disabled
          hidden
        />
      )
    }

    function createUserOption(user: UserDTO, isSelected = false) {
      const isUserInvolvedAlready = pagedUsers.includes(user.username)
      return (
        <NestedMultiselectOption
          data-ext={`${user.username}-vo-users-select`}
          data-obj='vo-user'
          value={user.username}
          key={isSelected ? 'selected' : user.username}
          hidden={isSelected}
          disabled={!allowInvolvedUsers && isUserInvolvedAlready}
          label={user.firstName} // this is unused because we are passing in children, but it is required by splunk-ui
        >
          {`${user.firstName} ${user.lastName} `}
          {!allowInvolvedUsers &&
            isUserInvolvedAlready &&
            translate('VO.UserSelect.UserAlreadyInvolved')}
        </NestedMultiselectOption>
      )
    }

    function createTeamOption(team: TeamDTO, isSelected = false) {
      return (
        <NestedMultiselectOption
          data-ext={`${team.slug}-vo-teams-select`}
          data-obj='vo-team'
          value={team.slug}
          key={isSelected ? 'selected' : team.slug}
          hidden={isSelected}
          label={team.slug}
        >
          {team.name}{' '}
          <AddEntireTeamLabel>
            {translate('VO.TeamSelect.AddEntireTeam')}
          </AddEntireTeamLabel>
        </NestedMultiselectOption>
      )
    }

    /**
     * @function: createSelectedOptions
     * The selected items always have to be in the option list, but can be hidden. This won't matter unless you have selections that are part of a different search result
     */
    function createSelectedOptions() {
      return selectedUsers.map((user) => {
        const userObj = usersCache.find((cachedUser) => {
          return cachedUser.username === user
        })

        return userObj ? createUserOption(userObj, true) : null
      })
    }

    function addTeamSelectionOptions(menu: JSX.Element[]) {
      return addEntireTeamFeature
        ? menu
            .concat(createTeamsHeader())
            .concat(createHiddenOption())
            .concat(teamsInDropdown.map((team) => createTeamOption(team)))
        : menu
    }

    /**
     * @function: generateOptions
     * Responsible for gathering up all the Multiselect component options.
     */
    function generateOptions() {
      const suggestedRespondersList =
        (!isLoadingUsers &&
          [
            <Multiselect.Heading key='heading-suggested-responders'>
              {translate('VO.UserSelect.SuggestedResponders')}
            </Multiselect.Heading>,
          ].concat(
            suggestedResponders.map((user) => createUserOption(user))
          )) ||
        []

      const menu = [createRespondersHeader(), createHiddenOption()]
        .concat(usersInDropdown.map((user) => createUserOption(user)))
        .concat(suggestedRespondersList)

      return addTeamSelectionOptions(menu).concat(createSelectedOptions()) // this should be at the bottom of the list.
    }

    // multiselect does not work with react fragments around its options in any form.
    const options = generateOptions()
    return (
      <Multiselect
        data-ext='vo-users-select'
        values={selectedUsers}
        placeholder={translate('VO.UserSelect.SelectUsers')}
        onChange={handleUsers}
        onFilterChange={searchingForUsersDebounced}
        isLoadingOptions={isLoadingUsers && isLoadingTeams}
        inputRef={setInputRef}
        inline
        controlledFilter // setting this gets rid of the matchRanges error. If we change the Option to use label, then we should remove this to re-enable match highlighting.
        error={hasError}
      >
        {options}
      </Multiselect>
    )
  }
)

UserSearchMultiSelect.defaultProps = {
  addEntireTeamFeature: false,
  incidentNumber: undefined,
  hasError: false,
  excludeStakeholders: false,
  allowInvolvedUsers: false,
  teams: [],
}

UserSearchMultiSelect.propTypes = {
  incidentNumber: PropTypes.string,
  defaultUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludeStakeholders: PropTypes.bool,
  allowInvolvedUsers: PropTypes.bool,
  hasError: PropTypes.bool,
  selectionUpdated: PropTypes.func.isRequired,
  addEntireTeamFeature: PropTypes.bool,
  teams: PropTypes.arrayOf(PropTypes.any),
}
