import { createSlice } from '@reduxjs/toolkit'
import { IntegrationDTO } from 'utils/fetch'
import {
  StatusPage,
  StatusPageTemplateDTO,
  StatusPageIncidentDTO,
  StatusPageComponents,
  StatusPageComponentDTO,
  StatusPageComponent,
  StatusPageSubComponent,
} from 'utils/fetch/integrations/statuspage'
import sortBy from 'lodash/fp/sortBy'
import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import map from 'lodash/fp/map'

export enum Integrations {
  REST = 'rest',
  EMAIL = 'email',
  STATUS_PAGE = 'statuspage',
}
type ActiveIntegrations = Record<string, string>

interface IntegrationsInitialState {
  activeIntegrations: ActiveIntegrations
  statusPage: {
    incidents: StatusPageIncidentDTO[]
    components: StatusPageComponents
    templates: StatusPageTemplateDTO[]
    allOperational: boolean
    isDropdownOpen: boolean
  }
}

const integrationsInitialState: IntegrationsInitialState = {
  activeIntegrations: {},
  statusPage: {
    incidents: [],
    components: [],
    templates: [],
    allOperational: true,
    isDropdownOpen: false,
  },
}

const transformIntegrations = (integrations: IntegrationDTO[]) => {
  return integrations.reduce(
    (
      activeIntegrations: ActiveIntegrations,
      { integration, key }: IntegrationDTO
    ) => {
      return {
        ...activeIntegrations,
        [integration]: key,
      }
    },
    {}
  )
}
const sortComponentsByPosition = (components: StatusPageComponentDTO[]) =>
  sortBy((component) => component.position, components)

const groupComponentsByGroupId = (components: StatusPageComponentDTO[]) =>
  groupBy((component) => component.group_id || 'components', components)

const addSubComponentsToGroups = (
  componentObject: Record<string, StatusPageComponentDTO[]>
) =>
  map((component: StatusPageComponentDTO) => {
    if (componentObject[component.id] !== undefined) {
      const group = {
        ...component,
        type: 'StatusPageComponentGroup',
        // now set sub components and add type
        children: map(
          (subComponent): StatusPageSubComponent => ({
            ...subComponent,
            type: 'StatusPageSubComponent',
          }),
          componentObject[component.id]
        ),
      }
      return group
    }
    const normalComponent: StatusPageComponent = {
      ...component,
      type: 'StatusPageComponent',
    }
    return normalComponent
  }, componentObject.components)

const transformComponents = (components: StatusPageComponentDTO[]) =>
  flow(
    sortComponentsByPosition,
    groupComponentsByGroupId,
    addSubComponentsToGroups
  )(components)

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: integrationsInitialState,
  reducers: {
    setIntegrations(draft, { payload }) {
      draft.activeIntegrations = transformIntegrations(payload)
    },
    setStatusPageIncidents(
      draft,
      { payload }: { payload: StatusPageIncidentDTO[] }
    ) {
      draft.statusPage.incidents = payload.sort(
        (a, b) => b.updated_at - a.updated_at
      )
    },
    updateStatusPageIncidents(
      draft,
      { payload }: { payload: StatusPageIncidentDTO }
    ) {
      let { incidents } = draft.statusPage
      const incidentIndex = incidents.findIndex(
        (incident) => incident.id === payload.id
      )

      incidents[incidentIndex] = payload
      incidents = incidents.sort((a, b) => b.updated_at - a.updated_at)
    },
    createStatusPageIncident(
      draft,
      { payload }: { payload: StatusPageIncidentDTO }
    ) {
      draft.statusPage.incidents.unshift(payload)
      return draft
    },
    setStatusPageComponents(
      draft,
      { payload }: { payload: StatusPageComponentDTO[] }
    ) {
      draft.statusPage.components = transformComponents(
        payload
      ) as StatusPageComponents
    },
    updateStatusPageComponents(
      draft,
      { payload }: { payload: StatusPageComponentDTO[] }
    ) {
      const { components } = draft.statusPage
      // we are expecting an array of just the components to update
      payload.forEach((updatedComponent) => {
        // if it is sub component we need to find parent
        if (StatusPage.Components.isStatusPageSubComponent(updatedComponent)) {
          const parent = components.find(
            (component) => component.id === updatedComponent.group_id
          )
          if (StatusPage.Components.isStatusPageComponentGroup(parent)) {
            const componentIndex = parent.children.findIndex(
              (existingComponent) =>
                existingComponent.id === updatedComponent.id
            )
            parent.children[componentIndex] = {
              ...updatedComponent,
              type: 'StatusPageSubComponent',
            }
          }
        }
        const componentIndex = components.findIndex(
          (existingComponent) => existingComponent.id === updatedComponent.id
        )
        components[componentIndex] = {
          ...updatedComponent,
          type: 'StatusPageComponent',
        }
      })
    },
    setStatusPageTemplates(
      draft,
      { payload }: { payload: StatusPageTemplateDTO[] }
    ) {
      draft.statusPage.templates = payload
    },
    setStatusPageAllOperational(draft, { payload }: { payload: boolean }) {
      draft.statusPage.allOperational = payload
    },
    setDropdownOpenState(draft, { payload }: { payload: boolean }) {
      draft.statusPage.isDropdownOpen = payload
    },
  },
})

export const {
  setIntegrations,
  setStatusPageAllOperational,
  setStatusPageComponents,
  updateStatusPageComponents,
  setStatusPageIncidents,
  updateStatusPageIncidents,
  createStatusPageIncident,
  setStatusPageTemplates,
  setDropdownOpenState,
} = integrationsSlice.actions

export const { reducer: integrations } = integrationsSlice
