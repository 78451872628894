import React, { memo, FunctionComponent } from 'react'
import PropTypes from 'prop-types'

import { Detail } from './details-styles'

export interface DetailsProps {
  acknowledgedBy?: string[]
  acknowledgementRatio?: string | 0
  peoplePaged?: string[]
  policiesPaged?: string[]
  resolvedBy?: string
  snoozedBy?: string
}

export const Details: FunctionComponent<DetailsProps> = memo(
  ({
    acknowledgedBy,
    acknowledgementRatio,
    peoplePaged,
    policiesPaged,
    resolvedBy,
    snoozedBy,
  }) => {
    const getCommaSeparatedString = (strings: string[]) => strings.join(', ')

    return (
      <>
        {policiesPaged && (
          <Detail
            colorPrimary='danger'
            title={getCommaSeparatedString(policiesPaged)}
          >
            <span>Policies:</span>{' '}
            <span>{getCommaSeparatedString(policiesPaged)}</span>
          </Detail>
        )}
        {acknowledgedBy && (
          <Detail title={getCommaSeparatedString(acknowledgedBy)}>
            <span>Acknowledged {acknowledgementRatio || 'by'}:</span>{' '}
            <span>{getCommaSeparatedString(acknowledgedBy)}</span>
          </Detail>
        )}
        {peoplePaged && (
          <Detail title={getCommaSeparatedString(peoplePaged)}>
            <span>Paging:</span>{' '}
            <span>{getCommaSeparatedString(peoplePaged)}</span>
          </Detail>
        )}
        {resolvedBy && (
          <Detail title={resolvedBy}>
            <span>Resolved by:</span> <span>{resolvedBy}</span>
          </Detail>
        )}
        {snoozedBy && (
          <Detail title={snoozedBy}>
            <span>Snoozed by:</span> <span>{snoozedBy}</span>
          </Detail>
        )}
      </>
    )
  }
)

Details.defaultProps = {
  acknowledgedBy: undefined,
  acknowledgementRatio: undefined,
  peoplePaged: undefined,
  policiesPaged: undefined,
  resolvedBy: undefined,
  snoozedBy: undefined,
}

Details.propTypes = {
  acknowledgedBy: PropTypes.arrayOf(PropTypes.string.isRequired),
  acknowledgementRatio: PropTypes.string,
  peoplePaged: PropTypes.arrayOf(PropTypes.string.isRequired),
  policiesPaged: PropTypes.arrayOf(PropTypes.string.isRequired),
  resolvedBy: PropTypes.string,
  snoozedBy: PropTypes.string,
}
