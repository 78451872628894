import { patchAPI } from './common'

/**
 * function patchTakeOncallAPI - transfer the current oncall shift from one user to another
 * @param orgSlug required - Current org
 * @param policy required - Policy for which the oncall shift is being swapped
 * @param fromUser required - user who currently owns the shift
 * @param toUser required - user who is going to get the shift
 * @param isClear boolean - when doing a clearTake, this needs to be true.  Otherwise default false.
 */
export const patchTakeOncallAPI = (
  orgSlug: string,
  policy: string,
  fromUser: string,
  toUser: string,
  isClear?: boolean
) =>
  patchAPI(`v1/org/${orgSlug}/policies/${policy}/oncall/user`, {
    fromUser,
    toUser,
    isClear,
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
