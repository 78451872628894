import { PropsWithoutRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { PaletteKeys } from '@victorops/victoria'

export interface DetailProps
  extends PropsWithoutRef<JSX.IntrinsicElements['span']> {
  colorPrimary?: PaletteKeys
  colorSecondary?: PaletteKeys
}

// FIXME: fix style props https://victorops.atlassian.net/browse/PBJ-2252
export const Detail = styled.div<DetailProps>`
  font-size: ${({ theme }) => theme.fontSizes.xs};

  & :first-child {
    color: ${({ colorSecondary = 'gray1', theme }) =>
      theme.palette[colorSecondary]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  & :not(:first-child) {
    color: ${({ colorPrimary = 'primary', theme }) =>
      theme.palette[colorPrimary]};
    margin-top: ${({ theme }) => theme.functions.getSpace(0.5)};
  }
`

Detail.defaultProps = {
  colorPrimary: 'splunkCobalt',
  colorSecondary: 'gray1',
}

Detail.propTypes = {
  colorPrimary: PropTypes.oneOf([
    'black',
    'brand',
    'danger',
    'gray',
    'gray1',
    'gray2',
    'gray3',
    'gray4',
    'gray5',
    'grays',
    'link',
    'neutral',
    'primary',
    'primaryLight',
    'splunkCobalt',
    'splunkTeal',
    'splunkTeal1',
    'splunkTeal2',
    'success',
    'text',
    'transparent',
    'warning',
    'white',
  ]),
  colorSecondary: PropTypes.oneOf([
    'black',
    'brand',
    'danger',
    'gray',
    'gray1',
    'gray2',
    'gray3',
    'gray4',
    'gray5',
    'grays',
    'link',
    'neutral',
    'primary',
    'primaryLight',
    'splunkTeal',
    'splunkTeal1',
    'splunkTeal2',
    'success',
    'text',
    'transparent',
    'warning',
    'white',
  ]),
}
