import React from 'react'
import { render } from 'react-dom'

import { OnCallApp } from '.'

export const initApp = () => {
  const container = document.getElementById('victorops')
  render(<OnCallApp />, container)
}

initApp()
