import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'

import { Container, Content, Date, Header } from '../styles'
import { Message } from './card-message-styles'

export interface CardMessageProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  date?: string
  message: string
}

export const CardMessage: FunctionComponent<CardMessageProps> = memo(
  ({ date, message, ...rest }) => (
    <Container data-ext='card-message' {...rest}>
      <Content>
        <Header>
          <Message title={message}>{message}</Message>
          {date && <Date>{date}</Date>}
        </Header>
      </Content>
    </Container>
  )
)

CardMessage.defaultProps = {
  date: '',
}

CardMessage.propTypes = {
  date: PropTypes.string, // date is optional because of the last card in the timeline.
  message: PropTypes.string.isRequired,
}
