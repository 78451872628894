import { createSlice } from '@reduxjs/toolkit'

import { OrgMap } from './types'

const initialState: OrgMap[] = []

const authorizedOrgsSlice = createSlice({
  name: 'authorizedOrgs',
  initialState,
  reducers: {},
})

export const { reducer: authorizedOrgs } = authorizedOrgsSlice
