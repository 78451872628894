import styled from 'styled-components'

const Container = styled.div`
  background-color: #2d2d2d;
  color: white;
  padding: 5px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.s};
  height: 16px;
`

const Link = styled.a`
  color: ${({ theme }) => theme.palette.splunkTeal};
  text-decoration: underline;
`

export const StyledTrialBanner = {
  Container,
  Link,
}
