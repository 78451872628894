import { combineReducers } from '@reduxjs/toolkit'

import {
  authorizedOrgs,
  autoPause,
  currentOrg,
  incidentDetails,
  incidentsTable,
  integrations,
  isOlly,
  maintenanceMode,
  modals,
  onCallShifts,
  orgMeta,
  presetConferenceBridges,
  similarIncidents,
  snackbar,
  stakeholders,
  teams,
  user,
  websocketChat,
} from 'slices'

export const reducer = combineReducers({
  authorizedOrgs,
  autoPause,
  currentOrg,
  incidentDetails,
  incidentsTable,
  integrations,
  isOlly,
  maintenanceMode,
  modals,
  onCallShifts,
  orgMeta,
  presetConferenceBridges,
  similarIncidents,
  snackbar,
  stakeholders,
  teams,
  user,
  websocketChat,
})

export type State = ReturnType<typeof reducer>
