import React, { memo, FunctionComponent } from 'react'
import PropTypes from 'prop-types'

import { Row } from './row'

export interface RowRendererProps {
  dataSetID: string
  id: number
}

export const RowRenderer: FunctionComponent<RowRendererProps> = memo(
  ({ dataSetID, id }) => <Row {...{ dataSetID, id }} />
)

RowRenderer.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
