import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

import { translate } from 'utils'
import { Container, LoadingIcon } from './loading-style'

interface LoadingProps {
  size?: SizeProp
}

export const Loading: FunctionComponent<LoadingProps> = ({ size }) => {
  const [isLongLoad, setIsLongLoad] = useState(false)
  const interval = useRef(null)

  // We do not want to flash loading views.
  // It instead waits for long load times to show.
  useEffect(() => {
    interval.current = setTimeout(() => setIsLongLoad(true), 500)

    return () => clearInterval(interval.current)
  }, [])

  return (
    <Container data-ext='loading'>
      {isLongLoad ? (
        <LoadingIcon
          aria-label={translate('VO.Global.Loading')}
          icon={faSpinner}
          size={size as SizeProp}
          spin
        />
      ) : null}
    </Container>
  )
}

Loading.defaultProps = {
  size: '4x',
}

Loading.propTypes = {
  size: PropTypes.any, // eslint-disable-line react/forbid-prop-types
}
