import { postAPI, fetchAPI, putAPI, deleteAPI } from './common'

export interface StakeholderMessageTemplateDTO {
  id?: number
  name: string
  subject: string
  message: string
  teams: string[]
  users: string[]
}

// Fetches stakeholder templates by orgSlug.  TemplateId is optional to fetch a single one.
export const getStakeholderTemplates = (
  orgSlug: string,
  templateId?: number
): Promise<StakeholderMessageTemplateDTO[]> => {
  const idString = templateId ? `/${templateId}` : ''
  return fetchAPI(
    `v1/org/${orgSlug}/stakeholders/message-templates${idString}`
  ).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}

// Creates a new template for a given org.  Payload contains the template info, except for id,
//   which is auto generated on the backend.
export const createStakeholderTemplate = (
  orgSlug: string,
  payload: StakeholderMessageTemplateDTO
) =>
  postAPI(`v1/org/${orgSlug}/stakeholders/message-templates`, payload).then(
    (response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    }
  )

// Updates an existing template with new values.  Must specify templateId in the payload.
export const updateStakeholderTemplate = (
  orgSlug: string,
  payload: StakeholderMessageTemplateDTO
) =>
  putAPI(`v1/org/${orgSlug}/stakeholders/message-templates`, payload).then(
    (response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    }
  )

// Delete a given template by org and templateId.
export const deleteStakeholderTemplate = (
  orgSlug: string,
  templateId: number
) =>
  deleteAPI(
    `v1/org/${orgSlug}/stakeholders/message-templates/${templateId}`
  ).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })

export interface StakeholderRequestDTO {
  title: string
  message: string
  incidentId: number
  recipients: string[]
  recipientTeams: string[]
  tags: string[]
}

export const postStakeholderMessage = (
  orgSlug: string,
  payload: StakeholderRequestDTO
) =>
  postAPI(`v1/org/${orgSlug}/stakeholders/sendMsg`, payload).then(
    (response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    }
  )
