import React, { memo, FunctionComponent, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'

import { PaletteStatusKeys } from '@victorops/victoria'
import { Icon, IconContainer } from './icons-styles'

export interface IconsProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  monitorTool: string
  iconPath: string
  status?: PaletteStatusKeys
  snoozedUntil?: string
}

export const Icons: FunctionComponent<IconsProps> = memo(
  ({ iconPath, monitorTool, snoozedUntil, status, ...rest }) => {
    let iconOverlayPath
    if (status === 'resolved') {
      iconOverlayPath =
        'https://portal.victorops.com/integrations/logos/green-checkmark.svg'
    } else if (status === 'acknowledged') {
      iconOverlayPath =
        'https://portal.victorops.com/integrations/logos/red-checkmark.svg'
    } else if (snoozedUntil) {
      iconOverlayPath =
        'https://portal.victorops.com/integrations/logos/snooze-badge.svg'
    }

    return (
      <IconContainer {...rest}>
        <Icon alt={monitorTool} src={iconPath} title={monitorTool} />
        {iconOverlayPath && (
          <Icon alt=' ' data-ext='icon-overlay' src={iconOverlayPath} />
        )}
      </IconContainer>
    )
  }
)

Icons.defaultProps = {
  snoozedUntil: undefined,
  status: undefined,
}

Icons.propTypes = {
  iconPath: PropTypes.string.isRequired,
  monitorTool: PropTypes.string.isRequired,
  snoozedUntil: PropTypes.string,
  // FIXME: figure out types like this
  status: PropTypes.any, // eslint-disable-line react/forbid-prop-types
}
