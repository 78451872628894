import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'
import {
  faAlarmClock,
  faBell,
  faPaperclip,
} from '@fortawesome/pro-light-svg-icons'
import { faWrench } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  PaletteStatusKeys,
  paletteStatusPropType,
  StatusBar,
} from '@victorops/victoria'

import { translate } from 'utils'
import { Container, Content, Date, Header, Message } from '../styles'
import { TitleWithIcon } from '../components'
import {
  Footer,
  Info,
  InfoMuted,
  Muted,
  Username,
} from './card-incident-styles'
import { Details } from './components'

export interface CardIncidentProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  acknowledgedBy?: string[]
  acknowledgementsReceived?: number
  acknowledgementsExpected?: number
  date: string
  iconPath?: string
  incidentName: string
  isMultiResponder?: boolean
  isMuted?: boolean
  manualFrom?: string
  message: string
  monitorTool: string
  peoplePaged?: string[]
  policiesPaged?: string[]
  resolvedBy?: string
  snoozedBy?: string
  snoozedUntil?: string
  status?: PaletteStatusKeys
  statusText?: string
  showCardIncidentFooter?: boolean
  alertCount?: number
  annotationsCount?: number
  detailLink?: string
}

export const CardIncident: FunctionComponent<CardIncidentProps> = memo(
  ({
    acknowledgedBy,
    acknowledgementsExpected,
    acknowledgementsReceived,
    date,
    iconPath,
    incidentName,
    isMultiResponder,
    isMuted,
    manualFrom,
    message,
    monitorTool,
    peoplePaged,
    policiesPaged,
    resolvedBy,
    snoozedBy,
    snoozedUntil,
    status,
    statusText,
    showCardIncidentFooter,
    alertCount,
    annotationsCount,
    detailLink,
    ...rest
  }) => {
    const statusBarStatus = `${status}Alt` as PaletteStatusKeys

    const acknowledgementRatio =
      acknowledgementsExpected &&
      acknowledgementsReceived &&
      `(${acknowledgementsReceived}/${acknowledgementsExpected})`

    const titleText = `Incident ${incidentName} ${
      acknowledgementRatio || ''
    } ${statusText}`

    return (
      <Container isMuted={isMuted} data-ext='card-incident' {...rest}>
        <StatusBar isVertical status={statusBarStatus} />

        <Content>
          <Header>
            <TitleWithIcon
              {...{
                iconPath,
                monitorTool,
                snoozedUntil,
                status,
                titleText,
                detailLink,
              }}
            />
            <Date>{date}</Date>
          </Header>
          <Message>
            {monitorTool}
            {manualFrom && (
              <span>
                {' '}
                ({translate('VO.CardIncident.From')}{' '}
                <Username>{manualFrom}</Username>)
              </span>
            )}
            {`: ${message}`}
          </Message>
          <Details
            {...{
              acknowledgedBy,
              acknowledgementRatio,
              peoplePaged,
              policiesPaged,
              resolvedBy,
              snoozedBy,
            }}
          />

          {/* info for multi-ack functionality */}
          {isMultiResponder && (
            <Info>{translate('VO.CardIncident.MultiAckMessage')}</Info>
          )}
          {/* info for muting functionality */}
          {isMuted && (
            <InfoMuted>
              <FontAwesomeIcon icon={faWrench} />
              <span>{translate('VO.CardIncident.PagingMutedMessage')}</span>
            </InfoMuted>
          )}
        </Content>

        {snoozedUntil && (
          <Footer>
            <FontAwesomeIcon icon={faAlarmClock} />
            <span>
              {translate('VO.CardIncident.SnoozedUntil')} {snoozedUntil}
            </span>
          </Footer>
        )}

        {/* style overlay for muting */}
        {isMuted && <Muted />}

        {showCardIncidentFooter && (
          <Footer>
            {
              <span>
                <FontAwesomeIcon icon={faPaperclip} />
                <span>
                  {' '}
                  {annotationsCount} {translate('VO.Global.Annotations')}
                </span>
              </span>
            }{' '}
            {
              <span>
                <FontAwesomeIcon icon={faBell} />
                <span>
                  {' '}
                  {alertCount} {translate('VO.CardIncident.Alerts')}
                </span>
              </span>
            }{' '}
            {
              <span className='pull-right'>
                <a href={detailLink} rel='noopener noreferrer' target='_blank'>
                  {translate('VO.CardIncident.IncidentDetails')}
                </a>
              </span>
            }{' '}
          </Footer>
        )}
      </Container>
    )
  }
)

CardIncident.defaultProps = {
  acknowledgedBy: undefined,
  acknowledgementsExpected: undefined,
  acknowledgementsReceived: undefined,
  iconPath: undefined,
  isMultiResponder: false,
  isMuted: false,
  manualFrom: '',
  peoplePaged: undefined,
  policiesPaged: undefined,
  resolvedBy: undefined,
  snoozedBy: undefined,
  snoozedUntil: undefined,
  status: 'neutral',
  statusText: '',
  showCardIncidentFooter: false,
  alertCount: 0,
  annotationsCount: 0,
  detailLink: '',
}

CardIncident.propTypes = {
  acknowledgedBy: PropTypes.arrayOf(PropTypes.string.isRequired),
  acknowledgementsExpected: PropTypes.number,
  acknowledgementsReceived: PropTypes.number,
  date: PropTypes.string.isRequired,
  iconPath: PropTypes.string,
  incidentName: PropTypes.string.isRequired,
  isMultiResponder: PropTypes.bool,
  isMuted: PropTypes.bool,
  manualFrom: PropTypes.string,
  message: PropTypes.string.isRequired,
  monitorTool: PropTypes.string.isRequired,
  peoplePaged: PropTypes.arrayOf(PropTypes.string.isRequired),
  policiesPaged: PropTypes.arrayOf(PropTypes.string.isRequired),
  resolvedBy: PropTypes.string,
  snoozedBy: PropTypes.string,
  snoozedUntil: PropTypes.string,
  status: paletteStatusPropType,
  statusText: PropTypes.string,
  showCardIncidentFooter: PropTypes.bool,
  alertCount: PropTypes.number,
  annotationsCount: PropTypes.number,
  detailLink: PropTypes.string,
}
