import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum modalTypes {
  SNOOZE = 'snooze',
  BULK_ACTIONS_CONFIRMATION = 'bulkActionsConfirmation',
  MAINTENANCE_MODE = 'maintenanceMode',
  SPIO_EDITOR = 'spioEditor',
  SPIO_COMPONENTS = 'spioComponents',
  REROUTE = 'reroute',
  REROUTE_CONFIRMATION = 'rerouteConfirmation',
  ADD_RESPONDERS = 'addResponders',
  ADD_RESPONDERS_CONFIRMATION = 'addRespondersConfirmation',
  MANUAL_INCIDENT = 'manualIncident',
  DELETE_CONFERENCE_BRIDGE = 'deleteConferenceBridge',
  CONFERENCE_BRIDGE = 'conferenceBridge',
  EDIT_CONFERENCE_BRIDGE = 'editConferenceBridge',
  TAKE_ONCALL_CONFIRMATION = 'takeOncallConfirmation',
  SH_MESSAGE_TEMPLATE_OVERWRITE = 'shMessageTemplateOverwriteConfirmation',
}

export enum takeTypes {
  TAKE_CALL = 'TAKE_CALL',
  CLEAR_TAKE = 'CLEAR_TAKE',
  TAKE_BACK = 'TAKE_BACK',
}

interface InitialState {
  currentModalOpen: modalTypes
  deleteConferenceBridgeModalError: boolean
  spioEditorModal: {
    mode: string
    spioIncidentId: string
  }
  takeOncallConfirmationModal: {
    fromUser: string
    toUser: string
    takeType: string
    teamSlug: string
    teamName: string
    policySlug: string
    policyName: string
  }
}

const initialState: InitialState = {
  currentModalOpen: null,
  deleteConferenceBridgeModalError: false,
  spioEditorModal: {
    mode: null,
    spioIncidentId: null,
  },
  takeOncallConfirmationModal: {
    fromUser: null,
    toUser: null,
    takeType: null,
    teamSlug: null,
    teamName: null,
    policySlug: null,
    policyName: null,
  },
}

interface OpenSpioEditorModalMessage {
  mode: string
  spioIncidentId: string
}

interface OpenTakeOnCallConfirmationModalMessage {
  fromUser: string
  toUser: string
  takeType: string
  teamSlug: string
  teamName: string
  policySlug: string
  policyName: string
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    closeModal: (state) => ({
      ...state,
      currentModalOpen: null,
      deleteConferenceBridgeModalError: false,
    }),
    openModalSnooze: (state) => ({
      ...state,
      currentModalOpen: modalTypes.SNOOZE,
    }),
    openModalReroute: (state) => ({
      ...state,
      currentModalOpen: modalTypes.REROUTE,
    }),
    openModalRerouteConfirmation: (state) => ({
      ...state,
      currentModalOpen: modalTypes.REROUTE_CONFIRMATION,
    }),
    openModalAddResponders: (state) => ({
      ...state,
      currentModalOpen: modalTypes.ADD_RESPONDERS,
    }),
    openModalAddRespondersConfirmation: (state) => ({
      ...state,
      currentModalOpen: modalTypes.ADD_RESPONDERS_CONFIRMATION,
    }),
    openModalManualIncident: (state) => ({
      ...state,
      currentModalOpen: modalTypes.MANUAL_INCIDENT,
    }),
    openDeleteConferenceBridge: (state) => ({
      ...state,
      currentModalOpen: modalTypes.DELETE_CONFERENCE_BRIDGE,
      deleteConferenceBridgeModalError: false,
    }),
    openModalConferenceBridge: (state) => ({
      ...state,
      currentModalOpen: modalTypes.CONFERENCE_BRIDGE,
    }),
    openEditConferenceBridge: (state) => ({
      ...state,
      currentModalOpen: modalTypes.EDIT_CONFERENCE_BRIDGE,
      editConferenceBridgeModalError: false,
    }),
    openBulkActionsConfirmationModal: (state) => ({
      ...state,
      currentModalOpen: modalTypes.BULK_ACTIONS_CONFIRMATION,
    }),
    openMaintenanceModeModal: (state) => ({
      ...state,
      currentModalOpen: modalTypes.MAINTENANCE_MODE,
    }),
    openSHTemplateOverwriteConfirmModal: (state) => ({
      ...state,
      currentModalOpen: modalTypes.SH_MESSAGE_TEMPLATE_OVERWRITE,
    }),
    openSpioEditorModal: (
      state,
      { payload }: PayloadAction<OpenSpioEditorModalMessage>
    ) => ({
      ...state,
      currentModalOpen: modalTypes.SPIO_EDITOR,
      spioEditorModal: {
        mode: payload.mode,
        spioIncidentId: payload.spioIncidentId,
      },
    }),
    openSpioComponentsModal(draft) {
      draft.currentModalOpen = modalTypes.SPIO_COMPONENTS
    },
    openTakeOncallConfirmationModal: (
      draft,
      { payload }: PayloadAction<OpenTakeOnCallConfirmationModalMessage>
    ) => {
      draft.currentModalOpen = modalTypes.TAKE_ONCALL_CONFIRMATION
      draft.takeOncallConfirmationModal = {
        fromUser: payload.fromUser,
        toUser: payload.toUser,
        takeType: payload.takeType,
        teamSlug: payload.teamSlug,
        teamName: payload.teamName,
        policySlug: payload.policySlug,
        policyName: payload.policyName,
      }
    },

    /**
     * @function toggleModal
     * this is meant to be used when a modal is already open, and you want to switch the user to a different modal.
     */
    toggleModal: (state, { payload }) => ({
      ...state,
      currentModalOpen: payload.modalType,
    }),
  },
})

export const {
  closeModal,
  openModalSnooze,
  openModalReroute,
  openModalRerouteConfirmation,
  openModalAddResponders,
  openModalAddRespondersConfirmation,
  openModalManualIncident,
  openDeleteConferenceBridge,
  openModalConferenceBridge,
  openEditConferenceBridge,
  openBulkActionsConfirmationModal,
  openMaintenanceModeModal,
  openSpioEditorModal,
  openSpioComponentsModal,
  openTakeOncallConfirmationModal,
  openSHTemplateOverwriteConfirmModal,
  toggleModal,
} = modalsSlice.actions
export const { reducer: modals } = modalsSlice
