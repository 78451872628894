export const organizationalIntelligenceRouteBase = '/:orgSlug/incidents'
export const incidentDetailRouteBase = '/:orgSlug/incident/:incidentNumber'

export const getBaseRoutes = (orgSlug: string) => [
  {
    path: `/ui/${orgSlug}/incidents`,
    name: 'incidents',
  },
  {
    path: `/client/${orgSlug}`,
    name: 'timeline',
  },
  {
    path: `/reports/${orgSlug}/post-incidents`,
    name: 'reports',
  },
  {
    path: `/dash/${orgSlug}/team-schedules`,
    name: 'teams',
  },
  {
    path: `/dash/${orgSlug}#/users`,
    name: 'users',
  },
  {
    path: `/dash/${orgSlug}#/advanced`,
    name: 'integrations',
  },
  {
    path: `/dash/${orgSlug}#/routekeys`,
    name: 'settings',
  },
]
