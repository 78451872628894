import styled from 'styled-components'

export const Container = styled.ol`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  list-style-type: none;
  padding: 0;
`
