import upperFirst from 'lodash/upperFirst'

import { fetchAPI } from './common'
import { OnCallShiftsDTO, transformOnCalls } from './on-call'

export interface InitAppData {
  auth: {
    user: {
      firstName: string
      lastName: string
      primaryEmail: string
      username: string
    }
  }
  billing: {
    state: string
    trial_end?: number // eslint-disable-line camelcase
  }
  orgname: string
  orgs: {
    name: string
    slug: string
  }[]
  orgslug: string
  isAdmin: boolean
  context: {
    roles: {
      name: string
      teamSlug?: string
    }[]
  }
  autoPauseThreshold: number
  activeIncidentCount: number
  nextOnCall: OnCallShiftsDTO
}

export const transformConfig = (data: InitAppData) => ({
  authorizedOrgs: data.orgs.map((org) =>
    org.slug === data.orgslug ? { isCurrent: true, ...org } : org
  ),
  currentOrg: {
    name: data.orgname,
    slug: data.orgslug,
    billingState: data.billing.state,
    trialEnd: data.billing.trial_end,
  },
  user: {
    email: data.auth.user.primaryEmail,
    fullName: `${upperFirst(data.auth.user.firstName)} ${upperFirst(
      data.auth.user.lastName
    )}`,
    roles: {
      isGlobalAdmin: data.isAdmin,
      isAlertAdmin: data.context.roles.some(
        (role) => role.name === 'alert_admin'
      ),
      isStakeholder: data.context.roles.some(
        (role) => role.name === 'stakeholder'
      ),
      teamsAdministered: data.context.roles
        .filter((role) => role.name === 'team_admin')
        .map((role) => role.teamSlug),
    },
    username: data.auth.user.username,
    usernameDisplay: `${upperFirst(data.auth.user.firstName)} ${upperFirst(
      data.auth.user.lastName
    )}`,
    shift: transformOnCalls(data.auth.user.username, data.nextOnCall),
  },
  autoPause: {
    autoPauseThreshold: data.autoPauseThreshold,
    activeIncidentCount: data.activeIncidentCount,
  },
})

export const fetchConfig = (orgSlug: string) =>
  fetchAPI(`${orgSlug}/config.json`).then((response) => {
    if (response.ok) {
      return response
        .json()
        .then((initAppData: InitAppData) => transformConfig(initAppData))
    }
    // We consider the user unauthed with no config
    const currentURL = encodeURIComponent(window.location.href)
    window.location.assign(`/membership/#/?returnTo=${currentURL}`)
    return null
  })
