export const getFullPath = (path: string, url: string) => [path, url].join('/')

// resolves to response: https://developer.mozilla.org/en-US/docs/Web/API/Response
export const getFetch = (path: string, url: string, params?: RequestInit) =>
  fetch(getFullPath(path, url), {
    ...params,
    credentials: 'same-origin', // "credentials" option necassary for auth on Edge
  }).then((response) => response)

export const fetchAPI = (url: string) => getFetch('api', url)

export const patchAPI = (url: string, body = {}) =>
  getFetch('api', url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })

export const postAPI = (url: string, body = {}) =>
  getFetch('api', url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })

export const putAPI = (url: string, body = {}) =>
  getFetch('api', url, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })

export const deleteAPI = (url: string) =>
  getFetch('api', url, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
    },
  })

export const fetchAPIReporting = (url: string) => getFetch('api-reporting', url)
