import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ActiveInstances {
  INSTANCE_ID: string
  STARTED_BY: string
  STARTED_AT: number
  PURPOSE: string
  TARGETS: Targets[]
  IS_GLOBAL: boolean
}
interface Targets {
  TYPE: string
  NAMES: string[]
}
interface MaintenanceModeMessage {
  PAYLOAD: {
    MAINTENANCE_MODE_STATE: {
      COMPANY_ID: string
      ACTIVE_INSTANCES: ActiveInstances[]
    }
  }
}
interface MaintenanceModeInitialState {
  companyID: string
  activeInstances: ActiveInstances[]
}
const initialState: MaintenanceModeInitialState = {
  companyID: null,
  activeInstances: [],
}
const mainenanceModeSlice = createSlice({
  name: 'maintenanceMode',
  initialState,
  reducers: {
    receivedMaintenanceModeStateNotify: (
      state,
      { payload }: PayloadAction<MaintenanceModeMessage>
    ) => ({
      ...state,
      companyID: payload.PAYLOAD.MAINTENANCE_MODE_STATE.COMPANY_ID,
      activeInstances: payload.PAYLOAD.MAINTENANCE_MODE_STATE.ACTIVE_INSTANCES,
    }),
  },
})

export const {
  receivedMaintenanceModeStateNotify,
} = mainenanceModeSlice.actions
export const { reducer: maintenanceMode } = mainenanceModeSlice
