import styled from 'styled-components'
import Multiselect from '@splunk/react-ui/Multiselect'
import ControlGroup from '@splunk/react-ui/ControlGroup'

export const RespondersControlGroup = styled(ControlGroup)`
  margin-bottom: ${({ theme }) => theme.functions.getSpace(1)};
`

export const NestedMultiselectOption = styled(Multiselect.Option)`
  > span {
    margin-left: ${({ theme }) => theme.functions.getSpace(2)};
  }
`
