import { StatusPageFetch } from './base'

export enum StatusPageComponentStatus {
  OPERATIONAL = 'operational',
  DEGRADED_PERFORMANCE = 'degraded_performance',
  PARTIAL_OUTAGE = 'partial_outage',
  MAJOR_OUTAGE = 'major_outage',
  UNDER_MAINTENANCE = 'under_maintenance',
}

export interface StatusPageComponentDTO extends StatusPageFetch {
  status: StatusPageComponentStatus
  // from server
  /* eslint-disable camelcase */
  updated_by: string
  group_id: string | null
  /* eslint-enable camelcase */
  position: number
  type: string
}

export interface StatusPageComponentGroup extends StatusPageComponentDTO {
  children: StatusPageSubComponent[]
  type: 'StatusPageComponentGroup'
}

export interface StatusPageSubComponent extends StatusPageComponentDTO {
  // from server
  // eslint-disable-next-line camelcase
  group_id: string
  type: 'StatusPageSubComponent'
}

export interface StatusPageComponent extends StatusPageComponentDTO {
  type: 'StatusPageComponent'
}

export type SPIOComponent =
  | StatusPageComponent
  | StatusPageComponentGroup
  | StatusPageSubComponent

// these are the top level components
export type StatusPageComponents = Array<
  StatusPageComponent | StatusPageComponentGroup
>
