import React, { memo, FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons'

import { translate } from 'utils'
import {
  NewDataDisplayContainer,
  NewDataDisplayArrow,
} from './new-data-display-styles'

interface NewDataDisplayProps {
  newDataCount: number
  onClick(): void
}

export const NewDataDisplay: FunctionComponent<NewDataDisplayProps> = memo(
  ({ newDataCount, onClick }) => {
    return (
      <NewDataDisplayContainer onClick={onClick} data-ext='vo-timeline-growler'>
        <span>
          {newDataCount > 1
            ? translate('VO.Timeline.NewMessagesDynamic', newDataCount)
            : translate('VO.Timeline.NewMessage')}
          <NewDataDisplayArrow>
            <FontAwesomeIcon icon={faArrowUp} />
          </NewDataDisplayArrow>
        </span>
      </NewDataDisplayContainer>
    )
  }
)

NewDataDisplay.propTypes = {
  onClick: PropTypes.func.isRequired,
  newDataCount: PropTypes.number.isRequired,
}
