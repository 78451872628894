import styled, { css } from 'styled-components'
import SplunkControlGroup from '@splunk/react-ui/ControlGroup'
import { PrimaryButton, SecondaryButton } from 'styles/button-styles'

// matching splunk ui styles for focus styles
const inputFocusStyles = css`
  box-shadow: 0 0 1px 2px rgba(0, 164, 253, 0.6);
  color: #3c444d;
  outline: 0;
  border-collapse: separate;
  z-index: 1;
`

// matching splunk ui styles for inputs
export const inputStyles = css`
  border: 1px solid #c3cbd4;
  background-color: #ffffff;
  height: inherit;
  min-height: 32px;
  line-height: inherit;
  margin: 0;
  display: block;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.gray};
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: inherit;
  border-radius: 3px;
  position: relative;
`

export const textAreaStyles = css`
  ${inputStyles}
  padding: 10px 5px;
  resize: none;

  &.error {
    border-color: ${({ theme }) => theme.palette.danger};
    color: ${({ theme }) => theme.palette.danger};
  }

  &:focus {
    ${inputFocusStyles}
  }
`

// matching splunk ui styles for textbox
const Input = styled.input`
  ${inputStyles}
  padding-top: 5px;
  padding-right: calc(10px + 0px);
  padding-bottom: 5px;
  padding-left: calc(10px + 0px);

  &:focus {
    ${inputFocusStyles}
  }
`

// matching splunk ui styles for textbox
const TextArea = styled.textarea`
  ${textAreaStyles}
`

const Cancel = styled(SecondaryButton)`
  margin-right: ${({ theme }) => theme.functions.getSpace(2)};
`
const Submit = styled(PrimaryButton)`
  flex-grow: 0 !important; /* overriding splunk-ui adding inline flex-grow style when combined with control group in specific ways (ex: stakeholders send button) */
`

const ControlGroup = styled(SplunkControlGroup)`
  min-width: 100%;
`

export const StyledForm = {
  ControlGroup,
  Input,
  TextArea,
  Submit,
  Cancel,
}
