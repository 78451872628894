import React, { memo, FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'app/reducer'
import Message from '@splunk/react-ui/Message'
import { unsetSnackbarMessage } from 'slices'
import { StyledSnackbar } from './snackbar-styles'

/**
 * FunctionComponent Snackbar
 * Renders a container for error messages.  Checks redux store for presence of error messges (if null,
 *   then there is no error)
 * @TODO Extend in the future to handle display of multiple messages at the same time
 */
export const Snackbar: FunctionComponent = memo(() => {
  const dispatch = useDispatch()

  const message = useSelector(
    (state: State) => state.snackbar.snackbarMessage?.message
  )
  const severity = useSelector(
    (state: State) => state.snackbar.snackbarMessage?.severity
  )

  // The cleanup prevents one unsetSnackbarMessage() action from clearing a new error's message prematurely
  useEffect(() => {
    const timer =
      message && setTimeout(() => dispatch(unsetSnackbarMessage()), 5000)
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [message, dispatch])

  return (
    message && (
      <StyledSnackbar.Container>
        <Message fill data-ext='snackbar-message' type={severity}>
          {message}
        </Message>
      </StyledSnackbar.Container>
    )
  )
})
