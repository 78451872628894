import { decode } from 'he'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import { Severity } from '@sentry/browser'

import { getFormattedDate } from 'utils/date-formats'
import { logError } from 'utils/log-error'
import { getUserDisplayName, getUserDisplayNames } from 'utils/user-helpers'
import {
  IncidentData,
  IncidentPolicy,
  InitialState,
  KeyedTimeline,
  OnCallShift,
  OnCallShiftDTO,
  Status,
  TimelineMessage,
} from './websocket-chat-types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseAnnotations = (data: any) => {
  const annotationsRegex = /^(?:details\.)?vo_annotate\.([isu])\.(.*)$/

  const annotationKeys = Object.keys(data).filter((key) =>
    key.includes('vo_annotate')
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const annotations: any[] = []
  annotationKeys.forEach((key) => {
    const extractedAnnotationData = annotationsRegex.exec(key)

    annotations.push({
      fieldName: decode(extractedAnnotationData[2]),
      fieldValue: decode(data[key]),
      annotationType: extractedAnnotationData[1],
    })
  })

  return annotations
}
const transformSource = (source = '') => {
  let integrationKey = source.toLowerCase()
  if (integrationKey === 'api') integrationKey = 'rest' // in integration map key is rest not api
  return integrationKey
}

// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformTimelineMessageData = (value: any, userMap: any) => {
  try {
    const type = Object.keys(value)[0]

    const getStatusFromPhase = (phase: string) => {
      switch (phase) {
        case 'RESOLVED':
          return 'resolved'
        default:
          return 'triggered'
      }
    }

    const getStatusText = (phase: string) => {
      switch (phase) {
        case 'ACKED':
          return 'Acknowledged'
        case 'RESOLVED':
          return 'Resolved'
        default:
          return 'Triggered'
      }
    }

    const getStatus = () => {
      if (value.INCIDENT.SNOOZED_UNTIL) {
        return 'snoozed'
      }
      if (value.INCIDENT.ENTITY_STATE === 'WARNING') {
        return 'warning'
      }

      return getStatusFromPhase(value.INCIDENT.CURRENT_PHASE)
    }

    const getAcknowledgedBy = () => {
      if (
        value.INCIDENT.CURRENT_PHASE !== 'RESOLVED' &&
        value.INCIDENT.ACK_DATA?.ACK_USERS.length > 0
      ) {
        return value.INCIDENT.ACK_DATA.ACK_USERS
      }
      if (
        value.INCIDENT.CURRENT_PHASE === 'RESOLVED' ||
        value.INCIDENT.SNOOZED_UNTIL
      ) {
        return undefined
      }
      return value.INCIDENT.USER && [value.INCIDENT.USER]
    }

    // IMPORTANT: Each case needs an id and type
    switch (type) {
      case 'ALERT':
        return {
          alertType: value.ALERT.DISPLAY_ALERT_TYPE,
          annotations: parseAnnotations(value.ALERT),
          date: getFormattedDate(value.ALERT.SEQUENCING.SERVICE_TIME),
          from: decode(value.ALERT['details.monitor_name'] || ''),
          id: value.ALERT.SEQUENCING.SEQUENCE,
          message: decode(value.ALERT.SERVICEDISPLAYNAME || ''),
          monitorTool: value.ALERT.MONITOR_TYPE,
          numberOfAnnotations: value.ALERT.ANNOTATIONS.length,
          stateMessage: decode(value.ALERT.SERVICEOUTPUT || ''),
          timeStamp: value.ALERT.SEQUENCING.SERVICE_TIME,
          type,
          voUuid: value.ALERT.VO_UUID,
        }
      case 'CHAT': {
        const userId = value.CHAT.IS_ROBOT
          ? '@victorops'
          : `${value.CHAT.USER_ID}`
        const userIdDispName = getUserDisplayName(userMap, userId)
        return {
          date: getFormattedDate(value.CHAT.SEQUENCING.SERVICE_TIME),
          id: value.CHAT.SEQUENCING.SEQUENCE,
          message: decode(value.CHAT.TEXT || ''),
          service: value.CHAT.CHAT_SOURCE?.SERVICE,
          timeStamp: value.CHAT.SEQUENCING.SERVICE_TIME,
          type,
          userId,
          userIdDispName,
        }
      }

      case 'INCIDENT': {
        const peoplePaged =
          value.INCIDENT.CURRENT_PHASE !== 'RESOLVED' &&
          !value.INCIDENT.SNOOZED_UNTIL &&
          value.INCIDENT.USERS_PAGED.length > 0
            ? value.INCIDENT.USERS_PAGED
            : undefined

        const resolvedBy =
          value.INCIDENT.CURRENT_PHASE === 'RESOLVED'
            ? value.INCIDENT.USER
            : undefined

        const snoozedBy = value.INCIDENT.SNOOZED_UNTIL
          ? value.INCIDENT.USER
          : undefined
        const manualFrom =
          value.INCIDENT.MONITOR_TYPE === 'Manual'
            ? value.INCIDENT.MONITOR_NAME.replace('vouser-', '') // TODO: check for display name ?
            : undefined
        return {
          acknowledgedBy: getAcknowledgedBy(),
          acknowledgedByDispNames: getUserDisplayNames(
            userMap,
            getAcknowledgedBy()
          ),
          acknowledgementsExpected:
            value.INCIDENT.CURRENT_PHASE !== 'RESOLVED' &&
            value.INCIDENT.ACK_DATA?.ACKS_EXPECTED > 1
              ? value.INCIDENT.ACK_DATA.ACKS_EXPECTED
              : undefined,
          acknowledgementsReceived:
            value.INCIDENT.CURRENT_PHASE !== 'RESOLVED'
              ? value.INCIDENT.ACK_DATA?.ACKS_RECEIVED
              : undefined,
          date: getFormattedDate(value.INCIDENT.SEQUENCING.SERVICE_TIME),
          // TODO: iconPath: https://victorops.atlassian.net/browse/PBJ-2310,
          id: value.INCIDENT.SEQUENCING.SEQUENCE,
          incidentName: value.INCIDENT.INCIDENT_NAME,
          isMultiResponder: value.INCIDENT.IS_MULTI_RESPONDER,
          isMuted: value.INCIDENT.IS_MUTED,
          manualFrom,
          manualFromDispName: getUserDisplayName(userMap, manualFrom),
          message: decode(value.INCIDENT.SERVICE || ''),
          monitorTool: value.INCIDENT.MONITOR_TYPE,
          peoplePaged,
          peoplePagedDispNames: getUserDisplayNames(userMap, peoplePaged),
          phase: value.INCIDENT.CURRENT_PHASE,
          policiesPaged:
            value.INCIDENT.POLICIES_PAGED.filter(
              // filter out personal paging
              // fix with https://jira.splunk.com/browse/VOI-116
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ({ TEAM: { NAME: teamName } }: any) => teamName !== ''
            ).length > 0
              ? value.INCIDENT.POLICIES_PAGED.reduce((
                  acc: Array<string>,
                  // fix with https://jira.splunk.com/browse/VOI-116
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  {
                    POLICY: { NAME: policyName },
                    TEAM: { NAME: teamName },
                  }: any // eslint-disable-line @typescript-eslint/no-explicit-any
                ) => {
                  // filter out personal paging
                  if (teamName !== '') {
                    acc.push(`${teamName} : ${policyName}`)
                  }
                  return acc
                }, [])
              : undefined,
          resolvedBy,
          resolvedByDispName: getUserDisplayName(userMap, resolvedBy),
          snoozedBy,
          snoozedByDispName: getUserDisplayName(userMap, snoozedBy),
          snoozedUntil: value.INCIDENT.SNOOZED_UNTIL
            ? getFormattedDate(value.INCIDENT.SNOOZED_UNTIL)
            : undefined,
          status: getStatus(),
          statusText: value.INCIDENT.SNOOZED_UNTIL
            ? 'Snoozed'
            : getStatusText(value.INCIDENT.CURRENT_PHASE),
          timeStamp: value.INCIDENT.SEQUENCING.SERVICE_TIME,
          type,
        }
      }

      case 'PAGE': {
        const pageUserId = value.PAGE.USER_ID
        const pageUserIdDispName = getUserDisplayName(userMap, pageUserId)
        return {
          date: getFormattedDate(value.PAGE.SEQUENCING.SERVICE_TIME),
          id: value.PAGE.SEQUENCING.SEQUENCE,
          message: `Trying to contact ${pageUserIdDispName} for ${
            value.PAGE.ID
          }, sending ${value.PAGE.METHODS.map(
            (method: any) => method.LABEL
          ).join(', ')}`,
          pageUserId,
          pageUserIdDispName,
          pageId: value.PAGE.ID,
          pageMethodLabel: value.PAGE.METHODS.map(
            (method: any) => method.LABEL
          ).join(', '),
          timeStamp: value.PAGE.SEQUENCING.SERVICE_TIME,
          type,
        }
      }

      case 'STAKEHOLDER_MESSAGE': {
        const stakeholderMessageRecipients =
          value.STAKEHOLDER_MESSAGE.RECIPIENTS
        const stakeholderMessageRecipientsDispNames = getUserDisplayNames(
          userMap,
          stakeholderMessageRecipients
        )

        return {
          date: getFormattedDate(
            value.STAKEHOLDER_MESSAGE.SEQUENCING.SERVICE_TIME
          ),
          id: value.STAKEHOLDER_MESSAGE.SEQUENCING.SEQUENCE,
          message: value.STAKEHOLDER_MESSAGE.TEXT,
          stakeholderMessageRecipients,
          stakeholderMessageRecipientsDispNames,
          stakeholderMessageTitle: value.STAKEHOLDER_MESSAGE.TITLE,
          timeStamp: value.STAKEHOLDER_MESSAGE.SEQUENCING.SERVICE_TIME,
          type,
        }
      }

      default: {
        logError('unknown message type received', { info: value })
        return { id: -1 }
      }
    }
  } catch (error) {
    logError(error, { info: value })
    return null
  }
}
// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAlertData = (
  alertPayload: { [key: string]: any }, // eslint-disable-line @typescript-eslint/no-explicit-any
  voAlertPayload: { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const voAlertDetails = voAlertPayload
  const voAlertDetailKeys = Object.keys(voAlertDetails)

  try {
    // Server is returning redundant keys in the ALERT_DETAILS payload.
    // We need to filter out any keys that were also in VO_ALERT_DETAILS.
    // fix with https://jira.splunk.com/browse/VOI-116
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const alertDetails: { [key: string]: any } = {}
    Object.keys(alertPayload)
      .filter((alertKey) => !voAlertDetailKeys.includes(alertKey))
      .forEach((key) => {
        alertDetails[key] = alertPayload[key]
          ? decode(alertPayload[key])
          : alertPayload[key]
      })

    voAlertDetailKeys.forEach((key) => {
      voAlertDetails[key] = voAlertDetails[key]
        ? decode(voAlertDetails[key])
        : voAlertDetails[key]
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getIsNullField = (value: any) => isNil(value) || value === ''
    const strippedAlertDetails = omitBy(alertDetails, getIsNullField)
    const strippedVoAlertDetails = omitBy(voAlertDetails, getIsNullField)

    const nullFieldCount =
      Object.keys(alertDetails).length -
      Object.keys(strippedAlertDetails).length +
      Object.keys(voAlertDetails).length -
      Object.keys(strippedVoAlertDetails).length

    return {
      alertDetails,
      voAlertDetails,
      strippedAlertDetails,
      strippedVoAlertDetails,
      nullFieldCount,
    }
  } catch (error) {
    logError(error, { info: { voAlertDetails, voAlertDetailKeys } })
    return null
  }
}

/**
 * Takes an INCIDENT_DATA_NOTIFY_MESSAGE containing conference bridge information
 * and parses the conference bridge information into an object to be put into the store.
 * @param payload message payload
 */
// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformConferenceBridgeUpdateMessage = (payload: any) => {
  try {
    const conferenceBridgeData = payload.PAYLOAD.INCIDENT_DATA.find(
      // fix with https://jira.splunk.com/browse/VOI-116
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => data.CATEGORY === 'conferencebridge'
    )
    return {
      // VALUE_DETAIL is an optional field on the payload, which was done to support project fury. This will not change.
      url: conferenceBridgeData?.VALUE_DETAIL?.url as string,
      phone: conferenceBridgeData?.VALUE_DETAIL?.phone as string,
      notes: conferenceBridgeData?.VALUE_DETAIL?.notes as string,
      id: conferenceBridgeData?.VALUE_DETAIL?.id as number,
      name: conferenceBridgeData?.VALUE_DETAIL?.name as string,
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformIncidentDetailReply = (payload: any) => {
  try {
    const data = cloneDeep(payload)
    /* eslint-disable camelcase */
    if (!data.PAYLOAD.INCIDENT_DETAILS.monitoring_tool) {
      data.PAYLOAD.INCIDENT_DETAILS.monitoring_tool = ''
    }
    if (!data.PAYLOAD.VO_INCIDENT_DETAILS.entity_display_name) {
      data.PAYLOAD.VO_INCIDENT_DETAILS.entity_display_name = ''
    }

    if (!data.PAYLOAD.VO_INCIDENT_DETAILS.host_name) {
      data.PAYLOAD.VO_INCIDENT_DETAILS.host_name = ''
    }

    if (!data.PAYLOAD.VO_INCIDENT_DETAILS.routing_key) {
      data.PAYLOAD.VO_INCIDENT_DETAILS.routing_key = ''
    }

    if (!data.PAYLOAD.VO_INCIDENT_DETAILS.entity_id) {
      data.PAYLOAD.VO_INCIDENT_DETAILS.entity_id = ''
    }

    /* eslint-enable camelcase */

    delete data.PAYLOAD.INCIDENT_DETAILS.vo_annotate

    const {
      alertDetails,
      voAlertDetails,
      strippedAlertDetails,
      strippedVoAlertDetails,
      nullFieldCount,
    } = getAlertData(
      data.PAYLOAD.INCIDENT_DETAILS,
      data.PAYLOAD.VO_INCIDENT_DETAILS
    )

    return {
      annotations: parseAnnotations(data.PAYLOAD.INCIDENT_DETAILS),
      hostName: data.PAYLOAD.INCIDENT_DETAILS.host_name,
      routingKey: data.PAYLOAD.INCIDENT_DETAILS.routing_key,
      alertIncidentData: {
        alertIncidentDetails: alertDetails,
        voAlertIncidentDetails: voAlertDetails,
        strippedAlertIncidentDetails: strippedAlertDetails,
        strippedVoAlertIncidentDetails: strippedVoAlertDetails,
        nullFieldCount,
      },
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}
// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformIncidentReply = (payload: any) => {
  try {
    const data = cloneDeep(payload)
    const incident = data.PAYLOAD.INCIDENTS[0]
    const userMap = data.PAYLOAD.USER_DETAILS

    /* eslint-disable camelcase */
    if (!incident.MONITOR_TYPE) {
      incident.MONITOR_TYPE = ''
    }
    if (!incident.ENTITY_DISPLAY_NAME) {
      incident.ENTITY_DISPLAY_NAME = ''
    }
    if (!incident.ENTITY_ID) {
      incident.ENTITY_ID = ''
    }
    /* eslint-enable camelcase */

    const entityStatus =
      incident.CURRENT_ALERT_PHASE === 'ACKED' && incident.SNOOZED_UNTIL
        ? 'SNOOZED'
        : incident.CURRENT_ALERT_PHASE.toUpperCase()
    const entityDescription = incident.ENTITY_DISPLAY_NAME
      ? decode(incident.ENTITY_DISPLAY_NAME)
      : incident.ENTITY_ID
    const incidentTimestamp = incident.INCIDENT_TIMESTAMP
    const acknowledgedTimestamp = incident.ACK_TIMESTAMP || 0
    const resolvedTimestamp = incident.RESOLVED_AT || 0
    const snoozedUntil = incident.SNOOZED_UNTIL || 0
    const isMuted = incident.IS_MUTED || false
    const {
      ACK_DATA: {
        ACKS_EXPECTED: acksExpected,
        ACKS_RECEIVED: acksReceived,
        ACK_USERS: ackedUsers,
        ACKS: acks,
      },
      IS_MULTI_RESPONDER: isMultiResponder,
      POLICIES_PAGED: policiesPaged,
      USERS_PAGED: pagedUsers,
      ACK_USER: ackUser,
    } = incident

    return {
      ackData: {
        acks,
        acksExpected,
        acksReceived,
        ackUser,
        ackedUsers,
        ackedUsersDispNames: getUserDisplayNames(userMap, ackedUsers),
        isMultiResponder,
        pagedUsers,
        pagedUsersDispNames: getUserDisplayNames(userMap, pagedUsers),
        policiesPaged,
      },
      entityDescription,
      entityStatus,
      monitorTool: incident.MONITOR_TYPE.toLowerCase(),
      incidentTimestamp,
      acknowledgedTimestamp,
      resolvedTimestamp,
      snoozedUntil,
      isMuted,
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

/**
 * Takes a ENTITY_STATE_NOTIFY_MESSAGE and returns payload data that is put into the store,
 *   to notify that incident state has changed (such as hitting resolve button).
 * @param payload message payload
 */
// fix with https://jira.splunk.com/browse/VOI-116
/*  eslint-disable @typescript-eslint/no-explicit-any */
export const transformEntityStateNotifyMessage = (payload: any) => {
  try {
    const userMap = payload.PAYLOAD.USER_DETAILS
    const incidentList = payload.PAYLOAD.SYSTEM_ALERT_STATE_LIST.map(
      (incident: any) => {
        // If the alert phase is "ACKED" and there is a SNOOZED_UNTIL value, then that means the entity has been snoozed.
        const entityStatus =
          incident.CURRENT_ALERT_PHASE === 'ACKED' && incident.SNOOZED_UNTIL
            ? 'SNOOZED'
            : incident.CURRENT_ALERT_PHASE.toUpperCase()

        const acknowledgedTimestamp = incident.ACK_TIMESTAMP || 0
        const resolvedTimestamp = incident.RESOLVED_AT || 0
        const snoozedUntil = incident.SNOOZED_UNTIL || 0

        const {
          ACK_DATA: {
            ACKS_EXPECTED: acksExpected,
            ACKS_RECEIVED: acksReceived,
            ACK_USERS: ackedUsers,
            ACKS: acks,
          },
          IS_MULTI_RESPONDER: isMultiResponder,
          IS_MUTED: isMuted,
          NAME: incidentId,
          POLICIES_PAGED: policiesPaged,
          USERS_PAGED: pagedUsers,
        } = incident

        return {
          ackData: {
            acks,
            acksExpected,
            acksReceived,
            ackedUsers,
            ackedUsersDispNames: getUserDisplayNames(userMap, ackedUsers),
            isMultiResponder,
            pagedUsers,
            pagedUsersDispNames: getUserDisplayNames(userMap, pagedUsers),
            policiesPaged,
          },
          entityStatus,
          isMuted,
          acknowledgedTimestamp,
          resolvedTimestamp,
          incidentId,
          snoozedUntil,
        }
      }
    )
    return incidentList
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const createTeamSlugsArray = (policies: IncidentPolicy[]) => {
  if (policies !== undefined && policies.length > 0) {
    return policies.map((policy) => policy.TEAM?.SLUG)
  }
  return []
}

const isUserCreateIncident = (
  monitorType: string,
  monitorName: string,
  username: string
) =>
  monitorType.toLowerCase() === 'manual' &&
  monitorName.toLowerCase() === `vouser-${username}`

/**
 * Function transformEntityStateNotifyIntoIncidentsUpdate() -- Transforms socket data for incident updates, specifically
 *   for the incidents table.
 *
 * @param payload payload sent with ENTITY_STATE_NOTIFY
 * @TODO we need to get consistent with entity statuses -- "acknowledged" vs "acknowledgment", upper vs lower case,
 *   so that we don't have to repeat code constantly for every variation.
 */
export const transformEntityStateNotifyIntoIncidentsUpdate = (
  // fix with https://jira.splunk.com/browse/VOI-116
  /* eslint-disable @typescript-eslint/no-explicit-any */
  payload: any,
  username = '',
  draft: InitialState
) => {
  try {
    const userMap = payload.PAYLOAD.USER_DETAILS
    const getListStatus = (incident: any) => {
      switch (incident.CURRENT_ALERT_PHASE.toUpperCase()) {
        case 'ACKNOWLEDGEMENT':
        case 'ACKNOWLEDGED':
        case 'ACKED':
          return incident.SNOOZED_UNTIL ? 'snoozed' : 'acknowledged'
        case 'SNOOZED':
          return 'snoozed'
        case 'RECOVERY':
        case 'RESOLVED':
          return 'resolved'
        default:
          return 'triggered'
      }
    }

    const transformIncident = (
      incident: any,
      updateIncident?: IncidentData
    ): IncidentData => {
      const status = getListStatus(incident)

      return {
        acksExpected: incident.ACK_DATA?.ACKS_EXPECTED,
        ackMessage: incident.ACK_MSG,
        acksReceived: incident.ACK_DATA?.ACKS_RECEIVED,
        counts: {
          alertCount: incident.ALERT_COUNT,
          annotationCount: incident.ANNOTATION_COUNT,
          // Chat count updates from from INCIDENT_DATA_NOTIFY_MESSAGE, so keep existing chat count or default to zero
          chatCount: updateIncident ? updateIncident.counts.chatCount : 0,
        },
        currentState: incident.CURRENT_STATE,
        date: getFormattedDate(incident.TS),
        description: incident.ENTITY_DISPLAY_NAME
          ? decode(incident.ENTITY_DISPLAY_NAME)
          : incident.ENTITY_DISPLAY_NAME,
        host: incident.HOST,
        id: incident.NAME,
        isMultiResponder: incident.IS_MULTI_RESPONDER,
        isMuted: incident.IS_MUTED,
        // if current user created incident do not mark as new so it appears
        // immediately, not in new incidents filter
        isNew:
          !isUserCreateIncident(
            incident.MONITOR_TYPE,
            incident.MONITOR_NAME,
            username
          ) &&
          (updateIncident?.isNew || isUndefined(updateIncident?.isNew)),
        isSeen: false,
        monitorName: incident.MONITOR_NAME,
        monitorType: incident.MONITOR_TYPE,
        policiesPaged: incident.POLICIES_PAGED,
        rawDate: incident.TS,
        responders: {
          acked: incident.ACK_DATA?.ACK_USERS,
          ackedDispNames: getUserDisplayNames(
            userMap,
            incident.ACK_DATA?.ACK_USERS
          ),
          paging: incident.USERS_PAGED,
          pagingDispNames: getUserDisplayNames(userMap, incident.USERS_PAGED),
          teams:
            incident.POLICIES_PAGED?.map(
              (policy: any) => `${policy.TEAM?.NAME} (${policy.POLICY?.NAME})`
            ) || [],
        },
        service: incident.SERVICE,
        snoozedUntil: incident.SNOOZED_UNTIL,
        snoozedUntilDisplay: incident.SNOOZED_UNTIL
          ? getFormattedDate(incident.SNOOZED_UNTIL)
          : undefined,
        source: transformSource(incident.MONITOR_TYPE),
        stateMessage: incident.STATE_MESSAGE,
        status,
        statusUser: incident.ACK_USER,
        statusUserDispName: getUserDisplayName(userMap, incident.ACK_USER),
        tags: incident.TAGS,
        teams:
          incident.POLICIES_PAGED?.map(
            (policy: any) => `${policy.TEAM?.NAME} (${policy.POLICY?.NAME})`
          ).join(', ') || '',
        teamSlugs: createTeamSlugsArray(incident.POLICIES_PAGED),
      }
    }

    const incomingIncidents = payload.PAYLOAD.SYSTEM_ALERT_STATE_LIST
    const newIncidents = new Map()

    // First, go through the list of incoming incidents.  For each, look for an existing incident match
    incomingIncidents.forEach((inc: any) => {
      const currentIncidentsIndex = draft.incidents.findIndex(
        (incident: IncidentData) => inc.NAME === incident.id
      )

      // If there is a current incident with the same id, then update that one
      if (currentIncidentsIndex > -1) {
        draft.incidents[currentIncidentsIndex] = transformIncident(
          inc,
          draft.incidents[currentIncidentsIndex]
        )
      }

      // Otherwise, add it into the new incidents map, overwriting duplicates with the latest
      else {
        newIncidents.set(inc.NAME, inc)
      }
    })

    // Now transform the new incidents and add them to the list
    newIncidents.forEach((newInc: any) => {
      draft.incidents.push(transformIncident(newInc))
    })

    return draft.incidents
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

/**
 * transformIncidentCountByUser
 * Returns a structure listing active incidents (acked and paged) by user id.
 */
// eslint-disable @typescript-eslint/no-explicit-any
export const transformIncidentCountByUser = (
  payload: any
): {
  [key: string]: { acked: string[]; paged: string[] }
} => {
  try {
    const payloadIncidentList = payload.PAYLOAD.SYSTEM_INCIDENT_STATE.TRIGGERED_INCIDENT_NAMES.concat(
      payload.PAYLOAD.SYSTEM_INCIDENT_STATE.ACKNOWLEDGED_INCIDENT_NAMES
    )

    const incidentsByUser: {
      [key: string]: { acked: string[]; paged: string[] }
    } = {}
    payloadIncidentList.forEach((incident: any) => {
      incident.ACK_DATA.ACK_USERS.forEach((ackUser: string) => {
        if (!incidentsByUser[ackUser]) {
          incidentsByUser[ackUser] = { acked: [], paged: [] }
        }
        if (!incidentsByUser[ackUser].acked.includes(incident.NAME)) {
          incidentsByUser[ackUser].acked.push(incident.NAME)
        }
      })
      incident.USERS_PAGED.forEach((pageUser: string) => {
        if (!incidentsByUser[pageUser]) {
          incidentsByUser[pageUser] = { acked: [], paged: [] }
        }
        if (!incidentsByUser[pageUser].paged.includes(incident.NAME)) {
          incidentsByUser[pageUser].paged.push(incident.NAME)
        }
      })
    })

    return incidentsByUser
  } catch (error) {
    logError(error)
    return {}
  }
}
// eslint-enable @typescript-eslint/no-explicit-any

/**
 * transformIncidentCountByUserUpdate
 * Returns a structure listing active incidents (acked and paged) by user id.  Used by ENTITY_STATE_NOTIFY_MESSAGES
 *   for updating the list.
 */
// eslint-disable @typescript-eslint/no-explicit-any
export const transformIncidentCountByUserUpdate = (
  incidents: InitialState['incidents']
): {
  [key: string]: { acked: string[]; paged: string[] }
} => {
  try {
    const incidentsByUser: {
      [key: string]: { acked: string[]; paged: string[] }
    } = {}
    incidents.forEach((incident: any) => {
      if (incident.status !== 'resolved') {
        incident.responders.acked.forEach((ackUser: string) => {
          if (!incidentsByUser[ackUser]) {
            incidentsByUser[ackUser] = { acked: [], paged: [] }
          }
          if (!incidentsByUser[ackUser].acked.includes(incident.id)) {
            incidentsByUser[ackUser].acked.push(incident.id)
          }
        })
        incident.responders.paging.forEach((pageUser: string) => {
          if (!incidentsByUser[pageUser]) {
            incidentsByUser[pageUser] = { acked: [], paged: [] }
          }
          if (!incidentsByUser[pageUser].paged.includes(incident.id)) {
            incidentsByUser[pageUser].paged.push(incident.id)
          }
        })
      }
    })

    return incidentsByUser
  } catch (error) {
    logError(error)
    return {}
  }
}
// eslint-enable @typescript-eslint/no-explicit-any

export const payloadHasIncidents = (payload: any) => {
  try {
    const rawListByStatus: { [key: string]: any } = {
      triggered: payload.PAYLOAD.SYSTEM_INCIDENT_STATE.TRIGGERED_INCIDENT_NAMES,
      acknowledged:
        payload.PAYLOAD.SYSTEM_INCIDENT_STATE.ACKNOWLEDGED_INCIDENT_NAMES,
      resolved: payload.PAYLOAD.SYSTEM_INCIDENT_STATE.RESOLVED_INCIDENT_NAMES,
    }
    return Object.values(rawListByStatus).some(
      (incidentsList) => incidentsList.length > 0
    )
  } catch (error) {
    logError(error)
    // default to payload having incidents
    return true
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Takes a STATE_NOTIFY_MESSAGE containing a SYSTEM_INCIDENT_STATE payload and returns
 * a list of all incidents currently available.
 * @param payload
 */
// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformStateNotifyMessage = (payload: any) => {
  try {
    // fix with https://jira.splunk.com/browse/VOI-116
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rawListByStatus: { [key: string]: any } = {
      triggered: payload.PAYLOAD.SYSTEM_INCIDENT_STATE.TRIGGERED_INCIDENT_NAMES,
      acknowledged:
        payload.PAYLOAD.SYSTEM_INCIDENT_STATE.ACKNOWLEDGED_INCIDENT_NAMES,
      resolved: payload.PAYLOAD.SYSTEM_INCIDENT_STATE.RESOLVED_INCIDENT_NAMES,
    }
    const userMap = payload.PAYLOAD.SYSTEM_INCIDENT_STATE.USER_DETAILS
    const incidentList: IncidentData[] = []
    Object.keys(rawListByStatus).map((status: Status) =>
      // fix with https://jira.splunk.com/browse/VOI-116
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rawListByStatus[status].map((incident: any) =>
        incidentList.push({
          acksExpected: incident.ACK_DATA?.ACKS_EXPECTED,
          ackMessage: incident.ACK_MSG,
          acksReceived: incident.ACK_DATA?.ACKS_RECEIVED,
          counts: {
            alertCount: incident.ALERT_COUNT,
            annotationCount: incident.ANNOTATION_COUNT,
            chatCount: incident.INCIDENT_DATA.reduce(
              // fix with https://jira.splunk.com/browse/VOI-116
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (total: number, data: any) =>
                data.CATEGORY === 'chat_count' && data.VALUE === 'chat_count'
                  ? total + data.VALUE_DETAIL?.chatCount
                  : total,
              0
            ),
          },
          currentState: incident.CURRENT_STATE,
          date: getFormattedDate(incident.TS),
          description: incident.ENTITY_DISPLAY_NAME
            ? decode(incident.ENTITY_DISPLAY_NAME)
            : incident.ENTITY_DISPLAY_NAME,
          host: incident.HOST,
          id: incident.NAME,
          isMultiResponder: incident.IS_MULTI_RESPONDER,
          isMuted: incident.IS_MUTED,
          isNew: false,
          isSeen: false,
          monitorName: incident.MONITOR_NAME,
          monitorType: incident.MONITOR_TYPE,
          policiesPaged: incident.POLICIES_PAGED,
          rawDate: incident.TS,
          responders: {
            acked: incident.ACK_DATA?.ACK_USERS,
            ackedDispNames: getUserDisplayNames(
              userMap,
              incident.ACK_DATA?.ACK_USERS
            ),
            paging: incident.USERS_PAGED,
            pagingDispNames: getUserDisplayNames(userMap, incident.USERS_PAGED),
            teams:
              incident.POLICIES_PAGED?.map(
                // fix with https://jira.splunk.com/browse/VOI-116
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (policy: any) => `${policy.TEAM?.NAME} (${policy.POLICY?.NAME})`
              ) || [],
          },
          service: incident.SERVICE,
          snoozedUntil: incident.SNOOZED_UNTIL,
          snoozedUntilDisplay: incident.SNOOZED_UNTIL
            ? getFormattedDate(incident.SNOOZED_UNTIL)
            : undefined,
          source: transformSource(incident.MONITOR_TYPE),
          stateMessage: incident.STATE_MESSAGE,
          status: incident.SNOOZED_UNTIL ? 'snoozed' : status,
          statusUser: incident.ACK_USER,
          statusUserDispName: getUserDisplayName(userMap, incident.ACK_USER),
          tags: incident.TAGS,
          teams:
            incident.POLICIES_PAGED?.map(
              // fix with https://jira.splunk.com/browse/VOI-116
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (policy: any) => `${policy.TEAM?.NAME} (${policy.POLICY?.NAME})`
            ).join(', ') || '',
          teamSlugs: createTeamSlugsArray(incident.POLICIES_PAGED),
        })
      )
    )

    return incidentList
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformOnCallData = (shifts: OnCallShiftDTO[]): OnCallShift[] =>
  shifts
    .map(
      ({
        GROUP_ID: teamSlug,
        POLICY_NAME: policyName,
        POLICY_SLUG: policySlug,
        STATE: state,
        TEAM_NAME: teamName,
        USER_ID: username,
      }) => ({
        policyName,
        policySlug,
        state,
        teamName,
        teamSlug,
        username,
      })
    )
    // Invited users can be on-call, which breaks the people pane
    .filter(({ username }) => {
      if (username.includes('invited_')) {
        logError(
          'Invited user is set to be on-call but cannot be displayed in the people pane',
          {
            info: { username },
            level: Severity.Warning,
          }
        )
        return false
      }
      return true
    })

// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformAlertDetailMessageData = (payload: any) => {
  try {
    const data = cloneDeep(payload)
    const voUuid: string = data.PAYLOAD.VO_UUID
    const {
      alertDetails,
      voAlertDetails,
      strippedAlertDetails,
      strippedVoAlertDetails,
      nullFieldCount,
    } = getAlertData(data.PAYLOAD.ALERT_DETAILS, data.PAYLOAD.VO_ALERT_DETAILS)

    return {
      [voUuid]: {
        alertDetails,
        voAlertDetails,
        strippedAlertDetails,
        strippedVoAlertDetails,
        nullFieldCount,
      },
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformTimelineListReply = (payload: any) => {
  try {
    const data = cloneDeep(payload).PAYLOAD.TIMELINE_LIST
    const userMap = payload.PAYLOAD.USER_DETAILS
    const transformedData: TimelineMessage[] = data.map(
      // fix with https://jira.splunk.com/browse/VOI-116
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (timelineCard: Record<string, any>) => {
        return transformTimelineMessageData(timelineCard, userMap)
      }
    )

    const keyedTimeline: KeyedTimeline = keyBy(transformedData, ({ id }) => {
      return id
    })
    return keyedTimeline
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}
// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformChatNotifyMessage = (payload: any) => {
  try {
    const data = cloneDeep(payload).PAYLOAD
    const userMap = payload.USER_DETAILS
    const id = data.CHAT.SEQUENCING.SEQUENCE

    return {
      [id]: transformTimelineMessageData(data, userMap),
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}
// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformPagingNotifyMessage = (payload: any) => {
  try {
    const data = cloneDeep(payload).PAYLOAD
    const userMap = payload.USER_DETAILS
    const id = data.PAGE.SEQUENCING.SEQUENCE

    return {
      [id]: transformTimelineMessageData(data, userMap),
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

// fix with https://jira.splunk.com/browse/VOI-116
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformStakeholderNotifyMessage = (payload: any) => {
  try {
    const data = cloneDeep(payload).PAYLOAD
    const userMap = payload.USER_DETAILS
    const id = data.STAKEHOLDER_MESSAGE.SEQUENCING.SEQUENCE

    return {
      [id]: transformTimelineMessageData(data, userMap),
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}

/**
 * function transformChatCountUpdateMessage - right now this only handles changes in chat count, but will
 *   be extended to handle different incident metadata changes in the future.
 * @param payload required INCIDENT_DATA_NOTIFY_MESSAGE
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformChatCountUpdateMessage = (payload: any) => {
  try {
    const incident = payload.PAYLOAD

    return {
      id: incident.INCIDENT_ID,

      chatCount: incident.INCIDENT_DATA.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (total: number, data: any) =>
          data.CATEGORY === 'chat_count' && data.VALUE === 'chat_count'
            ? total + data.VALUE_DETAIL?.chatCount
            : total,
        0
      ),
    }
  } catch (error) {
    logError(error, { info: payload })
    return null
  }
}
