import { postAPI } from './common'

export interface NoisyIncidentsRequestDTO {
  teamSlugs: string[]
}

export interface NoisyIncidentsFeedbackRequestDTO {
  entityDisplayName: string
  entityId: string
  feedback: string
  teamSlugs: string[]
}

export interface NoisyIncidentResponseDTO {
  // eslint-disable-next-line camelcase
  entity_display_name: string
  // eslint-disable-next-line camelcase
  entity_id: number
  reason: string
  // eslint-disable-next-line camelcase
  incident_ids: number[]
}

export const fetchNoisyIncidents = (
  orgSlug: string,
  payload: NoisyIncidentsRequestDTO
) =>
  postAPI(`v1/org/${orgSlug}/ds/noisy-incidents`, {
    team_slugs: payload.teamSlugs,
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    })
    .then((response) => ({
      ...response,
      noisyIncidents: response.noisyIncidents.map(
        ({
          // eslint-disable-next-line camelcase
          entity_display_name,
          // eslint-disable-next-line camelcase
          entity_id,
          // eslint-disable-next-line camelcase
          incident_ids,
          ...rest
        }: NoisyIncidentResponseDTO) => ({
          ...rest,
          entityDisplayName: entity_display_name,
          entityId: entity_id,
          incidentIds: incident_ids,
        })
      ),
    }))

export const postNoisyIncidentFeedback = (
  orgSlug: string,
  payload: NoisyIncidentsFeedbackRequestDTO
) =>
  postAPI(`v1/org/${orgSlug}/ds/noisy-feedback`, {
    org_slug: orgSlug,
    entity_id: payload.entityId,
    entity_display_name: payload.entityDisplayName,
    feedback: payload.feedback,
    team_slugs: payload.teamSlugs,
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }

      return response.json()
    })
    .then((response) => ({
      ...response,
      noisyIncidents: response.noisyIncidents.map(
        ({
          // eslint-disable-next-line camelcase
          entity_display_name,
          // eslint-disable-next-line camelcase
          entity_id,
          // eslint-disable-next-line camelcase
          incident_ids,
          ...rest
        }: NoisyIncidentResponseDTO) => ({
          ...rest,
          entityDisplayName: entity_display_name,
          entityId: entity_id,
          incidentIds: incident_ids,
        })
      ),
    }))
