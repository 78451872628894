import React, { memo, FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { State } from 'app/reducer'
import { CardMessage } from '../../cards'

export interface RowMessageProps {
  dataSetID: string
  id: number
}

export const RowMessage: FunctionComponent<RowMessageProps> = memo(
  ({ dataSetID, id }) => {
    const date = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].date
    )
    const message = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].message
    )

    return (
      /* the "id" prop ends up on html elements as an attribute, which is why we turn it into a string */
      <CardMessage id={id.toString()} {...{ date, message }} />
    )
  }
)

RowMessage.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
