import { createSlice } from '@reduxjs/toolkit'

interface SnackbarMessage {
  message: string
  severity: string
}
interface InitialState {
  snackbarMessage: SnackbarMessage
}
const initialState: InitialState = {
  snackbarMessage: null,
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbarMessage: (state, { payload }) => {
      return {
        ...state,
        snackbarMessage: {
          message: payload.message,
          severity: payload.severity,
        },
      }
    },

    unsetSnackbarMessage: (state) => {
      return {
        ...state,
        snackbarMessage: null,
      }
    },
  },
})

export const {
  setSnackbarMessage,
  unsetSnackbarMessage,
} = snackbarSlice.actions
export const { reducer: snackbar } = snackbarSlice
