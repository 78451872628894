import styled from 'styled-components'

export const Header = styled.span`
  display: block;
  border-top: thin solid ${({ theme }) => theme.palette.grays[4]};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.grays[5]};

  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: ${({ theme }) =>
    `${theme.functions.getSpace(2)} ${theme.functions.getSpace(2, -2)}`};
`

export const EmptyState = styled.span`
  color: ${({ theme }) => theme.palette.grays[2]};
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.functions.getSpace(2)} ${theme.functions.getSpace(2, -2)}`};
`
