import { fetchAPI, putAPI } from '../../../common'
import { SPIORetryCount, getStatusPageErrorMessage } from './common'
import {
  SPIOComponent,
  StatusPageComponent,
  StatusPageComponentDTO,
  StatusPageComponentGroup,
  StatusPageSubComponent,
} from '../types'

const fetchStatusPageComponentsWithRetry = (
  retryCount: number,
  orgSlug: string
): Promise<StatusPageComponentDTO[]> =>
  fetchAPI(`v1/org/${orgSlug}/statuspage/components`).then((response) => {
    if (!response.ok) {
      if (response.status === 400) {
        if (retryCount < SPIORetryCount) {
          return fetchStatusPageComponentsWithRetry(retryCount + 1, orgSlug)
        }
      }
      throw new Error(
        getStatusPageErrorMessage(response.status, 'GET', 'components')
      )
    }
    return response.json()
  })

const fetchStatusPageComponents = (orgSlug: string) =>
  fetchStatusPageComponentsWithRetry(0, orgSlug)

const putStatusPageComponentsWithRetry = (
  retryCount: number,
  orgSlug: string,
  components: StatusPageComponentDTO[]
): Promise<StatusPageComponentDTO[]> =>
  putAPI(`v1/org/${orgSlug}/statuspage/components`, components).then(
    (response) => {
      if (!response.ok) {
        if (response.status === 400) {
          if (retryCount < SPIORetryCount) {
            return putStatusPageComponentsWithRetry(
              retryCount + 1,
              orgSlug,
              components
            )
          }
        }
        throw new Error(
          getStatusPageErrorMessage(response.status, 'PUT', 'components')
        )
      }
      return response.json()
    }
  )

const putStatusPageComponents = (
  orgSlug: string,
  components: StatusPageComponentDTO[]
) => putStatusPageComponentsWithRetry(0, orgSlug, components)

const isStatusPageComponent = (
  component: SPIOComponent
): component is SPIOComponent =>
  (component as StatusPageComponent).type === 'StatusPageComponent'

const isStatusPageComponentGroup = (
  component: SPIOComponent
): component is StatusPageComponentGroup =>
  (component as StatusPageComponentGroup).children !== undefined

// this is how we check if sub component when we call api because it doesn't return types
const isStatusPageSubComponent = (
  component: StatusPageComponentDTO
): component is StatusPageSubComponent =>
  (component as StatusPageSubComponent).group_id !== null

export const Components = {
  fetchStatusPageComponents,
  putStatusPageComponents,
  isStatusPageComponent,
  isStatusPageComponentGroup,
  isStatusPageSubComponent,
}
