import { fetchAPI } from './common'

export interface PolicyDTO {
  slug: string
  name: string
}

export interface TeamDTO {
  slug: string
  name: string
  _policies?: PolicyDTO[]
}

export interface TeamDataDTO {
  name: string
  slug: string
  memberCount: number
  isDefaultTeam: boolean
  version: number
}

export const fetchTeam = (orgSlug: string, teamSlug: string) => {
  return fetchAPI(`v1/org/${orgSlug}/teams/${teamSlug}`).then((response) =>
    response.json()
  )
}

export const fetchTeams = (orgSlug: string, search?: string) => {
  const searchQuery = search ? `?search=${search}` : ''
  return fetchAPI(`v2/org/${orgSlug}/teams${searchQuery}`).then((response) =>
    response.json()
  )
}

export const fetchTeamsWithPolicies = (orgSlug: string) =>
  fetchAPI(`v2/org/${orgSlug}/teams?include=policies`).then((response) =>
    response.json().then((fromServer) =>
      (fromServer as TeamDTO[])
        .map((team) => {
          return {
            ...team,
            // eslint-disable-next-line no-underscore-dangle
            policies: team._policies.sort((a, b) => {
              return a.name.localeCompare(b.name)
            }),
          }
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
    )
  )

export const fetchTeamMembers = (orgSlug: string, teamSlug: string) =>
  fetchAPI(`v1/org/${orgSlug}/teams/${teamSlug}/members`).then((response) =>
    response.json()
  )
