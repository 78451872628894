import React, { FunctionComponent, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { themes } from '@victorops/victoria'
import { configureStore } from '@reduxjs/toolkit'
import { setUser } from '@sentry/browser'

import { fetchConfig, getOrgslugFromLocation } from 'utils'
import { Loading } from 'components/common'
import { reducer, State } from '../reducer'
import { Entry } from '../entry'
import { initErrorReporter, printVersion } from './utils'

export interface OnCallAppProps {
  isOlly?: boolean
}

export const OnCallApp: FunctionComponent<OnCallAppProps> = ({ isOlly }) => {
  const [initAppData, setInitAppData] = useState<Partial<State>>()

  // App initializers
  useEffect(() => {
    printVersion()

    fetchConfig(getOrgslugFromLocation()).then((config) => {
      setInitAppData(config)
    })
  }, [])

  // Error reporter initializer
  useEffect(() => {
    if (!isOlly) initErrorReporter()
  }, [isOlly])

  // Set username in error reporter from config data
  useEffect(() => {
    if (!isOlly && initAppData?.user.username) {
      setUser({ username: initAppData.user.username })
    }
  }, [initAppData, isOlly])

  return initAppData ? (
    <Provider
      store={configureStore({
        preloadedState: { ...initAppData, isOlly },
        reducer,
      })}
    >
      <Normalize />
      <ThemeProvider theme={themes.voLight}>
        <Entry />
      </ThemeProvider>
    </Provider>
  ) : (
    <ThemeProvider theme={themes.voLight}>
      <Loading />
    </ThemeProvider>
  )
}

OnCallApp.defaultProps = { isOlly: false }

OnCallApp.propTypes = {
  isOlly: PropTypes.bool,
}
