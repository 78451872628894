import React, { memo, FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import Timeline from '@victorops/timeline'
import { ErrorBoundary } from '@victorops/victoria'

import { logError } from 'utils'
import { useTimeline } from '../utils'
import { NewDataDisplay } from './components/new-data-display/new-data-display'
import { Container } from './vo-timeline-styles'
import { RowRenderer } from './components'

export interface VOTimelineProps {
  dataSetID: string
}

const VOTimelineComponent: FunctionComponent<VOTimelineProps> = memo(
  ({ dataSetID }) => {
    const props = useTimeline(dataSetID)

    return (
      <Container>
        <Timeline
          fetchMoreRowBuffer={100}
          hasLastRow
          {...{
            RowRenderer,
            NewDataDisplay,
            ...props,
          }}
        />
      </Container>
    )
  }
)

VOTimelineComponent.propTypes = {
  dataSetID: PropTypes.string.isRequired,
}

export const VOTimeline: FunctionComponent<VOTimelineProps> = (props) => (
  <ErrorBoundary {...{ logError }}>
    <VOTimelineComponent {...props} />
  </ErrorBoundary>
)
