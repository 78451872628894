import { differenceInSeconds, format, formatDistance, isBefore } from 'date-fns'

export const getFormattedDate = (date: Parameters<typeof format>[0]) =>
  format(date, 'MMM. d - h:mm a')

export const getFormattedTime = (date: Parameters<typeof format>[0]) =>
  format(date, 'h:mm a')

export const getFormattedDateTime = (date: Parameters<typeof format>[0]) =>
  format(date, 'E, MMM d, yyyy HH:mm:ss (OOOO)')

/**
 * getTimeDifference() -- returns an expression of the difference between an earlier date and a later
 *   one.  If the difference is less than 24 hours, it will return it as `xx hrs yy mins` (drop the
 *   seconds).  If it is greater, then it will use date-fns' formatDistance() which provides an english
 *   estimate of the time passed.
 * @param earlierDate
 * @param laterDate
 */
export const getTimeDifference = (
  earlierDate: Date | number,
  laterDate: Date | number
) => {
  const diffSecs = differenceInSeconds(laterDate, earlierDate)

  const diffMins = diffSecs >= 60 ? Math.floor(diffSecs / 60) : 0
  const mins = diffMins % 60
  const diffHours = diffMins >= 60 ? Math.floor(diffMins / 60) : 0

  if (diffHours >= 24) {
    return formatDistance(laterDate, earlierDate)
  }

  return `${diffHours} hrs ${mins} mins`
}

/**
 * Format time difference from now with time measurments abbriviated.
 * If date given is before baseDate, then it will return "seconds ago".
 *
 * @param date - The date to compare
 * @param baseDate - The date to compare to
 *
 * @returns string representation of time difference
 *
 * @example
 * getTimeDifferenceAbbr(sub(getCurrentDate(), { days: 1 }))
 * // -> '1 day ago'
 *
 * @example
 * getTimeDifferenceAbbr(add(getCurrentDate(), { days: 1 }))
 * // -> 'seconds ago'
 */
export const getTimeDifferenceAbbr = (
  date: Parameters<typeof formatDistance>[0],
  baseDate: Parameters<typeof formatDistance>[1]
) =>
  formatDistance(date, isBefore(date, baseDate) ? baseDate : date, {
    addSuffix: true,
  })
    .replace(/seconds?/, 's')
    .replace('minute', 'min')
    .replace('hour', 'hr')
    .replace('month', 'mo')
    .replace('year', 'yr')
    .replace('about', '')
    .replace('less than a min', 'seconds')
    .trim()
