import React, { FunctionComponent, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getTimeDifferenceAbbr, translate } from 'utils'
import { State } from 'app/reducer'
import { DateContext } from '../../../utils'
import { StyledRow } from './row-styles'
import { Icon } from './components'

export interface RowMessageProps {
  dataSetID: string
  id: number
}

export const RowMessage: FunctionComponent<RowMessageProps> = ({
  dataSetID,
  id,
}) => {
  const timeStamp = useSelector(
    (state: State) =>
      state.websocketChat[dataSetID].messages[id]?.timeStamp ||
      state.websocketChat[dataSetID].newMessages[id]?.timeStamp
  )
  const message = useSelector(
    (state: State) =>
      state.websocketChat[dataSetID].messages[id]?.message ||
      state.websocketChat[dataSetID].newMessages[id]?.message
  )
  const recipients = useSelector(
    (state: State) =>
      state.websocketChat[dataSetID].messages[id]
        ?.stakeholderMessageRecipientsDispNames ||
      state.websocketChat[dataSetID].newMessages[id]
        ?.stakeholderMessageRecipientsDispNames
  )
  const title = useSelector(
    (state: State) =>
      state.websocketChat[dataSetID].messages[id]?.stakeholderMessageTitle ||
      state.websocketChat[dataSetID].newMessages[id]?.stakeholderMessageTitle
  )

  const baseDate = useContext(DateContext)
  const timeDifference = useMemo(
    () =>
      // Format date with abbvs
      getTimeDifferenceAbbr(timeStamp, baseDate),
    [timeStamp, baseDate]
  )

  return (
    <StyledRow.Container aria-labelledby={`message-heading-${id}`}>
      <StyledRow.Side>
        <StyledRow.VerticalTop />
        <Icon />
        <StyledRow.Time>{timeDifference}</StyledRow.Time>
        <StyledRow.VerticalBottom />
      </StyledRow.Side>

      <StyledRow.Message>
        <StyledRow.Title id={`message-heading-${id}`} role='heading'>
          {title}
        </StyledRow.Title>
        <StyledRow.Recipients>
          {translate('VO.Stakeholders.ToUser', recipients.join(', '))}
        </StyledRow.Recipients>
        <StyledRow.Body>{message}</StyledRow.Body>
      </StyledRow.Message>
    </StyledRow.Container>
  )
}

RowMessage.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
