import React, { FunctionComponent, memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from '@victorops/victoria'

import { State } from 'app/reducer'
import { logError, translate } from 'entry/utils'

import {
  RowAlert,
  RowChat,
  RowIncident,
  RowMessage,
  RowStakeholder,
} from './rows'
import { CardLoading, CardMessage } from '../cards'

export interface RowCardProps {
  dataSetID: string
  id: number
  resize(id: number): null
}

export const RowCardComponent: FunctionComponent<RowCardProps> = memo(
  (props) => {
    const { id, dataSetID, resize } = props

    const dataSet = useSelector((state: State) => {
      return state.websocketChat[dataSetID]
    })

    const hasReachedEnd = useSelector(
      (state: State) => state.websocketChat[`hasReachedEnd-${dataSetID}`]
    )

    // an id of 0 is the timeline component's way of telling others to render a loading or last card.
    if (id === 0) {
      return hasReachedEnd ? (
        <CardMessage
          message={translate('VO.Timeline.YouHaveReachedTheEndOfYourHistory')}
          data-ext='timeline-card-last'
        />
      ) : (
        <CardLoading />
      )
    }

    const { type } = dataSet.messages[id]

    if (type === 'ALERT') {
      return <RowAlert {...{ dataSetID, id, resize }} />
    }
    if (type === 'CHAT') {
      return <RowChat {...{ dataSetID, id }} />
    }
    if (type === 'STAKEHOLDER_MESSAGE') {
      return <RowStakeholder {...{ dataSetID, id }} />
    }
    if (type === 'INCIDENT') {
      return <RowIncident {...{ dataSetID, id }} />
    }
    if (type === 'PAGE') {
      return <RowMessage {...{ dataSetID, id }} />
    }

    throw Error('unknown message row type')
  }
)

RowCardComponent.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  resize: PropTypes.func.isRequired,
}

export const RowCard = (props: RowCardProps) => (
  <ErrorBoundary {...{ logError }}>
    <RowCardComponent {...props} />
  </ErrorBoundary>
)
