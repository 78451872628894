import styled from 'styled-components'

export const ListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSizes.s};
  padding-bottom: ${({ theme }) => theme.functions.getSpace(2)};
`

export const ImageContainer = styled.img`
  max-width: 100%;
  margin-top: ${({ theme }) => theme.functions.getSpace(2)};
`
