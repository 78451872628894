import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'
import { ListItem } from './annotation-styles'

export interface TextAnnotationProps
  extends PropsWithoutRef<JSX.IntrinsicElements['li']> {
  fieldName: string
  fieldValue: string
  dataExt: string
}

export const TextAnnotation: FunctionComponent<TextAnnotationProps> = memo(
  ({ fieldName, fieldValue, dataExt }) => {
    return (
      <ListItem data-ext={`${dataExt}--annotation-text`}>
        <b>{fieldName}:</b> {fieldValue}
      </ListItem>
    )
  }
)

TextAnnotation.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  dataExt: PropTypes.string.isRequired,
}
