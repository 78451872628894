import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'utils'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { EmptyState } from '@victorops/victoria'
import { AnnotationsContainer } from './annotations-styles'
import { Annotation, AnnotationProps } from './annotation'

export interface AnnotationsProps
  extends PropsWithoutRef<JSX.IntrinsicElements['ol']> {
  'data-ext': string
  annotations: AnnotationProps[]
}

export const Annotations: FunctionComponent<AnnotationsProps> = memo(
  ({ annotations, ...rest }) => {
    // show empty state if there are no annotations
    if (!annotations.length) {
      return (
        <EmptyState
          data-ext={`${rest['data-ext']}-empty`}
          icon={faPaperclip}
          headline={translate('VO.Annotations.headerForEmptyState')}
          subhead={translate('VO.Annotations.subheaderForEmptyState')}
          link='https://help.victorops.com/knowledge-base/rules-engine-annotations/'
        />
      )
    }
    const annotationsView = annotations.map((annotation) => (
      <Annotation
        key={annotation.fieldName}
        dataExt={rest['data-ext']}
        {...annotation}
      />
    ))
    return (
      <AnnotationsContainer {...rest}>{annotationsView}</AnnotationsContainer>
    )
  }
)

Annotations.propTypes = {
  'data-ext': PropTypes.string.isRequired,
  annotations: PropTypes.arrayOf(PropTypes.any).isRequired,
}
