import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10vw;
  bottom: 10vh;
  width: 80vw;
  z-index: 1000;
`

export const StyledSnackbar = {
  Container,
}
