export interface UserDispName {
  displayName?: string
  /* eslint-disable camelcase */
  display_name?: string
  /* eslint-enable camelcase */
}

export interface UserMap {
  [username: string]: UserDispName
}

export const getUserDisplayName = (userMap: UserMap = {}, userName = '') => {
  return userMap[userName]
    ? userMap[userName].display_name || userMap[userName].displayName
    : userName
}

export const getUserDisplayNames = (
  userMap: UserMap = {},
  userNames: string[]
) => {
  return userNames
    ? userNames.map((userName: string) => {
        return getUserDisplayName(userMap, userName)
      })
    : []
}
