import React, { FunctionComponent, memo } from 'react'
import PropTypes from 'prop-types'

import { TableRowTitle } from '@victorops/victoria'
import { AlertDetailsTableRow } from '../table-display-styles'

export interface RowProps {
  text?: string | null
  title: string
}

export const Row: FunctionComponent<RowProps> = memo(({ text, title }) => (
  <AlertDetailsTableRow>
    <TableRowTitle>{title}</TableRowTitle>
    <td>{text}</td>
  </AlertDetailsTableRow>
))

Row.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
