import styled from 'styled-components'

const Body = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`

const Container = styled.li`
  display: flex;
`

const Icon = styled.div`
  padding: ${({ theme }) => theme.functions.getSpace(1, 4)};
`

// "width" needed for ie 11
const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.functions.getSpace(3)} 0`};
  width: 100%;
`

const Recipients = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: ${({ theme }) => `${theme.functions.getSpace()} 0`};
`

const Side = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.functions.getSpace(2)};
  width: ${({ theme }) => theme.functions.getSpace(10)};
`

// padding-bottom is for colliding text due to browser differences
const Time = styled.span`
  color: ${({ theme }) => theme.palette.gray2};
  flex: 0 1 auto;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-bottom: 1px;
`

const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const VerticalBottom = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.palette.gray2}`};
  flex: 1 1 auto;
`

const VerticalTop = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.palette.gray2}`};
  height: ${({ theme }) => theme.functions.getSpace(2)};
`

export const StyledRow = {
  Body,
  Container,
  Icon,
  Message,
  Recipients,
  Side,
  Time,
  Title,
  VerticalBottom,
  VerticalTop,
}
