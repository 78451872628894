import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import unionBy from 'lodash/unionBy'

import {
  ReceiveOnCallListStateNotifyMessage,
  transformOnCallData,
  OnCallShifts,
  OnCallShift,
} from 'utils'

const initialState: OnCallShifts = { current: [], next: [] }

const sortShifts = (shiftA: OnCallShift, shiftB: OnCallShift) => {
  if (shiftA.username > shiftB.username) {
    return 1
  }
  if (shiftA.username < shiftB.username) {
    return -1
  }
  return 0
}

const onCallShiftsSlice = createSlice({
  name: 'onCallShifts',
  initialState,
  reducers: {
    setOnCallShifts: (
      draft,
      {
        payload: { ONCALL_LIST },
      }: PayloadAction<ReceiveOnCallListStateNotifyMessage>
    ) => {
      draft.current = transformOnCallData(ONCALL_LIST).sort(sortShifts)
    },
    updateOnCallShifts: (
      draft,
      {
        payload: {
          ON_CALL_STATE_CHANGE: { COMING_ON, GOING_OFF },
        },
      }: PayloadAction<ReceiveOnCallListStateNotifyMessage>
    ) => {
      // Incoming shift data will overwrite what's in state with the same policySlug
      draft.current = unionBy(
        // Remove now off-call shifts from state before comparing to new on-call shifts
        differenceBy(
          draft.current,
          transformOnCallData(GOING_OFF),
          'policySlug'
        ),
        transformOnCallData(COMING_ON),
        'policySlug'
      ).sort(sortShifts)
    },
    updateOnCallShiftsREST: (
      draft,
      { payload: { current, next } }: PayloadAction<OnCallShifts>
    ) => {
      // Will only update current shift data, not add shifts.
      // This is due to a bug in our system that sends taken shifts
      // as still being active for the user it was taken from. 💩
      // https://jira.splunk.com/browse/VOI-512
      draft.current = draft.current.map((shift) => {
        const incomingShift = current.find(
          ({ policySlug }) => policySlug === shift.policySlug
        )

        return incomingShift?.username === shift.username
          ? incomingShift
          : shift
      })

      draft.next = uniqBy([...next, ...draft.next], 'policySlug').sort(
        sortShifts
      )
    },
  },
})

export const {
  setOnCallShifts,
  updateOnCallShifts,
  updateOnCallShiftsREST,
} = onCallShiftsSlice.actions
export const { reducer: onCallShifts } = onCallShiftsSlice
