import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  logError,
  getStatusFilterOutOfLocalStorage,
  getTeamsFilterOutOfLocalStorage,
  setStatusFilterInLocalStorage,
  setTeamsFilterInLocalStorage,
} from 'utils'

export interface GroupListData {
  NAME: string
  GROUP_ID: string
  VERSION: number
  USER_ID_LIST: string[]
  IS_DEFAULT: boolean
}

export interface ReceiveGroupListStateNotifyMessage {
  MESSAGE: string
  PAYLOAD: {
    GROUP_LIST: GroupListData[]
  }
}

export interface StatusFilter {
  [status: string]: boolean
  hasAcknowledged: boolean
  hasNew: boolean
  hasResolved: boolean
  hasSnoozed: boolean
  hasTriggered: boolean
}

export interface TeamFilter {
  name: string
  slug: string
  selected: boolean
  userList?: string[]
}

interface IncidentsTableFilter {
  statusFilter?: StatusFilter
  teamsFilter?: TeamFilter[]
  textFilter?: string
}

const initialFilter: IncidentsTableFilter = {
  statusFilter: {
    hasAcknowledged: true,
    hasNew: false,
    hasResolved: false,
    hasSnoozed: false,
    hasTriggered: true,
  },
  teamsFilter: [],
  textFilter: '',
}

const initialState = {
  filter: initialFilter,
  allIncidentsSelected: false,
}

const incidentsTableSlice = createSlice({
  name: 'incidentsTable',
  initialState,
  reducers: {
    setTextFilter: (
      draft,
      {
        payload: { textFilter },
      }: PayloadAction<{
        textFilter: string
      }>
    ) => {
      draft.filter.textFilter = textFilter
    },
    // This sets the new team filter when the Team Selection Dropdown is used.  Initial load of teams
    //   is handled below by receivedGroupListStateNotifyMessage
    setTeamsFilter: (
      draft,
      {
        payload: { payload, username, orgSlug },
      }: PayloadAction<{
        payload: TeamFilter[]
        username: string
        orgSlug: string
      }>
    ) => {
      setTeamsFilterInLocalStorage(orgSlug, username, JSON.stringify(payload))
      draft.filter.teamsFilter = payload.sort((teamA, teamB) =>
        teamA.name.toUpperCase() > teamB.name.toUpperCase() ? 1 : -1
      )
    },
    setStatusFilter: (
      draft,
      {
        payload: { statusFilter, username, orgSlug },
      }: PayloadAction<{
        statusFilter: StatusFilter
        username: string
        orgSlug: string
      }>
    ) => {
      setStatusFilterInLocalStorage(
        orgSlug,
        username,
        JSON.stringify({ ...statusFilter, hasNew: false })
      )
      draft.filter.statusFilter = statusFilter
    },
    initStatusFilterFromLocalStorage: (
      draft,
      {
        payload: { username, orgSlug },
      }: PayloadAction<{
        username: string
        orgSlug: string
      }>
    ) => {
      const localStorageStatusFilter = getStatusFilterOutOfLocalStorage(
        orgSlug,
        username
      )

      if (localStorageStatusFilter) {
        const statusFilter = JSON.parse(
          localStorageStatusFilter
        ) as StatusFilter
        draft.filter.statusFilter = statusFilter
      }
    },
    resetIncidentsFilter: (proxyState) => {
      // redux toolkit uses immer by default so state is actual a proxy in this instance
      // eslint-disable-next-line no-param-reassign
      proxyState.filter.teamsFilter = proxyState.filter.teamsFilter.map(
        (team: TeamFilter) => Object.assign(team, { selected: true })
      )
      // eslint-disable-next-line no-param-reassign
      proxyState.filter.statusFilter = initialFilter.statusFilter
    },
    setAllIncidentsSelected: (state, { payload }: { payload: boolean }) => ({
      ...state,
      allIncidentsSelected: payload,
    }),
    // GROUP_LIST message gets sent on initial load, has a teams list for the org and says whether the user is a
    //   member of the team or not.  This sets our initial teams list, unless there is one already in local storage.
    receivedGroupListStateNotifyMessage: (
      draft,
      {
        payload: { payload, username, orgSlug },
      }: PayloadAction<{
        payload: ReceiveGroupListStateNotifyMessage
        username: string
        orgSlug: string
      }>
    ) => {
      const localStorageTeamFilter = getTeamsFilterOutOfLocalStorage(
        orgSlug,
        username
      )

      if (localStorageTeamFilter) {
        try {
          const newTeamsFilter = JSON.parse(
            localStorageTeamFilter
          ) as TeamFilter[]
          const teamsFilterStringList: string[] = []
          newTeamsFilter.forEach((team: TeamFilter) => {
            if (team.selected) {
              teamsFilterStringList.push(team.slug)
            }
          })

          draft.filter.teamsFilter = payload.PAYLOAD.GROUP_LIST.sort(
            (teamA, teamB) =>
              teamA.NAME.toUpperCase() > teamB.NAME.toUpperCase() ? 1 : -1
          ).map((group: GroupListData) => ({
            name: group.NAME,
            slug: group.GROUP_ID,
            selected: teamsFilterStringList.includes(group.GROUP_ID),
            userList: group.USER_ID_LIST.filter(
              (user: string) => !user.includes('invited')
            ),
          }))
          return
        } catch (error) {
          logError(
            `Error encountered while getting teams filter out of local storage: ${error}`
          )
        } // No need to do anything else here, just move on to default teams below
      }

      // If we can't find/parse the localStorageFilter, or is invalid, use default teams
      draft.filter.teamsFilter = payload.PAYLOAD.GROUP_LIST.sort(
        (teamA, teamB) =>
          teamA.NAME.toUpperCase() > teamB.NAME.toUpperCase() ? 1 : -1
      ).map((group: GroupListData) => ({
        name: group.NAME,
        slug: group.GROUP_ID,
        selected: group.USER_ID_LIST.includes(username),
        userList: group.USER_ID_LIST.filter(
          (user: string) => !user.includes('invited')
        ),
      }))
    },
  },
})

export const {
  setTextFilter,
  setTeamsFilter,
  setStatusFilter,
  initStatusFilterFromLocalStorage,
  receivedGroupListStateNotifyMessage,
  resetIncidentsFilter,
  setAllIncidentsSelected,
} = incidentsTableSlice.actions

export const { reducer: incidentsTable } = incidentsTableSlice
