import React, { FunctionComponent, memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { logError } from 'utils'
import { State } from 'app/reducer'
import { ErrorBoundary } from '@victorops/victoria'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faComments,
  faBell,
  faPaperclip,
} from '@fortawesome/pro-light-svg-icons'

import { StyledCounterIcons } from './counter-icons-styles'

export interface CounterIconsProps {
  id: string
}

/**
 * FunctionComponent CounterIcons - small component that displays icons with various counts
 * @param id required - incident ID associated with the counts
 */
const CounterIconsComponent: FunctionComponent<CounterIconsProps> = memo(
  ({ id }) => {
    const counts = useSelector(
      (state: State) =>
        state.websocketChat.incidents.find((incident) => incident.id === id)
          .counts
    )

    const { alertCount, annotationCount, chatCount } = counts

    return (
      <StyledCounterIcons.Container data-ext={`vo.oi.${id}.counter-icons`}>
        {chatCount !== null && (
          <StyledCounterIcons.Segment aria-label='Chat Count'>
            <StyledCounterIcons.Icon>
              <FontAwesomeIcon icon={faComments} />
            </StyledCounterIcons.Icon>
            <StyledCounterIcons.Count>{chatCount}</StyledCounterIcons.Count>
          </StyledCounterIcons.Segment>
        )}

        {annotationCount !== null && (
          <StyledCounterIcons.Segment aria-label='Annotation Count'>
            <StyledCounterIcons.Icon>
              <FontAwesomeIcon icon={faPaperclip} />
            </StyledCounterIcons.Icon>
            <StyledCounterIcons.Count>
              {annotationCount}
            </StyledCounterIcons.Count>
          </StyledCounterIcons.Segment>
        )}

        {alertCount !== null && (
          <StyledCounterIcons.Segment aria-label='Alert Count'>
            <StyledCounterIcons.Icon>
              <FontAwesomeIcon icon={faBell} />
            </StyledCounterIcons.Icon>
            <StyledCounterIcons.Count>{alertCount}</StyledCounterIcons.Count>
          </StyledCounterIcons.Segment>
        )}
      </StyledCounterIcons.Container>
    )
  }
)

CounterIconsComponent.propTypes = {
  id: PropTypes.string.isRequired,
}

export const CounterIcons: FunctionComponent<CounterIconsProps> = ({ id }) => {
  const info = {
    incidentId: id,
  }

  return (
    <ErrorBoundary logError={logError} Fallback={<div />} info={info}>
      <CounterIconsComponent {...{ id }} />
    </ErrorBoundary>
  )
}

CounterIcons.propTypes = {
  id: PropTypes.string.isRequired,
}
