import styled from 'styled-components'

export const Footer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grays[5]};
  border-top: ${({ theme }) => `thin solid ${theme.palette.grays[4]}`};
  color: ${({ theme }) => theme.palette.grays[1]};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: ${({ theme }) =>
    `${theme.functions.getSpace(0.5)} ${theme.functions.getSpace(2)}
   ${theme.functions.getSpace(0.5)} ${theme.functions.getSpace(2, -4)}`};

  > :not(:first-child) {
    padding-left: ${({ theme }) => theme.functions.getSpace()};
  }

  .pull-right {
    margin-left: auto;
  }
`

export const Info = styled.span`
  color: ${({ theme }) => theme.palette.grays[1]};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-style: italic;
  margin-top: ${({ theme }) => theme.functions.getSpace(0.5)};
`

export const InfoMuted = styled.span`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-top: ${({ theme }) => theme.functions.getSpace()};
  z-index: 2;

  > :not(:first-child) {
    padding-left: ${({ theme }) => theme.functions.getSpace()};
  }
`

export const Muted = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  height: 100%;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  z-index: 1;
`

export const Username = styled.span`
  color: ${({ theme }) => theme.palette.splunkCobalt};
`
