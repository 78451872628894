import { StatusPageFetch } from './base'

export enum StatusPageIncidentStatus {
  INVESTIGATING = 'investigating',
  IDENTIFIED = 'identified',
  MONITORING = 'monitoring',
  RESOLVED = 'resolved',
}
export interface StatusPageIncidentDTO extends StatusPageFetch {
  status: StatusPageIncidentStatus
  message: string
  groups?: string[]
  // from server
  /* eslint-disable camelcase */
  component_ids: string[]
  created_by: string
  /* eslint-enable camelcase */
}

export interface StatusPageIncidentPostDTO
  extends Partial<StatusPageIncidentDTO> {
  id?: string
  // from server
  /* eslint-disable camelcase */
  created_at?: number
  updated_at?: number
  created_by?: string
  /* eslint-enable camelcase */
}
