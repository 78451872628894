import { createSlice } from '@reduxjs/toolkit'

const autoPauseSlice = createSlice({
  name: 'autoPause',
  initialState: {
    autoPauseThreshold: undefined,
    activeIncidentCount: undefined,
  },
  reducers: {},
})

export const { reducer: autoPause } = autoPauseSlice
