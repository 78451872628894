export enum EnumIncidentStatuses {
  ACKNOWLEDGED = 'acknowledged',
  TRIGGERED = 'triggered',
  RESOLVED = 'resolved',
  SNOOZED = 'snoozed',
  LOADING = 'loading',
}

/**
 * getEntityStatus() returns a definitive status value based on several variations
 * @param status a status string derived from one of a couple sources
 */
export const getEntityStatus = (statusString: string) => {
  if (!statusString) {
    return EnumIncidentStatuses.LOADING
  }
  if (
    statusString === 'UP' ||
    statusString === 'RECOVERY' ||
    statusString === 'RESOLVED'
  ) {
    return EnumIncidentStatuses.RESOLVED
  }
  if (statusString === 'ACKNOWLEDGEMENT' || statusString === 'ACKED') {
    return EnumIncidentStatuses.ACKNOWLEDGED
  }
  if (statusString === 'SNOOZED') {
    return EnumIncidentStatuses.SNOOZED
  }

  return EnumIncidentStatuses.TRIGGERED
}
