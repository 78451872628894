import styled from 'styled-components'
import PropTypes from 'prop-types'

import { PaletteStatusKeys } from '@victorops/victoria'

export interface ContainerProps {
  isMuted?: boolean
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ isMuted, theme }) =>
    isMuted ? theme.palette.grays[5] : theme.palette.white};
  border: ${({ theme }) => `thin solid ${theme.palette.grays[3]}`};
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.grays[0]};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.s};
  position: relative;
  width: 100%;

  h2 {
    /* to match up with the rows for expanded card-alert */
    padding-left: ${({ theme }) => theme.functions.getSpace(2, -2)};
  }
`

Container.defaultProps = {
  isMuted: false,
}

Container.propTypes = {
  isMuted: PropTypes.bool,
}

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.functions.getSpace()} ${theme.functions.getSpace(2)}
     ${theme.functions.getSpace()} ${theme.functions.getSpace(2, -4)}`};
  width: 100%;
`

export const Date = styled.span`
  color: ${({ theme }) => theme.palette.grays[2]};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  white-space: nowrap;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const Message = styled.span`
  padding: ${({ theme }) =>
    `${theme.functions.getSpace()} ${theme.functions.getSpace()}
     ${theme.functions.getSpace(2, -4)} 0`};
`

export interface TitleProps {
  color?: PaletteStatusKeys
}

export const Title = styled.span<TitleProps>`
  color: ${({ color = 'neutral', theme }) => theme.paletteStatus[color]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

Title.defaultProps = {
  color: 'neutral',
}

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;

  > :not(:first-child) {
    padding-left: ${({ theme }) => theme.functions.getSpace()};
  }
`
