import React, { memo, FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { integrationMap } from 'integration-icons/integration-map'
import { normalizeMonitorTool } from 'utils'
import { State } from 'app/reducer'
import { CardIncident } from '../../cards'

export interface RowIncidentProps {
  dataSetID: string
  id: number
}

export const RowIncident: FunctionComponent<RowIncidentProps> = memo(
  ({ dataSetID, id }) => {
    const acknowledgedBy = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].acknowledgedByDispNames
    )
    const acknowledgementsExpected = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].acknowledgementsExpected
    )
    const acknowledgementsReceived = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].acknowledgementsReceived
    )
    const date = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].date
    )
    const incidentName = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].incidentName
    )
    const isMultiResponder = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].isMultiResponder
    )
    const isMuted = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].isMuted
    )
    const manualFrom = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].manualFromDispName
    )
    const message = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].message
    )
    const monitorTool = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].monitorTool
    )
    const peoplePaged = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].peoplePagedDispNames
    )
    const policiesPaged = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].policiesPaged
    )
    const resolvedBy = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].resolvedByDispName
    )
    const snoozedBy = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].snoozedByDispName
    )
    const snoozedUntil = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].snoozedUntil
    )
    const status = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].status
    )
    const statusText = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].statusText
    )

    const monitorToolLower = monitorTool?.toLowerCase()
    const normalizedMonitorTool = normalizeMonitorTool(monitorToolLower)
    const monitorToolDisplayName =
      integrationMap[normalizedMonitorTool]?.displayName
    const iconPath = integrationMap[normalizedMonitorTool]?.icon

    return (
      <CardIncident
        id={id.toString()} // the "id" prop ends up on html elements as an attribute, which is why we turn it into a string
        {...{
          acknowledgedBy,
          acknowledgementsExpected,
          acknowledgementsReceived,
          date,
          iconPath,
          incidentName,
          isMultiResponder,
          isMuted,
          manualFrom,
          message,
          monitorTool: monitorToolDisplayName,
          peoplePaged,
          policiesPaged,
          resolvedBy,
          snoozedBy,
          snoozedUntil,
          status,
          statusText,
        }}
      />
    )
  }
)

RowIncident.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
