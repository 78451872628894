import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { transformConferenceBridgeUpdateMessage } from 'utils/websocket/websocket-transforms'

interface Integration {
  integrationType: string
  link: string
  name: string
}

export const transformIntegrations = (integrations: Integration[]) =>
  integrations?.map(({ integrationType, link, name }) => ({
    integrationType,
    link,
    name,
    uuid: `${integrationType}-${name}`,
  }))

interface IncidentData {
  MESSAGE: string
  // We have not shaped PAYLOAD from server yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PAYLOAD: Record<string, any>
}
export interface ConferenceBridgeDetailsDTO {
  url?: string
  phone?: string
  notes?: string
  // 'id' and 'name' exists only if it is a preset conference bridge
  id?: number
  name?: string
}
interface ConnectedIntegrations {
  integrationType?: string
  link?: string
  name?: string
  uuid?: string
}
interface IncidentDetailsIinitialState {
  [incidentNumber: string]: {
    connectedIntegrations: ConnectedIntegrations[]
    conferenceBridge: ConferenceBridgeDetailsDTO
  }
}
const initialState: IncidentDetailsIinitialState = {}

const incidentDetailsSlice = createSlice({
  name: 'incidentDetails',
  initialState,
  reducers: {
    setConnectedIntegrations: (
      state,
      {
        payload: { incidentNumber, integrations },
      }: PayloadAction<{ incidentNumber: string; integrations: Integration[] }>
    ) => ({
      ...state,
      [incidentNumber]: {
        ...state[incidentNumber],
        connectedIntegrations: transformIntegrations(integrations),
      },
    }),
    setConferenceBridge: (
      state,
      {
        payload: { conferenceBridge, incidentNumber },
      }: PayloadAction<{
        conferenceBridge: ConferenceBridgeDetailsDTO
        incidentNumber: string
      }>
    ) => ({
      ...state,
      [incidentNumber]: {
        ...state[incidentNumber],
        conferenceBridge,
      },
    }),
    deleteConferenceBridge: (
      state,
      { payload: { incidentNumber } }: PayloadAction<{ incidentNumber: string }>
    ) => ({
      ...state,
      [incidentNumber]: {
        ...state[incidentNumber],
        conferenceBridge: {},
      },
    }),
    receivedConferenceBridgeUpdateMessage: (
      state,
      { payload }: PayloadAction<IncidentData>
    ) => ({
      ...state,
      [payload.PAYLOAD.INCIDENT_ID]: {
        ...state[payload.PAYLOAD.INCIDENT_ID],
        conferenceBridge: transformConferenceBridgeUpdateMessage(payload),
      },
    }),
  },
})

export const {
  receivedConferenceBridgeUpdateMessage,
  setConnectedIntegrations,
  setConferenceBridge,
  deleteConferenceBridge,
} = incidentDetailsSlice.actions
export const { reducer: incidentDetails } = incidentDetailsSlice
