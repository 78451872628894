import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'
import { PaletteStatusKeys, paletteStatusPropType } from '@victorops/victoria'

import { Title, TitleContainer } from '../styles'
import { Icons } from './icons'

export interface TitleWithIconProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  iconPath?: string
  monitorTool?: string
  snoozedUntil?: string
  status?: PaletteStatusKeys
  titleText: string
  detailLink?: string
}

export const TitleWithIcon: FunctionComponent<TitleWithIconProps> = memo(
  ({
    iconPath,
    monitorTool,
    snoozedUntil,
    status,
    titleText,
    detailLink,
    ...rest
  }) => (
    <TitleContainer {...rest}>
      {iconPath && monitorTool && (
        <Icons
          iconPath={iconPath}
          monitorTool={monitorTool}
          snoozedUntil={snoozedUntil}
          status={status}
        />
      )}
      {detailLink && (
        <a href={detailLink} rel='noopener noreferrer' target='_blank'>
          <Title color={status}>{titleText}</Title>
        </a>
      )}
      {!detailLink && <Title color={status}>{titleText}</Title>}
    </TitleContainer>
  )
)

TitleWithIcon.defaultProps = {
  iconPath: undefined,
  monitorTool: undefined,
  snoozedUntil: undefined,
  detailLink: undefined,
  status: 'neutral',
}

TitleWithIcon.propTypes = {
  iconPath: PropTypes.string,
  detailLink: PropTypes.string,
  monitorTool: PropTypes.string,
  snoozedUntil: PropTypes.string,
  status: paletteStatusPropType,
  titleText: PropTypes.string.isRequired,
}
