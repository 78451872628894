import { createSlice } from '@reduxjs/toolkit'
import { isStagingLocation } from 'utils/window'
import { OrgMetaDTO } from 'utils'

export enum EnumFeatureFlags {
  CODE_RED = 'feature:codered', // add an enire team of individual responders to an incident/
  CORRELATED_INCIDENTS = 'feature:correlatedincidents', // enables correlated incidents as a feature. Cannot be removed due to mobile's usage.
  DISABLE_SUPPORT_CHAT = 'feature:disablesupportchat', // disables SFDC support chat widget
  ENTERPRISE_SIMILAR_INCIDENTS = 'feature:similarincidents', // enables similar incidents as a feature. Will be part of the enterprise package.
  ENTERPRISE_CONFERENCE_BRIDGES = 'feature:conferencebridges', // enables conferences bridges as a feature. Will be part of the enterprise package.
  ENTERPRISE_FEATURE_STAKEHOLDERS = 'feature:stakeholders', // enables stakeholders as a feature. Will be part of the enterprise package.
  FEATURE_INCIDENT_NOTES = 'feature:customincidentmetadata', // enables incident notes as a feature.
  NOISY_INCIDENTS = 'feature:noisyincidents', // enables noisy incidents as a feature.
  PEOPLE_PANE = 'feature:peoplepane', // experimental - remove after people pane released
  STAKEHOLDER_ROLE = 'feature:stakeholderrole', // experimental - remove after stakeholder role released
  STAKEHOLDER_TEMPLATES = 'feature:stakeholdertemplates', // enables templates for stakeholder messaging
}

interface InitialState {
  featureFlags: Record<EnumFeatureFlags, boolean>
}
export const orgMetaInitialState: InitialState = {
  // if a featureFlag we are using does not come back from server it needs to auto flip to false. This is to be in parity with mobile who needs continued support around legacy installations.
  featureFlags: {
    [EnumFeatureFlags.CODE_RED]: false,
    [EnumFeatureFlags.CORRELATED_INCIDENTS]: false,
    [EnumFeatureFlags.DISABLE_SUPPORT_CHAT]: false,
    [EnumFeatureFlags.ENTERPRISE_SIMILAR_INCIDENTS]: false,
    [EnumFeatureFlags.ENTERPRISE_CONFERENCE_BRIDGES]: false,
    [EnumFeatureFlags.ENTERPRISE_FEATURE_STAKEHOLDERS]: false,
    [EnumFeatureFlags.FEATURE_INCIDENT_NOTES]: false,
    [EnumFeatureFlags.NOISY_INCIDENTS]: false,
    [EnumFeatureFlags.PEOPLE_PANE]: false, // remove after people pane released
    [EnumFeatureFlags.STAKEHOLDER_ROLE]: false, // remove after stakeholder role released
    [EnumFeatureFlags.STAKEHOLDER_TEMPLATES]: false,
  },
}

/* eslint-disable dot-notation */
export const transformOrgMeta = (data: OrgMetaDTO) => {
  // this has an accompanying bookmarklet to allow us to flip flags through a nice UI. See the web resources repo
  if (isStagingLocation()) {
    Object.keys(data['_priv']).forEach((flag) => {
      if (sessionStorage.getItem(flag)) {
        // eslint-disable-next-line no-console
        console.info(
          '%c feature flags are being overridden by session storage...',
          'color: #21bdad'
        )

        if (sessionStorage.getItem(flag) === 'true') {
          data['_priv'][flag] = true // eslint-disable-line no-param-reassign
        }

        if (sessionStorage.getItem(flag) === 'false') {
          data['_priv'][flag] = false // eslint-disable-line no-param-reassign
        }
      }
    })
    window.VO_CONFIG = {
      featureFlags: data['_priv'],
    }
  }

  return {
    featureFlags: data['_priv'],
  }
}
/* eslint-enable dot-notation */
const orgMetaSlice = createSlice({
  name: 'orgMeta',
  initialState: orgMetaInitialState,
  reducers: {
    setOrgMeta(state, { payload }) {
      return {
        ...state,
        ...transformOrgMeta(payload),
      }
    },
  },
})

export const { setOrgMeta } = orgMetaSlice.actions

export const { reducer: orgMeta } = orgMetaSlice
