import React from 'react'
import { useSelector } from 'react-redux'
import { State } from 'app/reducer'
import { getCurrentDate, translate } from 'entry/utils'
import { differenceInDays } from 'date-fns'
import { StyledTrialBanner } from './banners-styles'
import { CountdownTimer } from './components/countdown-timer'

export const TrialBanner = () => {
  const initiateExtensionLimit = 2
  const orgSlug = useSelector((state: State) => state.currentOrg.slug)

  const isTrialAdmin = useSelector(
    (state: State) =>
      state.currentOrg.billingState === 'trial' &&
      state.user.roles.isGlobalAdmin
  )

  const isTrialNonAdmin = useSelector(
    (state: State) =>
      state.currentOrg.billingState === 'trial' &&
      !state.user.roles.isGlobalAdmin
  )
  const isTrialUser = isTrialAdmin || isTrialNonAdmin

  const trialEnd = useSelector((state: State) => state.currentOrg.trialEnd)

  const determineDaysLeft = (value: number) => {
    const daysLeft = differenceInDays(value, getCurrentDate())

    const daysLeftText =
      daysLeft === 1
        ? translate('VO.TrialBanner.ExpiresInOneDayHeader')
        : translate('VO.TrialBanner.ExpiresIn#Days', daysLeft)

    return [daysLeftText, daysLeft]
  }
  const [daysLeftText, daysLeft] = determineDaysLeft(trialEnd)
  const zeroDaysLeft = daysLeft === 0

  return (
    isTrialUser && (
      <StyledTrialBanner.Container data-ext='vo.oi.trial-banner'>
        {isTrialAdmin ? (
          <span>
            Your trial
            {zeroDaysLeft ? <CountdownTimer /> : daysLeftText}{' '}
            <StyledTrialBanner.Link
              href={`/dash/${orgSlug}#/buy-now`}
              target='_blank'
            >
              Buy Now
            </StyledTrialBanner.Link>
            {daysLeft > initiateExtensionLimit ? (
              <span>
                <span> or </span>
                <StyledTrialBanner.Link
                  href='https://victorops.com/request-demo/'
                  target='_blank'
                >
                  Get a Demo
                </StyledTrialBanner.Link>
              </span>
            ) : (
              <span>
                <span> or </span>
                <StyledTrialBanner.Link href='https://help.victorops.com/knowledge-base/how-to-contact-splunk-on-call-support/'>
                  Contact support
                </StyledTrialBanner.Link>{' '}
                to extend my trial
              </span>
            )}
          </span>
        ) : (
          <span>
            Your trial
            {zeroDaysLeft ? ' ends today. ' : daysLeftText}{' '}
            {daysLeft > initiateExtensionLimit ? (
              <span>
                <StyledTrialBanner.Link
                  href='https://victorops.com/request-demo/'
                  target='_blank'
                >
                  Get a Demo
                </StyledTrialBanner.Link>
              </span>
            ) : (
              <span>
                <StyledTrialBanner.Link href='https://help.victorops.com/knowledge-base/how-to-contact-splunk-on-call-support/'>
                  Contact support
                </StyledTrialBanner.Link>{' '}
                to extend my trial
              </span>
            )}
          </span>
        )}
      </StyledTrialBanner.Container>
    )
  )
}
