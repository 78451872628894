import React, { memo, FunctionComponent, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getTimeDifferenceAbbr } from 'utils'
import { State } from 'app/reducer'
import { DateContext } from '../../../utils'
import { StyledRow } from './row-styles'
import { Icon } from './components'

export interface RowIncidentProps {
  dataSetID: string
  id: number
}

export const RowIncident: FunctionComponent<RowIncidentProps> = memo(
  ({ dataSetID, id }) => {
    const acknowledgmentsRecieved = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id]?.acknowledgementsReceived ||
        state.websocketChat[dataSetID].newMessages[id]?.acknowledgementsReceived
    )
    const date = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id]?.date ||
        state.websocketChat[dataSetID].newMessages[id]?.date
    )
    const phase = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id]?.phase ||
        state.websocketChat[dataSetID].newMessages[id]?.phase
    )
    const statusText = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id]?.statusText ||
        state.websocketChat[dataSetID].newMessages[id]?.statusText
    )

    const timeStamp = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id]?.timeStamp ||
        state.websocketChat[dataSetID].newMessages[id]?.timeStamp
    )

    const baseDate = useContext(DateContext)
    const timeDifference = useMemo(
      () =>
        // Format date with abbvs
        getTimeDifferenceAbbr(timeStamp, baseDate),
      [timeStamp, baseDate]
    )

    const isSingleAcknowledgment =
      phase === 'UNACKED' && acknowledgmentsRecieved > 0

    return !isSingleAcknowledgment ? (
      <StyledRow.Container aria-labelledby={`message-heading-${id}`}>
        <StyledRow.Side>
          <StyledRow.VerticalTop />
          <Icon {...{ phase }} />
          <StyledRow.Time>{timeDifference}</StyledRow.Time>
          <StyledRow.VerticalBottom />
        </StyledRow.Side>

        <StyledRow.Message>
          <StyledRow.Title id={`message-heading-${id}`} role='heading'>
            {phase === 'ACKED'
              ? 'All parties present'
              : `Incident ${statusText}`}
          </StyledRow.Title>
          <StyledRow.Body>{date}</StyledRow.Body>
        </StyledRow.Message>
      </StyledRow.Container>
    ) : null
  }
)

RowIncident.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
