import React, { FunctionComponent, memo } from 'react'
import PropTypes from 'prop-types'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle, faUser } from '@fortawesome/pro-solid-svg-icons'
import { faCircle, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themes } from '@victorops/victoria'

import { StyledRow } from '../row-styles'

const TriggeredIcon = () => (
  <FontAwesomeIcon
    color={themes.voLight.paletteStatus.triggeredAlt}
    icon={faExclamationTriangle}
  />
)
const ResolvedIcon = () => (
  <FontAwesomeIcon
    color={themes.voLight.paletteStatus.resolvedAlt}
    icon={faCheck}
  />
)
const AllPresentIcon = () => (
  <FontAwesomeIcon color={themes.voLight.palette.gray1} icon={faUser} />
)
const MessageIcon = () => (
  <FontAwesomeIcon color={themes.voLight.palette.gray1} icon={faPaperPlane} />
)

const getIcon = (type: string) => {
  if (type === 'UNACKED') return <TriggeredIcon />
  if (type === 'RESOLVED') return <ResolvedIcon />
  if (type === 'ACKED') return <AllPresentIcon />
  return <MessageIcon />
}

const getColor = (type: string) => {
  if (type === 'UNACKED') return themes.voLight.paletteStatus.triggeredAlt
  if (type === 'RESOLVED') return themes.voLight.paletteStatus.resolvedAlt
  if (type === 'ACKED') return themes.voLight.paletteStatus.warningAlt
  return themes.voLight.paletteStatus.warningAlt
}

export interface IconProps {
  phase?: 'ACKED' | 'RESOLVED' | 'UNACKED'
}

export const Icon: FunctionComponent<IconProps> = memo(({ phase: type }) => (
  <StyledRow.Icon className='fa-layers fa-fw'>
    <FontAwesomeIcon
      color={getColor(type)}
      icon={faCircle}
      transform='grow-16'
    />
    {getIcon(type)}
  </StyledRow.Icon>
))

Icon.defaultProps = { phase: undefined }

Icon.propTypes = {
  phase: PropTypes.oneOf(['ACKED', 'RESOLVED', 'UNACKED']),
}
