import styled, { css } from 'styled-components'
import { ButtonAction as VictoriaButtonAction } from '@victorops/victoria'

const buttonBase = css`
  height: ${({ theme }) => theme.functions.getSpace(4)};
  padding-left: ${({ theme }) => theme.functions.getSpace(2)};
  padding-right: ${({ theme }) => theme.functions.getSpace(2)};
  min-width: ${({ theme }) => theme.functions.getSpace(10)};
`

export const ActionButton = styled(VictoriaButtonAction)`
  ${buttonBase}
`
// PrimaryButtons can be used as ActionButtons or as Submit buttons
export const PrimaryButton = styled(ActionButton)`
  border: 1px solid ${({ theme }) => theme.palette.navActiveBorder};
  border-radius: 24px;
  background: ${({ theme }) => theme.palette.navActiveBorder};

  &:hover:enabled {
    background: ${({ theme }) => theme.palette.primaryHover};
  }
`

// SecondaryButton can be used for cancel button.
export const SecondaryButton = styled(ActionButton)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.secondaryButtonBorder};
  border-radius: 24px;
  color: ${({ theme }) => theme.palette.activeText};
  &:hover:enabled {
    border-color: ${({ theme }) => theme.palette.disabledButtonBackground};
    background: ${({ theme }) => theme.palette.secondaryHoverBackground};
    color: ${({ theme }) => theme.palette.activeText};
  }
`

export const TertiaryButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.palette.splunkTeal};
  &:hover,
  &:focus {
    background-color: #568f85 !important; /* To override CSS specificity of victoria button, need to update this color in Victoria */
  }
`

export const LinkButton = styled(VictoriaButtonAction)``

PrimaryButton.defaultProps = {
  colorPrimary: 'white',
  hasBorder: true,
}

SecondaryButton.defaultProps = {
  colorPrimary: 'secondaryButtonBorder',
  colorSecondary: 'secondaryHoverBackground',
  hasBorder: true,
}

TertiaryButton.defaultProps = {
  colorPrimary: 'white',
  hasBorder: true,
}

export const StyledButton = {
  ActionButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
}
