import { fetchAPI } from './common'

export interface IntegrationDTO {
  integration: string
  key: string
}

export const fetchConnectedIntegrations = (
  orgSlug: string,
  incidentNumber: string
) =>
  fetchAPI(
    `v1/org/${orgSlug}/incidents/${incidentNumber}/connected-integrations`
  ).then((response) => response.json())

export const fetchIntegrations = (orgSlug: string): Promise<IntegrationDTO[]> =>
  fetchAPI(`v1/org/${orgSlug}/integrations`).then((response) => response.json())
