/**
 * Considerations:
 * - because this will be a big file, try to keep it in alphabetical order.
 * - globals are at the top
 * - namespacing these to victorOps because translators may desire to know what application the string is for.
 * - at the same time, they may want the ability to tie VO strings to Splunk wide translations.
 * - a string is global if it's referenced in more then one feature
 * - params will be replaced in the order they were received. {0} refers to the first param, {1} the second.
 */

const englishStrings = {
  /**
   * Globals
   */
  'VO.Global.Acknowledge': 'Acknowledge',
  'VO.Global.AddConferenceBridge': 'Add Conference Bridge',
  'VO.Global.AddResponders': 'Add Responders',
  'VO.Global.AddUsers': 'Add Users',
  'VO.Global.AlertFields': 'Alert Fields',
  'VO.Global.Annotations': 'Annotations',
  'VO.Global.Cancel': 'Cancel',
  'VO.Global.Close': 'Close',
  'VO.Global.Confirm': 'Confirm',
  'VO.Global.Continue': 'Continue',
  'VO.Global.Create': 'Create',
  'VO.Global.Loading': 'Loading',
  'VO.Global.MaintenanceMode': 'Maintenance Mode',
  'VO.Global.MaintenanceModeStart': 'Start Maintenance Mode',
  'VO.Global.Manage': 'Manage',
  'VO.Global.Message': 'Message',
  'VO.Global.Muted': 'Muted',
  'VO.Global.Name': 'Name',
  'VO.Global.RerouteIncident': 'Reroute Incident',
  'VO.Global.Resolve': 'Resolve',
  'VO.Global.Save': 'Save',
  'VO.Global.Status.Acknowledged.Plural': 'Acknowledged Incidents',
  'VO.Global.Status.Acknowledged': 'Acknowledged',
  'VO.Global.Status.New.Plural': 'New Incidents',
  'VO.Global.Status.NewIncident': 'Load {0} new incident',
  'VO.Global.Status.NewIncidents': 'Load {0} new incidents',
  'VO.Global.Status.NewTitle': 'New Incidents',
  'VO.Global.Status.NoNewIncidents': 'All incidents up to date',
  'VO.Global.Status.Resolved.Plural': 'Resolved Incidents',
  'VO.Global.Status.Resolved': 'Resolved',
  'VO.Global.Status.Snoozed.Plural': 'Snoozed Incidents',
  'VO.Global.Status.Snoozed': 'Snoozed',
  'VO.Global.Status.Triggered.Plural': 'Triggered Incidents',
  'VO.Global.Status.Triggered': 'Triggered',
  'VO.Global.Status': 'Status',
  'VO.Global.Submit': 'Submit',
  'VO.Global.Template': 'Template',
  'VO.Global.Undo': 'Undo',
  'VO.Global.Update': 'Update',
  'VO.Global.VictorOpsFields': 'Splunk On-Call Fields',

  /**
   * Global Errors
   */
  'VO.Global.ErrorAcknowledging':
    'There was an error acknowledging incident.  Please try again.',
  'VO.Global.ErrorResolving':
    'There was an error resolving incident.  Please try again.',

  /**
   * Alert details
   */
  'VO.AlertDetails.AlertData': 'Alert Data',
  'VO.AlertDetails.IncidentNotes': 'Incident Notes',
  'VO.AlertDetails.PayloadIsEmpty': 'Payload is empty',
  'VO.AlertDetails.WeWereUnableToRetrieveIncidentNotesForThisIncident':
    'We were unable to retrieve incident notes for this incident',

  /**
   * Annotations
   */
  'VO.Annotations.headerForEmptyState': 'No Annotations Yet',
  'VO.Annotations.subheaderForEmptyState':
    'Annotations let you attach information like runbooks and graphs directly to your incidents.',

  /**
   * Card Incident
   */

  'VO.CardIncident.Alerts': 'Alerts',
  'VO.CardIncident.IncidentDetails': 'Incident Details',
  'VO.CardIncident.SnoozedUntil': 'Snoozed until',
  'VO.CardIncident.From': 'from',
  'VO.CardIncident.MultiAckMessage':
    'Incident will stay triggered until all parties have acknowledged.',
  'VO.CardIncident.PagingMutedMessage': 'Paging muted for this incident',

  /**
   * Chat
   */
  'VO.Chat.MessageTheTeam': 'Message the team...',
  'VO.Chat.MentionsNotFound': 'No Results',

  /**
   *  Organizational Intelligence
   */
  'VO.OI.OnlyMyIncidents': 'Only My Incidents',
  'VO.OI.SelectTeam': 'Select Team',
  'VO.OI.Title': 'Splunk On-Call - Incidents',

  /**
   * MIR - Header
   */
  'VO.MIRHeader.Incidents': 'Incidents',
  'VO.MIRHeader.IncidentBreadcrumbDivider': ' > ',
  'VO.MIRHeader.Incident#': 'Incident #{0}', // {0} is the incidentID

  /**
   * Modals
   */

  // Snooze
  'VO.Modal.Snooze.Hours': 'Hours',
  'VO.Modal.Snooze.Minutes': 'Minutes',
  'VO.Modal.Snooze.Snooze': 'Snooze',
  'VO.Modal.Snooze.SnoozeFor': 'Snooze for',
  'VO.Modal.Snooze.SnoozeIncident': 'Snooze Incident #{0}',
  'VO.Modal.Snooze.StopAllPagingForThisIncident...':
    'Stop all paging for this incident for up to 24 hours, after which paging will resume again.',

  // Take Oncall
  'VO.Modal.Take-Oncall.BodyText.TakeBack':
    'You are taking back on-call responsibilities from {0} in ',
  'VO.Modal.Take-Oncall.BodyText.ClearTake':
    'This will return on-call responsibilities to {0} in ',
  'VO.Modal.Take-Oncall.BodyText.TakeShift':
    'You are about to go on-call for {0} in ',
  'VO.Modal.Take-Oncall.FailureText.TakeBack':
    'Failed to take back on-call responsibilities from {0}',
  'VO.Modal.Take-Oncall.FailureText.ClearTake':
    'Failed to return on-call responsibilities to {0}',
  'VO.Modal.Take-Oncall.FailureText.TakeShift': 'Failed to go on-call for {0}',
  'VO.Modal.Take-Oncall.SuccessText.TakeBack':
    'Successfully took back on-call responsibilities from {0}',
  'VO.Modal.Take-Oncall.SuccessText.ClearTake':
    'Successfully returned on-call responsibilities to {0}',
  'VO.Modal.Take-Oncall.SuccessText.TakeShift':
    'Successfully went on-call for {0}',

  // Delete
  'VO.ModalDelete.Delete': 'Delete',
  'VO.ModalDelete.DeleteBridge': 'Delete Bridge',
  'VO.ModalDelete.Header': 'Delete Conference Bridge from incident #{0}',
  'VO.ModalDelete.ConfirmationMessageForDeleteCB...':
    'Are you sure you want to remove the conference bridge from this incident?',
  'VO.ModalDelete.ErrorMessageForDeleteCB...':
    'The conference bridge could not be deleted, please refresh and try again.',

  // Conference Bridge Details
  'VO.Modal.ConferenceBridge.Details.Header': 'Conference Bridge Details',
  'VO.Modal.ConferenceBridge.Details.URL': 'URL',
  'VO.Modal.ConferenceBridge.Details.Phone': 'Phone Number',
  'VO.Modal.ConferenceBridge.Details.Notes': 'Details',
  'VO.Modal.ConferenceBridge.ViewDetails': 'View Details',
  'VO.Modal.ConferenceBridge.NoConferenceBridge': 'No Conference Bridge',
  'VO.Modal.ConferenceBridge.EnterYourOwnConferenceBridgeInformation':
    'Enter your own conference information',

  // Reroute
  'VO.ModalReroute.Reroute': 'Reroute',
  'VO.ModalReroute.Instead': ' instead.',
  'VO.ModalReroute.ReroutingWillRemoveYou...':
    'Rerouting will remove you from the incident. You may wish to ',
  'VO.ModalReroute.Warning': 'Warning!',

  // Edit
  'VO.ModalEdit.ErrorMessageForEditCB...':
    'The conference bridge could not be edited, please refresh and try again.',
  'VO.Modal.ConferenceBridge.Edit.Header': 'Edit Conference Bridge',
  'VO.Modal.ConferenceBridge.EditBridge': 'Edit Bridge',

  // Add Responders
  'VO.ModalAddResponders.Add': 'Add',
  'VO.ModalAddResponders.AdditionalRespondersWillBePagedUntil...':
    'Additional responders will be paged until they acknowledge or the incident is resolved.',
  'VO.ModalAddResponders.ConferenceBridge': 'Conference Bridge',
  'VO.ModalAddResponders.AddRespondersErrorMessage':
    'The information could not be added, please refresh and try again.',
  'VO.ModalAddResponders.AddRespondersFormErrorMessage':
    'Nothing to add. Please fill out any one of the fields above.',
  'VO.ModalAddResponders.WarningForSingleUserDynamic':
    'Warning: {0} is not on-call right now.',
  'VO.ModalAddResponders.WarningForMultipleUsersDynamic':
    'Warning: The following users are not on-call: {0}',
  'VO.ModalAddResponders.PresetConferenceBridgeInUseLabelDynamic':
    '(Currently in use for {0})',

  // Maintenance Mode
  'VO.MaintenanceModeModal.SubHeading':
    'Paging will be muted for any new triggered incidents for the Routing Key(s) you select. Paging will continue for incidents that are already in progress.',
  'VO.MaintenanceModeModal.Purpose.Placeholder':
    'Enter the purpose for muting routing keys',
  'VO.MaintenanceModeModal.MutePagingForAll':
    'Mute paging for ALL Routing Keys',
  'VO.MaintenanceModeModal.MutePagingForSelect':
    'Mute paging for select Routing Key(s)',
  'VO.MaintenanceModeModal.SelectRoutingKeys': 'Select routing key(s)',
  'VO.MaintenanceModeModal.ErrorStartingMaintenanceMode':
    'There was an error trying to activate maintenance mode.  Please try again.',
  'VO.MaintenanceModeModal.StartMaintenanceMode': 'Start Maintenance Mode',
  'VO.MaintenanceModeModal.ActivatedMessage':
    'Maintenance mode activated for {0} keys.',

  // Maintenance Mode Messaging
  'VO.MaintenanceModeMessage.Global':
    'All incidents are currently muted. Creating this incident will exclude it from Maintenance Mode and start paging the selected escalation polices and/or users.',
  'VO.MaintenanceModeMessage':
    'Maintenance Mode is active for some Routing Keys. Creating this incident will exclude it from Maintenance Mode and start paging the selected escalation policies and/or users.',

  //  Manual Incident
  'VO.ModalManualIncident.Header': 'Create New Incident',
  'VO.ModalManualIncident.MonitorType': 'Monitor Type: ',
  'VO.ModalManualIncident.MonitorTypeManual': 'Manual',
  'VO.ModalManualIncident.Incident.CreatedBy': 'Created By:',
  'VO.ModalManualIncident.Incident.SendTo': 'Send To',
  'VO.ModalManualIncident.Incident.AcknowledgeBehavior': 'Acknowledge Behavior',
  'VO.ModalManualIncident.Incident.MultiResponder':
    'Continue paging until each Escalation Policy or User above has Acknowledged',
  'VO.ModalManualIncident.Incident.SingleResponder':
    'Stop paging after a single Acknowledge from any Escalation Policy or User',
  'VO.ModalManualIncident.Incident.Description': 'Incident Description',
  'VO.ModalManualIncident.Incident.RequiredError': 'This field is required',
  'VO.ModalManualIncident.Incident.PolicyError':
    'Please select an Escalation Policy or User to page',
  'VO.ModalManualIncident.Incident.Body': 'Incident Body',
  'VO.ModalManualIncident.Submit': 'Create Incident',
  'VO.ModalManualIncident.Submit.Error':
    'There was an error creating incident, please try again.',
  'VO.ModalManualIncident.Submit.Success':
    'Incident #{0} created successfully.',

  /**
   * User Select
   */
  'VO.UserSelect.SearchByTypingAbove': 'Search by typing above...',
  'VO.UserSelect.NoUsersFound': 'No Users Found...',
  'VO.UserSelect.Responders': 'Responders',
  'VO.UserSelect.SelectUsers': 'Select users...',
  'VO.UserSelect.SuggestedResponders': 'Suggested Responders',
  'VO.UserSelect.UserAlreadyInvolved': ' (User already involved)',
  'VO.UserSelect.Users': 'Users',

  /**
   * Stakeholder Users and Teams Select
   */
  'VO.UsersAndTeamsSelect.SelectUsersAndTeams':
    'Start typing to select users and teams...',
  'VO.UsersAndTeamsSelect.Users': 'USERS',
  'VO.UsersAndTeamsSelect.Teams': 'TEAMS',
  'VO.UsersAndTeamsSelect.LoadingMessage': 'Loading users and teams...',
  'VO.UsersAndTeamsSelect.NoOptionsMessage': 'No matching users or teams',

  /**
   * Escalation Policy Select
   */

  'VO.PolicySelect.PolicyAlreadyInvolved': '{0} (Policy already involved)', // {0} is the name of the policy
  'VO.PolicySelect.TeamsPolicies': 'Teams / Policies',
  'VO.PolicySelect.SelectAnEscalationPolicy': 'Select an escalation policy...',

  /**
   * Sidebar
   */
  'VO.Sidebar.Acknowledged': 'Acknowledged',
  'VO.Sidebar.AcknowledgedWithRatio': 'Acknowledged ({0}/{1})',
  'VO.Sidebar.AddResponders': 'Add Responders',
  'VO.Sidebar.Day': 'day',
  'VO.Sidebar.Days': 'days',
  'VO.Sidebar.Hr': 'hr',
  'VO.Sidebar.Hrs': 'hrs',
  'VO.Sidebar.Min': 'min',
  'VO.Sidebar.Mins': 'mins',
  'VO.Sidebar.Integrations': 'Integrations',
  'VO.Sidebar.IncidentWillStayTriggered...':
    'Incident will stay triggered until all parties have acknowledged',
  'VO.Sidebar.Paging': 'Paging',
  'VO.Sidebar.Responders': 'Responders',
  'VO.Sidebar.SnoozeIncident': 'Snooze Incident',
  'VO.Sidebar.StatusUntilDateDynamic': '{0} until {1}',
  'VO.Sidebar.StatusOnDateDynamic': '{0} on {1}',
  'VO.Sidebar.TeamsAndEscalationPolicies': 'Teams & Escalation Policies',
  'VO.Sidebar.TimeSinceDetection': 'Time since detection',
  'VO.Sidebar.TimeToResolve': 'Time to resolve',
  'VO.Sidebar.YouAreBeingPaged...': 'You are being paged for this incident.',

  /**
   * Tabs
   */
  'VO.Tabs.AlertDetails': 'Alert Details',
  'VO.Tabs.Annotations': 'Annotations ({0})', // {0} is the number of annotations
  'VO.Tabs.Timeline': 'Timeline',
  'VO.Tabs.SimilarIncidents': 'Similar Incidents',
  'VO.Tabs.Stakeholders': 'Stakeholders',

  /**
   * Teams
   */
  'VO.TeamSelect.AddEntireTeam': 'Add Entire Team',
  'VO.TeamSelect.Teams': 'Teams',
  'VO.TeamSelect.NoTeamsFound': 'No Teams Found',

  /**
   * Similar Incidents
   */
  'VO.SimilarIncidents.headerForEmptyState': 'No Similar Incidents',
  'VO.SimilarIncidents.subheaderForEmptyState':
    'If incidents have similar alerts, titles, and other data to this incident, they would show up here. At this time, none are similar enough.',
  'VO.SimilarIncidents.PastIncidents': 'Past Incidents',
  'VO.SimilarIncidents.PastIncidentsDescription':
    'These incidents happened in the past. They might provide clues around how you can solve this current incident.',
  'VO.SimilarIncidents.PastIncidentsEmptyState': 'No Past incidents',

  'VO.SimilarIncidents.UpdatedMomentAgo': 'Updated a moment ago',
  'VO.SimilarIncidents.UpdatedMoreThanADayAgo': 'Updated more than a day ago',
  'VO.SimilarIncidents.UpdatedMinsAgo': 'Updated {0} minutes ago',
  'VO.SimilarIncidents.UpdatedHoursAgo': 'Updated {0} hours ago',
  'VO.SimilarIncidents.UpdatedHourAgo': 'Updated 1 hour ago',

  'VO.SimilarIncidents.CurrentIncidents': 'Current Incidents',
  'VO.SimilarIncidents.CurrentIncidentsDescription':
    'These related incidents are happening right now. You may want to approach resolving these as a single event versus individual incidents.',
  'VO.SimilarIncidents.CurrentIncidentsEmptyState': 'No Current Incidents',
  'VO.SimilarIncidents.CurrentIncidentsShowMore': 'Show more',
  'VO.SimilarIncidents.CurrentIncidentsShowLess': 'Show less',

  /**
   * Noisy Incidents
   */
  'VO.NoisyIncidents.Title': 'Noisy Incidents',
  'VO.NoisyIncidents.NegativeTitle': 'No Noisy Incidents',
  'VO.NoisyIncidents.ErrorTitle': 'Error fetching Noisy Incidents',
  'VO.NoisyIncidents.DropDown.Description.Part.A':
    'We have detected the following “noisy” incidents, meaning they may be alerting people unnecessarily. We recommend you',
  'VO.NoisyIncidents.DropDown.Description.Link':
    'tune your monitoring tools or adjust your Splunk On-Call configuation',
  'VO.NoisyIncidents.DropDown.Description.Part.B': 'to reduce noise.',
  'VO.NoisyIncidents.Card.Title.Link': 'View most recent instance',
  'VO.NoisyIncidents.Card.List.Head': 'Other Examples',
  'VO.NoisyIncidents.Incident': 'Incident',
  'VO.NoisyIncidents.Feedback.Question': 'Was this helpful?',
  'VO.NoisyIncidents.Feedback.Affirmative': 'Yes',
  'VO.NoisyIncidents.Feedback.Negative': 'No',
  'VO.NoisyIncidents.Feedback.NegativeResponse':
    "Thanks for the feedback, we won't show this again.",
  'VO.NoisyIncidents.Feedback.NegativeUndo': 'to keep seeing this',
  'VO.NoisyIncidents.Feedback.AffirmativeResponse':
    'Thanks for the feedback! This helps us improve.',
  'VO.NoisyIncidents.Feedback.AffirmativeUndo': 'If this was an error, you can',
  'VO.NoisyIncidents.Feedback.UndoResponse':
    'The previous action has been undone.',
  'VO.NoisyIncidents.Feedback.UndoUndo': 'If this was an error, you can',
  'VO.NoisyIncidents.Feedback.ErrorHeading': 'Something went wrong.',
  'VO.NoisyIncidents.Feedback.ErrorSubheading': 'Please contact support.',

  /**
   * Stakeholders
   */
  'VO.Stakeholders.CharactersRemaining': 'Characters remaining: {0}', // {0} is the length of how many characters the user can type before they've hit the limit.
  'VO.Stakeholders.ErrorMessage':
    'An error occurred while attempting to send the message',
  'VO.Stakeholders.Message': 'Message',
  'VO.Stakeholders.NewMessage': 'New Message',
  'VO.Stakeholders.Description':
    'Stakeholder messages are used to keep non-responders on your team informed about updates to incidents.',
  'VO.Stakeholders.Send': 'Send',
  'VO.Stakeholders.Subject': 'Subject',
  'VO.Stakeholders.SuccessMessage': 'Message successfully sent',
  'VO.Stakeholders.TimelineTitle': 'Recent Messages',
  'VO.Stakeholders.ToField': 'To',
  'VO.Stakeholders.TemplateField': 'Template',
  'VO.Stakeholders.ToUser': 'To: {0}',

  /**
   * Stakeholders Template Select
   */
  'VO.TemplateSelect.ClickToSelect': 'Click to select a template...',
  'VO.TemplateSelect.LoadingMessage': 'Loading templates...',
  'VO.TemplateSelect.NoOptionsMessage': 'No templates found for this org',
  'VO.TemplateSelect.ErrorLoadingTemplates': 'Error loading template(s): {0}',
  'VO.TemplateSelect.UnknownRecipients': 'Unknown recipients: {0}',
  'VO.TemplateSelect.OverwriteConfirmModal.BodyLine1':
    'The subject, message, and recipient data will be lost as a result of',
  'VO.TemplateSelect.OverwriteConfirmModal.BodyLine2':
    'applying this template. Do you wish to proceed?',
  'VO.TemplateSelect.OverwriteConfirmModal.DontShowThisAgain':
    "Don't show this again",
  'VO.TemplateSelect.OverwriteConfirmModal.ApplyTemplate': 'Apply Template',
  'VO.TemplateSelect.OverwriteConfirmModal.Header': 'Confirm Message Change',

  /**
   * Timeline
   */
  'VO.Timeline.NewMessage': '1 new message',
  'VO.Timeline.NewMessagesDynamic': '{0} new messages', // {0} is the number of new messages
  'VO.Timeline.YouHaveReachedTheEndOfYourHistory':
    'You have reached the end of your history',

  /**
   * Card Alert
   *
   */
  'VO.CardAlert.AlertPayload': 'Alert Payload',
  'VO.CardAlert.From': 'From',

  /**
   * Incidents Table
   */
  'VO.OI.IncidentsTable.UpdatedCount.Text':
    'You have {0} new incident. Click to view.',
  'VO.OI.IncidentsTable.UpdatedCount.Title': 'View updated incident',
  'VO.OI.IncidentsTable.UpdatedCount.Text.Plural':
    'You have {0} new incidents. Click to view.',
  'VO.OI.IncidentsTable.UpdatedCount.Title.Plural': 'View updated incidents',
  'OI.IncidentsTable.NoIncidents': 'No Incidents',
  'OI.IncidentsTable.EmptyMessage':
    "Either this team doesn't have any incidents or they are all filtered out.",
  /**
   * Confirmation Modal
   */
  'VO.Confirmation.Title': 'Please Confirm Your Changes',
  'VO.Confirmation.Ok': 'OK',

  /**
   * Reroute Confirmation
   */
  'VO.RerouteConfirmation.Title': 'Reroute Muted Incident?',
  'VO.RerouteConfirmation.Message':
    'This incident is currently muted. Rerouting this incident will remove it from Maintenance Mode and start paging again once you reroute.',

  /**
   * Add Responders Confirmation
   */
  'VO.AddRespondersConfirmation.Title': 'Add Responders to Muted Incident?',
  'VO.AddRespondersConfirmation.Message':
    'This incident is currently muted. Adding responders to this incident will remove it from Maintenance Mode and start paging again.',
  /**
   * Bulk Actions
   */
  'VO.BulkActions.AcknowledgeAllSelected': 'Acknowledge All Selected',
  'VO.BulkActions.ResolveAllSelected': 'Resolve All Selected',
  'VO.BulkActions.AcknowledgeAll': 'Acknowledge All?',
  'VO.BulkActions.ResolveAll': 'Resolve All?',

  'VO.BulkActions.SubheadingDynamic': 'You are going to {0} {1} incident.',
  'VO.BulkActions.SubheadingPluralDynamic':
    'You are going to {0} {1} incidents.',
  'VO.BulkActions.UnActionableDynamic':
    'Note: {0} of the selected incidents cannot be {1} because',
  'VO.BulkActions.UnActionableStatusDynamic': '1 incident is already {0}', // 0 is incident status
  'VO.BulkActions.UnActionableStatusPluralDynamic':
    '{0} incidents are already {1}', // 1 is status
  'VO.BulkActions.UnActionableSnoozedDynamic': '{0} incident is snoozed',
  'VO.BulkActions.UnActionableSnoozedPluralDynamic':
    '{0} incidents are snoozed',
  'VO.BulkActions.UnActionableTriggered': '1 incident is triggered',
  'VO.BulkActions.UnActionableTriggeredPluralDynamic':
    '{0} incidents are triggered',
  'VO.BulkActions.SuccessPluralDynamic': 'Successfully {0} {1} incidents',
  'VO.BulkActions.SuccessDynamic': 'Successfully {0} 1 incident',
  'VO.BulkActions.ErrorDynamic':
    'There was an error trying to {0} selected incidents',

  /**
   * Select All
   */
  'VO.SelectAll.DeselectAll': 'Clear Selection',
  'VO.SelectAll.SelectAllDynamic': 'Select all {0} incidents',
  'VO.SelectAll.CurrentSelectionPage':
    'Only the one incident on this page has been selected.',
  'VO.SelectAll.CurrentSelectionPagePluralDynamic':
    'All {0} incidents on this page are selected.',
  'VO.SelectAll.CurrentSelectionAllDynamic': 'All {0} incidents are selected.',

  // Integrations
  'VO.Integrations.StatusPage': 'Status Page',

  // People Pane
  'VO.PeoplePane.AllMembers': 'All Members',
  'VO.PeoplePane.AlwaysOnCall': 'Always on-call',
  'VO.PeoplePane.ClearTake': 'Clear Take',
  'VO.PeoplePane.ContinueSearch': 'Continue search across all teams.',
  'VO.PeoplePane.CurrentlyOnCall': 'Currently On-Call:',
  'VO.PeoplePane.ErrorMessage': 'An error has occurred in the people pane',
  'VO.PeoplePane.Loading': 'Loading People Pane',
  'VO.PeoplePane.NextOnCall': 'Next On-Call:',
  'VO.PeoplePane.NoResultsFound': 'No results found.',
  'VO.PeoplePane.NotOnCallCurrently': 'Not on-call currently.',
  'VO.PeoplePane.NotOnCallFuture': 'Not scheduled to be on-call in the future.',
  'VO.PeoplePane.OnCall': 'On-Call',
  'VO.PeoplePane.OnCallDataError':
    "There was an error retrieving this user's on-call data.",
  'VO.PeoplePane.OnCallStart': 'Starts {0}',
  'VO.PeoplePane.OnCallUntil': 'On-call {0} until {1}',
  'VO.PeoplePane.OnlyMe': 'Only Me',
  'VO.PeoplePane.PagingIncidents': 'Paging for 1 incident',
  'VO.PeoplePane.PagingIncidentsPluralDynamic': 'Paging for {0} incidents',
  'VO.PeoplePane.ProfileLink': 'Profile',
  'VO.PeoplePane.SearchFilterPlaceholder': 'Search People',
  'VO.PeoplePane.SearchingAllTeams': 'Searching all teams',
  'VO.PeoplePane.SearchingTeam': 'Searching 1 team',
  'VO.PeoplePane.SearchingTeamPluralDynamic': 'Searching {0} teams',
  'VO.PeoplePane.ShowMore': 'Show 1 more user',
  'VO.PeoplePane.ShowMorePluralDynamic': 'Show {0} more users',
  'VO.PeoplePane.TakeBack': 'Take Back',
  'VO.PeoplePane.TakeShift': 'Take Shift',
  'VO.PeoplePane.TeamsHeading': 'Teams',

  // Status Page dropdown
  'VO.StatusPage.Heading': 'Update Statuspage.io',
  'VO.StatusPage.OpenIncidents': 'Active Statuspage.io incidents',
  'VO.StatusPage.DegradedComponents': 'Degraded Statuspage.io Components',
  'VO.StatusPage.ComponentEmpty':
    'You have no degraded Statuspage.io components.',
  'VO.StatusPage.IncidentsEmpty': 'You have no active Statuspage.io incidents.',
  'VO.StatusPage.CreateIncident': 'Create Incident',
  'VO.StatusPage.UpdateComponents': 'Update Components',
  'VO.StatusPage.Link': 'Visit your Statuspage.io',
  'VO.StatusPage.Status': 'Status',
  'VO.StatusPage.Message': 'Message',
  'VO.StatusPage.LastUpdate': 'Last Update',
  'VO.StatusPage.UpdatedBy': 'Updated By',

  // Statuspage.io Edit/Update Modal
  'VO.Modal.Statuspageio.IncidentTemplate': 'StatusPage.io Incident Template',
  'VO.Modal.Statuspageio.IncidentDetails': 'StatusPage.io Incident Details',
  'VO.Modal.Statuspageio.EnterSpioIncidentName': 'Enter SPIO incident name...',
  'VO.Modal.Statuspageio.EnterMessageBody': 'Enter message body...',
  'VO.Modal.Statuspageio.AlertUsersSubscribed': 'Alert Users Subscribed:',
  'VO.Modal.Statuspageio.Status.Identified': 'Identified',
  'VO.Modal.Statuspageio.Status.Investigating': 'Investigating',
  'VO.Modal.Statuspageio.Status.Monitoring': 'Monitoring',
  'VO.Modal.Statuspageio.Status.Resolved': 'Resolved',
  'VO.Modal.Statuspageio.Button.Create': 'Create Incident',
  'VO.Modal.Statuspageio.Button.Update': 'Update Incident',
  'VO.Modal.Statuspageio.CreateIncident': 'Create a StatusPage.io Incident',
  'VO.Modal.Statuspageio.UpdateIncident': 'Update a StatusPage.io Incident',
  'VO.Modal.Statuspageio.UpdateComponent': 'Update Statuspage.io Components',
  'VO.Modal.Statuspageio.UpdateComponentSuccess':
    'Successfully updated components.',
  'VO.Modal.Statuspageio.UpdateComponentError': 'Error Updating components.',

  // Statuspage.io error message
  'VO.Statuspageio.FetchErrorMessage':
    'We encountered a problem trying to connect to statuspage.io. Please try again in a few minutes.',
  'VO.Statuspageio.404ErrorMessage':
    'We encountered a problem trying to connect to statuspage.io. Have you set it up?',

  // Error pages
  'VO.ErrorPage.404Title': 'Page Not Found (404)',
  'VO.ErrorPage.403Title': 'Forbidden (403)',
  'VO.ErrorPage.404Message': 'It looks like this page does not exist.',
  'VO.ErrorPage.403Message': 'You do not have permission to access this page.',

  /**
   * note: Status Page Component Statuses
   */
  'VO.StatusPage.ComponentStatus.Operational': 'Operational',
  'VO.StatusPage.ComponentStatus.MajorOutage': 'Major Outage',
  'VO.StatusPage.ComponentStatus.PartialOutage': 'Partial Outage',
  'VO.StatusPage.ComponentStatus.DegradedPerformance': 'Degraded Performance',
  'VO.StatusPage.ComponentStatus.UnderMaintenance': 'Under Maintenance',

  /**
   * note: Trial Banner
   */
  'VO.TrialBanner.ExtendTrialEmailSubject': 'Extend My Trial, Please!',
  'VO.TrialBanner.ExpiresInOneDayHeader': ' expires in 1 day. ',
  'VO.TrialBanner.ExpiresIn#Days': ' expires in {0} days.', // {0} is the number of days
}

/**
 * note: this currently isn't built for performance, but for readability.
 */
export function translate(
  stringKey: keyof typeof englishStrings,
  ...params: (string | number)[]
): string {
  let sentence = englishStrings[stringKey]
  if (!englishStrings[stringKey]) {
    // eslint-disable-next-line no-console
    console.error('english string key was not found: ', stringKey)
  }
  if (params) {
    params.forEach((param: string | number, index: number) => {
      sentence = sentence.replace(`{${index}}`, param.toString())
    })
  }

  return sentence
}

export const getIsPlural = (count: number) => count !== 1
