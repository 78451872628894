import { getLocationHost } from '../window'

export const websocketChatUrl = `wss://${getLocationHost()}/chatsession`

const getRequestAlertData = (id: string) => ({
  MESSAGE: 'ALERT_DETAIL_REQUEST_MESSAGE',
  TRANSACTION_ID: '',
  PAYLOAD: {
    VO_UUID: id,
  },
})

const getRequestTimelineInit = (roomId: string, { count = 250 } = {}) => ({
  MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
  TRANSACTION_ID: '',
  PAYLOAD: {
    ROOM_ID: roomId,
    SELECT_FROM_END: {
      LIMIT_SEQUENCE: 0,
      MAGNITUDE: count,
    },
  },
})

const getRequestTimelineFrom = (
  roomId: string,
  from: number,
  { count = 250 } = {}
) => ({
  MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
  TRANSACTION_ID: '',
  PAYLOAD: {
    ROOM_ID: roomId,
    SELECT_BY_SEQUENCE_RANGE: {
      REF_SEQUENCE: from - 1,
      LIMIT_SEQUENCE: 0,
      MAGNITUDE: count,
    },
  },
})

const getRequestIncidentDetails = (incidentId: string) => ({
  MESSAGE: 'INCIDENT_DETAIL_REQUEST_MESSAGE',
  TRANSACTION_ID: '',
  PAYLOAD: {
    INCIDENT_ID: parseInt(incidentId, 10),
  },
})

const getRequestIncident = (incidentId: string) => ({
  MESSAGE: 'INCIDENT_REQUEST',
  TRANSACTION_ID: '',
  PAYLOAD: {
    INCIDENT_NAMES: [incidentId],
  },
})

const getSendChatMessage = (roomId: string, text: string) => ({
  MESSAGE: 'CHAT_ACTION_MESSAGE',
  TRANSACTION_ID: '',
  PAYLOAD: {
    CHAT: { ROOM_ID: roomId, TEXT: text, IS_ROBOT: false, IS_ONCALL: false },
  },
})

const websocketPingMessage = { MESSAGE: 'PING', TRANSACTION_ID: '' }

export const websocketMessages = {
  getRequestAlertData,
  getRequestTimelineInit,
  getRequestTimelineFrom,
  getRequestIncidentDetails,
  getRequestIncident,
  getSendChatMessage,
  websocketPingMessage,
}
