import React, { memo, CSSProperties, FunctionComponent } from 'react'
import PropTypes from 'prop-types'

import { Row } from './row-renderer-styles'
import { RowCard } from './row-card'

export interface RowRendererProps {
  dataSetID: string
  id: number
  resize?(id: number): null
  style?: CSSProperties
}

export const RowRenderer: FunctionComponent<RowRendererProps> = memo(
  ({ dataSetID, id, resize, style }) => (
    <Row
      style={{ ...style, boxSizing: 'border-box' }}
      data-ext={`timeline-row-${id}`}
    >
      <RowCard {...{ dataSetID, id, resize }} />
    </Row>
  )
)

RowRenderer.defaultProps = {
  resize: undefined,
  style: undefined,
}

RowRenderer.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  resize: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
