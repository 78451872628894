import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'

import { Content, Date, Header, Message } from '../styles'
import { TitleWithIcon } from '../components'
import { ChatContainer } from './card-chat-styles'

export interface CardChatProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  date: string
  iconPath?: string
  message: string
  service?: string
  titleText?: string
}

export const CardChat: FunctionComponent<CardChatProps> = memo(
  ({ date, iconPath, message, service, titleText, ...rest }) => (
    <ChatContainer data-ext='card-chat' {...rest}>
      <Content>
        <Header>
          <TitleWithIcon monitorTool={service} {...{ iconPath, titleText }} />
          <Date>{date}</Date>
        </Header>
        <Message>{message}</Message>
      </Content>
    </ChatContainer>
  )
)

CardChat.defaultProps = {
  iconPath: undefined,
  service: '',
  titleText: '',
}

CardChat.propTypes = {
  date: PropTypes.string.isRequired,
  iconPath: PropTypes.string,
  message: PropTypes.string.isRequired,
  service: PropTypes.string,
  titleText: PropTypes.string,
}
