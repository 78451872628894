import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PresetConferenceBridgeDTO } from 'utils'

const initialState: PresetConferenceBridgeDTO[] = []

const presetConferenceBridgesSlice = createSlice({
  name: 'presetConferenceBridges',
  initialState,
  reducers: {
    setPresetConferenceBridges: (
      state,
      { payload }: PayloadAction<PresetConferenceBridgeDTO[]>
    ) => {
      return [...payload]
    },
  },
})
export const {
  setPresetConferenceBridges,
} = presetConferenceBridgesSlice.actions
export const { reducer: presetConferenceBridges } = presetConferenceBridgesSlice
