import { useCallback, useRef } from 'react'
import ReconnectingWebsocket from 'reconnecting-websocket'

import { logError } from 'utils/log-error'
import { WebsocketChatMessage } from './websocket-chat-types'
import { websocketMessages } from './api'

export const parseData = (data: string) => {
  /* The data will come as a prefixed string and we
     want to start parsing at the start of the object */
  try {
    const dataJSON = JSON.parse(data.slice(data.indexOf('{')))
    if (!dataJSON.MESSAGE) {
      logError('unknown message type from socket:', { info: dataJSON })
      return null
    }

    return dataJSON
  } catch (error) {
    logError(error, { info: { dataString: data } })
    return null
  }
}

// store of all websocket connections and subscriptions
const websockets: Record<string, { connection: ReconnectingWebsocket }> = {}

export const useWebsocket = (url: string) => {
  const websocketsRef = useRef(websockets)

  const sendMessage = useCallback(
    (message) => {
      try {
        websocketsRef.current[url].connection.send(JSON.stringify(message))
      } catch (error) {
        logError(error, { info: message })
      }
    },
    [url]
  )

  /**
   * Creates a new WebSocket connection.
   * @param onMessage A function to be called whenever a message is received on the websocket
   * @param onOpen A function to be called whenever the websocket becomes successfully connected
   * @param onReady A function to be called whenever the websocket becomes fully ready to use
   * @param onClose A function to be called whenever the websocket closes
   */
  const connect = (
    onMessage: (data: WebsocketChatMessage) => void,
    onOpen: () => void,
    onReady: () => void,
    onClose: () => void
  ) => {
    websocketsRef.current[url] = { connection: null }
    // instantiate new socket in the ref object
    const websocket = new ReconnectingWebsocket(url, [], {
      // to enable debugging, set item and relaod
      debug: sessionStorage.getItem('debugWebsockets') === 'true',
      /* The default for minReconnectDelay is random, so we override.
           If the value is too small, multiple reconnects will fire before
           it gets the chance to receive a response.
           https://github.com/pladaria/reconnecting-websocket#default-values */
      maxReconnectionDelay: 60000,
      minReconnectionDelay: 100,
      reconnectionDelayGrowFactor: 10,
      maxRetries: 10,
    })

    // Periodically send ping message to server to keep the connection alive
    let pingIntervalID: null | number = null
    const setupPing = () =>
      setInterval(
        () => sendMessage(websocketMessages.websocketPingMessage),
        5000
      )
    const clearPing = () => clearInterval(pingIntervalID)

    websocket.onopen = () => {
      pingIntervalID = setupPing()
      onOpen()
    }

    websocket.onclose = () => {
      onClose()
      clearPing()
    }

    websocket.addEventListener('message', (message) => {
      const data = parseData(message.data)
      // We need to avoid sending any messages across the websocket until the LOGIN_REPLY_MESSAGE
      // has been received.
      if (data.MESSAGE === 'LOGIN_REPLY_MESSAGE') {
        // Signal that the socket is now ready to use
        onReady()
      }
      onMessage(data)
    })

    websocketsRef.current[url].connection = websocket

    return websocketsRef.current[url].connection
  }

  return { connect, sendMessage }
}
