import React, { memo, FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { State } from 'app/reducer'
import { CardChat } from '../../cards'

export interface RowStakeholderProps {
  dataSetID: string
  id: number
}

export const RowStakeholder: FunctionComponent<RowStakeholderProps> = memo(
  ({ dataSetID, id }) => {
    const date = useSelector(
      (state: State) => state.websocketChat[dataSetID].messages[id].date
    )
    const title = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id].stakeholderMessageTitle
    )
    const stakeholderMessageRecipientsDispNames = useSelector(
      (state: State) =>
        state.websocketChat[dataSetID].messages[id]
          .stakeholderMessageRecipientsDispNames
    )

    const message = `Stakeholder Message sent to ${stakeholderMessageRecipientsDispNames.join(
      ', '
    )}: "${title}"`

    return (
      <CardChat
        {...{
          date,
          message,
        }}
      />
    )
  }
)

RowStakeholder.propTypes = {
  dataSetID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
