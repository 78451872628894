import styled from 'styled-components'

export const Message = styled.span`
  color: #7e7e7e;
  font-size: ${({ theme }) => theme.functions.getSpace(2, -2)};
  overflow: hidden;
  padding-right: ${({ theme }) => theme.functions.getSpace(2)};
  text-overflow: ellipsis;
  white-space: nowrap;
`
