import { fetchAPI, fetchAPIReporting } from './common'

export interface UserDTO {
  username: string
  firstName: string
  lastName: string
  roles?: string[]
}

export interface UserDTORaw {
  username: string
  // from server
  /* eslint-disable camelcase */
  first_name: string
  last_name: string
  /* eslint-enable camelcase */
}
/**
 * Abstract all REST calls into functions.
 * These will get broken out into an API library.
 */
export const fetchSuggestedResponders = (
  orgSlug: string,
  entityDisplayName: string,
  entityId: string,
  hostName: string,
  monitoringTool: string,
  routingKey: string
) =>
  fetchAPIReporting(
    `v1/org/${orgSlug}/suggestedResponders?entity_display_name=${entityDisplayName}&entity_id=${entityId}&host_name=${hostName}&monitoring_tool=${monitoringTool}&routing_key=${routingKey}`
  ).then((response) =>
    response.json().then((fromServer) =>
      fromServer.map((user: UserDTORaw) => ({
        firstName: user.first_name,
        lastName: user.last_name,
        username: user.username,
      }))
    )
  )

export const fetchUsers = (orgSlug: string, searchQuery?: string) => {
  const queryString = searchQuery ? `?search=${searchQuery}` : ''

  return fetchAPI(`v1/org/${orgSlug}/users${queryString}`).then((response) => {
    return response.json().then((fromServer: UserDTO[]) => {
      return fromServer
    })
  })
}

export const fetchUser = (orgSlug: string, username: string) => {
  return fetchAPI(`v1/org/${orgSlug}/users/${username}`).then((response) =>
    response.json()
  )
}
