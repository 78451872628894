import React, {
  FunctionComponent,
  memo,
  MouseEvent as ReactMouseEvent,
} from 'react'
import PropTypes from 'prop-types'
import { ButtonAction, TableBody } from '@victorops/victoria'

import {
  AlertDetailsTable,
  Container,
  Footer,
  Header,
} from './table-display-styles'
import { Row, RowProps } from './components'

export interface TableProp {
  header?: string
  fields: Record<string, RowProps['text']>
}

export interface TableDisplayProps extends TableProp {
  hasShowHide?: boolean
  id: string
  isShowingNullFields?: boolean
  nullFieldCount?: number
  onNullFieldClick?(event: ReactMouseEvent<HTMLButtonElement, MouseEvent>): void
}

export const TableDisplay: FunctionComponent<TableDisplayProps> = memo(
  ({
    fields,
    hasShowHide,
    header,
    id,
    isShowingNullFields,
    nullFieldCount,
    onNullFieldClick,
  }) => {
    const getRows = (json: TableDisplayProps['fields']) =>
      Object.keys(json).map(
        (key) =>
          !key.match(/^vo_annotate/) && (
            <Row key={key} text={json[key]} title={key} />
          )
      )

    return (
      <Container>
        {header && <Header>{header}</Header>}
        <AlertDetailsTable>
          <TableBody>{getRows(fields)}</TableBody>
        </AlertDetailsTable>
        {hasShowHide && (
          <Footer>
            <ButtonAction
              colorPrimary='primary'
              className='link'
              data-ext={`${id}-${
                isShowingNullFields ? 'hide' : 'show'
              }-null-fields`}
              onClick={onNullFieldClick}
              title={`${isShowingNullFields ? 'Hide' : 'Show'} Null Fields`}
            >
              {isShowingNullFields ? 'Hide' : 'Show'} Null Fields (
              {nullFieldCount})
            </ButtonAction>
          </Footer>
        )}
      </Container>
    )
  }
)

TableDisplay.defaultProps = {
  hasShowHide: false,
  header: undefined,
  isShowingNullFields: false,
  nullFieldCount: 0,
  onNullFieldClick: undefined,
}

TableDisplay.propTypes = {
  fields: PropTypes.objectOf(PropTypes.string).isRequired,
  hasShowHide: PropTypes.bool,
  header: PropTypes.string,
  id: PropTypes.string.isRequired,
  isShowingNullFields: PropTypes.bool,
  nullFieldCount: PropTypes.number,
  onNullFieldClick: PropTypes.func,
}
