import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'app/reducer'
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns'
import { getCurrentDate, getIsPlural } from 'entry/utils'

export const CountdownTimer = () => {
  const trialEnd = useSelector((state: State) => state.currentOrg.trialEnd)
  const [ticker, setTicker] = useState(getCurrentDate())

  useEffect(() => {
    const timer = setInterval(() => setTicker(getCurrentDate()), 1000)
    return () => clearTimeout(timer)
  }, [])

  const hours = differenceInHours(trialEnd, ticker)
  const minutes = differenceInMinutes(trialEnd, ticker) % 60
  const seconds = differenceInSeconds(trialEnd, ticker) % 60

  const hoursText = getIsPlural(hours) ? `${hours} hours` : `${hours} hour`
  const minutesText = getIsPlural(minutes)
    ? `${minutes} minutes`
    : `${minutes} minute`
  const secondsText = getIsPlural(seconds)
    ? `${seconds} seconds.  `
    : `${seconds} second.  `

  return (
    <span data-ext='vo.oi.countdown-timer'>
      {' '}
      expires in {hoursText} {minutesText} {secondsText}{' '}
    </span>
  )
}
