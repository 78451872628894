import { OnCallShift } from '../websocket'
import { fetchAPI } from './common'

// // Declared from network
/* eslint-disable camelcase */
export interface CurrentShiftTakeDTO {
  end_time: number
  name: string
  original_user: string
  override_user: string
  policyName: string
  policySlug: string
  slug: string
}

export interface OnCallShiftRESTDTO {
  name: string
  policyName: string
  policySlug: string
  slug: string
  when: number
}

export interface OnCallShiftsDTO {
  current_takes: CurrentShiftTakeDTO[]
  next_off_call: OnCallShiftRESTDTO[]
  next_on_call: OnCallShiftRESTDTO[]
}

export interface OnCallShifts {
  current: OnCallShift[]
  next: OnCallShift[]
}

export const transformOnCalls = (
  username: string,
  {
    current_takes: currentOnCallTakes,
    next_off_call: currentOnCallShifts,
    next_on_call: nextOnCallShifts,
  }: OnCallShiftsDTO
): OnCallShifts => ({
  current: [
    ...currentOnCallShifts
      .filter(
        ({ when, policySlug: ocsPolicySlug }) =>
          // This filter prevents duplicates in the on-call (server sends a current oncall and a take that are redundant)
          !currentOnCallTakes.find(
            ({ end_time, policySlug: octPolicySlug }) =>
              end_time === when && ocsPolicySlug === octPolicySlug
          )
      )
      .map(
        ({ name: teamName, policyName, policySlug, slug: teamSlug, when }) => ({
          policyName,
          policySlug,
          teamName,
          teamSlug,
          when,
          username,
        })
      ),
    ...currentOnCallTakes
      .filter(
        ({ override_user }) =>
          // This filter prevents a person being shown as being on-call for a shift that another user took from them
          !override_user || override_user === username
      )
      .map(
        ({
          end_time: when,
          name: teamName,
          policyName,
          policySlug,
          slug: teamSlug,
          original_user: originalUser,
          override_user: overrideUser,
        }) => ({
          originalUser,
          overrideUser,
          policyName,
          policySlug,
          teamName,
          teamSlug,
          username,
          when,
        })
      ),
  ],
  next: nextOnCallShifts.map(
    ({ name: teamName, policyName, policySlug, slug: teamSlug, when }) => ({
      policyName,
      policySlug,
      teamName,
      teamSlug,
      username,
      when,
    })
  ),
})
/* eslint-enable camelcase */

/**
 * Get the on-call shifts for a user.
 *
 * @param orgSlug - current org
 * @param username - user to get on-call shifts for
 *
 */
export const fetchOnCallShifts = (orgSlug: string, username: string) =>
  fetchAPI(`v2/user/${username}/${orgSlug}/next_on_call`).then((response) =>
    response
      .json()
      .then((jsonResponse) => transformOnCalls(username, jsonResponse))
  )
