import React, {
  FunctionComponent,
  memo,
  MouseEvent as ReactMouseEvent,
  PropsWithoutRef,
} from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/pro-light-svg-icons'

import { translate } from 'utils'
import { ActionButton } from 'styles'
import { AlertDetails, AlertDetailsProps } from '../../../../../alert-details'
import { Container, Content, Date, Header, Message } from '../styles'
import { TitleWithIcon } from '../components'
import { AlertType, Footer, StateMessage } from './card-alert-styles'

// TODO CardAlertType and CardAlertTypeDisplay may be redundant.  Maybe convert to an enum?
export type AlertType = 'critical' | 'info' | 'recovery' | 'warning'
export type AlertTypeDisplay = 'Critical' | 'Info' | 'Recovery' | 'Warning'

export interface CardAlertProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  alertType?: AlertType
  alertTypeDisplay?: AlertTypeDisplay
  annotations?: AlertDetailsProps['annotations']
  date: string
  from?: string
  iconPath?: string
  id: string
  isExpanded?: boolean
  isShowingNullFields?: boolean
  message?: string
  monitorTool?: string
  nullFieldCount?: number
  onExpandClick(event: ReactMouseEvent<HTMLButtonElement, MouseEvent>): void
  onToggleNullFieldsClick(
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ): void
  stateMessage?: string
  strippedTables?: AlertDetailsProps['tables']
  tables?: AlertDetailsProps['tables']
  titleText: string
  hostDisplayName?: string
  hostState?: string
  hostOutput?: string
}

export const CardAlert: FunctionComponent<CardAlertProps> = memo(
  ({
    hostDisplayName,
    hostState,
    hostOutput,
    alertType,
    alertTypeDisplay,
    annotations,
    date,
    from,
    iconPath,
    id,
    isExpanded,
    isShowingNullFields,
    message,
    monitorTool,
    nullFieldCount,
    onExpandClick,
    onToggleNullFieldsClick,
    stateMessage,
    strippedTables,
    tables,
    titleText,
    ...rest
  }) => (
    <Container data-ext='card-alert' {...rest}>
      <Content>
        <Header>
          <TitleWithIcon {...{ iconPath, monitorTool, titleText }} />
          <Date>{date}</Date>
        </Header>
        <Message>
          <AlertType color={alertType}>{alertTypeDisplay}:</AlertType> {message}
        </Message>
        {from && (
          <span>
            {translate('VO.CardAlert.From')}: {from}
          </span>
        )}
        <StateMessage>{stateMessage}</StateMessage>
        {monitorTool === 'NAGIOS' ? (
          <StateMessage>
            <div>Host: {hostDisplayName}</div>
            <div>State: {hostState}</div>
            <div>Output: {hostOutput}</div>
          </StateMessage>
        ) : (
          ''
        )}
        <Footer>
          <ActionButton
            colorPrimary='splunkCobalt'
            data-ext={`${id}-alert-payload-button`}
            onClick={onExpandClick}
            title='Expand Alert Payload'
          >
            <FontAwesomeIcon icon={isExpanded ? faAngleDown : faAngleRight} />
            <span>{translate('VO.CardAlert.AlertPayload')}</span>
          </ActionButton>
        </Footer>
      </Content>
      {isExpanded && (
        <AlertDetails
          tables={isShowingNullFields ? tables : strippedTables}
          {...{
            annotations,
            id,
            isShowingNullFields,
            nullFieldCount,
            onToggleNullFieldsClick,
          }}
        />
      )}
    </Container>
  )
)

CardAlert.defaultProps = {
  alertType: 'info',
  alertTypeDisplay: 'Info',
  annotations: undefined,
  from: undefined,
  iconPath: undefined,
  isExpanded: false,
  isShowingNullFields: false,
  message: '',
  monitorTool: undefined,
  nullFieldCount: 0,
  stateMessage: '',
  strippedTables: undefined,
  tables: undefined,
  hostDisplayName: undefined,
  hostState: undefined,
  hostOutput: undefined,
}

CardAlert.propTypes = {
  alertType: PropTypes.oneOf(['critical', 'info', 'recovery', 'warning']),
  alertTypeDisplay: PropTypes.oneOf([
    'Critical',
    'Info',
    'Recovery',
    'Warning',
  ]),
  annotations: PropTypes.arrayOf(PropTypes.any),
  date: PropTypes.string.isRequired,
  from: PropTypes.string,
  iconPath: PropTypes.string,
  id: PropTypes.string.isRequired,
  isShowingNullFields: PropTypes.bool,
  isExpanded: PropTypes.bool,
  message: PropTypes.string,
  monitorTool: PropTypes.string,
  nullFieldCount: PropTypes.number,
  onExpandClick: PropTypes.func.isRequired,
  onToggleNullFieldsClick: PropTypes.func.isRequired,
  stateMessage: PropTypes.string,
  titleText: PropTypes.string.isRequired,
  strippedTables: PropTypes.arrayOf(PropTypes.any),
  tables: PropTypes.arrayOf(PropTypes.any),
  hostDisplayName: PropTypes.string,
  hostOutput: PropTypes.string,
  hostState: PropTypes.string,
}
