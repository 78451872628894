import styled from 'styled-components'

export const Icon = styled.img`
  height: 100%;
  width: 100%;
`

export const IconContainer = styled.div`
  position: relative;
  height: ${({ theme }) => theme.functions.getSpace(3)};
  width: ${({ theme }) => theme.functions.getSpace(3)};

  > :not(:first-child) {
    bottom: 0;
    position: absolute;
    right: 0;
  }
`
