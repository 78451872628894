import styled from 'styled-components'

export const NewDataDisplayContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: ${({ theme }) => theme.functions.getSpace(4)};
  border-radius: ${({ theme }) => theme.functions.getBorderRadius()};
  background-color: ${({ theme }) => theme.palette.splunkTeal};
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.functions.getSpace(2)};
  color: ${({ theme }) => theme.palette.white};

  &:hover {
    background-color: ${({ theme }) => theme.palette.splunkTeal1};
  }

  /* To be above the timeline cards */
  z-index: 1;
`
export const NewDataDisplayArrow = styled.div`
  margin-left: ${({ theme }) => theme.functions.getSpace()};
  display: inline-block;
`
