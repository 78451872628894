import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'

import { Container, Content } from '../styles'
import { GradientBar } from './card-loading-styles'

export const CardLoading: FunctionComponent<PropsWithoutRef<
  JSX.IntrinsicElements['div']
>> = memo(({ ...rest }) => (
  <Container data-ext='card-loading' {...rest}>
    <Content>
      <GradientBar width='20%' />
      <GradientBar />
      <GradientBar />
      <GradientBar />
    </Content>
  </Container>
))
