import React, { FunctionComponent, memo, PropsWithoutRef } from 'react'
import PropTypes from 'prop-types'
import { ImageAnnotation } from './image-annotation'
import { LinkAnnotation } from './link-annotation'
import { TextAnnotation } from './text-annotation'

export interface AnnotationProps
  extends PropsWithoutRef<JSX.IntrinsicElements['li']> {
  annotationType: string
  fieldName: string
  fieldValue: string
}

export interface AnnotationPropsReceived extends AnnotationProps {
  dataExt: string
}

// Possible annotation type values are i, u, and s
// i describes an image annotation
// u describes a link annotation
// s describes a text annotation
export const Annotation: FunctionComponent<AnnotationPropsReceived> = memo(
  ({ annotationType, fieldName, fieldValue, dataExt }) => {
    switch (annotationType) {
      case 'i':
        return (
          <ImageAnnotation
            fieldName={fieldName}
            fieldValue={fieldValue}
            dataExt={dataExt}
          />
        )
      case 'u':
        return (
          <LinkAnnotation
            fieldName={fieldName}
            fieldValue={fieldValue}
            dataExt={dataExt}
          />
        )
      default:
        return (
          <TextAnnotation
            fieldName={fieldName}
            fieldValue={fieldValue}
            dataExt={dataExt}
          />
        )
    }
  }
)

Annotation.propTypes = {
  annotationType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  dataExt: PropTypes.string.isRequired,
}
