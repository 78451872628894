import { createSlice } from '@reduxjs/toolkit'

const initialState = false

const ollySlice = createSlice({
  name: 'isOlly',
  initialState,
  reducers: {},
})

export const { reducer: isOlly } = ollySlice
