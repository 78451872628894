import React, {
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from '@victorops/victoria'

import { getCurrentDate, logError } from 'utils'
import { Loading } from '../../loading'
import { DateContext, useTimeline } from '../utils'
import { Container } from './stakeholder-timeline-styles'
import { RowRenderer } from './components'

export interface MessagesTimelineProps {
  dataSetID: string
}

const StakeholderTimelineComponent: FunctionComponent<MessagesTimelineProps> = memo(
  ({ dataSetID }) => {
    const { dataIDs, isLoading, newDataIDs } = useTimeline(dataSetID)

    const refreshTimeInterval = useRef(null)
    const [baseDate, setBaseDate] = useState(getCurrentDate())

    useEffect(() => {
      refreshTimeInterval.current = setInterval(
        () => setBaseDate(getCurrentDate()),
        30000
      )

      return () => clearInterval(refreshTimeInterval.current)
    }, [])

    return (
      <DateContext.Provider value={baseDate}>
        <Container aria-busy={isLoading} aria-labelledby='messages-heading'>
          {isLoading ? (
            <Loading />
          ) : (
            [...newDataIDs, ...dataIDs].map((id) => (
              <RowRenderer
                key={id}
                {...{
                  dataSetID,
                  id,
                }}
              />
            ))
          )}
        </Container>
      </DateContext.Provider>
    )
  }
)

StakeholderTimelineComponent.propTypes = {
  dataSetID: PropTypes.string.isRequired,
}

export const StakeholderTimeline: FunctionComponent<MessagesTimelineProps> = (
  props
) => (
  <ErrorBoundary {...{ logError }}>
    <StakeholderTimelineComponent {...props} />
  </ErrorBoundary>
)
