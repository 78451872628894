import { postAPI } from './common'

export const postSnoozeAPI = (
  orgSlug: string,
  incident: string,
  snoozeUntil: number
) =>
  postAPI(`v1/org/${orgSlug}/incidents/snooze`, {
    incident,
    snoozeUntil,
  })

/**
 * function postAcknowledgeAPI() - calls REST endpoint for setting events to acknowledged
 * @param orgSlug required - orgslug for incidents in question
 * @param incidents required - array of incident numbers in string form
 * @return response as a promise
 */
export const postAcknowledgeAPI = (orgSlug: string, incidents: string[]) =>
  postAPI(`v1/org/${orgSlug}/incidents/ack`, {
    incidents,
  }).then((response) => response.json())

/**
 * function postResolveAPI() - calls REST endpoint for setting events to resolved
 * @param orgSlug required - orgslug for incidents in question
 * @param incidents required - array of incident numbers in string form
 * @return response as a promise
 */
export const postResolveAPI = (orgSlug: string, incidents: string[]) =>
  postAPI(`v1/org/${orgSlug}/incidents/resolve`, {
    incidents,
  }).then((response) => response.json())
