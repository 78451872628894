import cloneDeep from 'lodash/cloneDeep'

import { fetchAPI, postAPI } from './common'

export interface IncidentNoteDTO {
  valueDetail: { [key: string]: unknown }
}

export interface IncidentNote {
  displayValue: string
  value: string
  valueDetail: { [key: string]: string }
}

export interface SimilarIncidentsWithScoreDTO {
  // fix with https://jira.splunk.com/browse/VOI-116
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incident: Record<string, any>
  score: number
}

export interface SimilarIncidentsDTO {
  similarIncidents: SimilarIncidentsWithScoreDTO[]
}

export interface CorrelatedIncidentsWithTotalDTO {
  correlatedIncidents: CorrelatedIncidentsWithTotalDTO[]
  totalIncidents: number
}

export interface CorrelatedIncidentsDTO {
  // fix with https://jira.splunk.com/browse/VOI-116
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incident: Record<string, any>
}

export const fetchIncidentNotes = (
  orgSlug: string,
  incidentNumber: string
): Promise<IncidentNote[]> =>
  fetchAPI(
    `v1/org/${orgSlug}/incidents/${incidentNumber}/incidentData?category=notes`
  ).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    // per spec, the valueDetail field can be set by a customer to any valid JSON. We need to stringify every value in each JSON key inside.
    // This will help avoid potential JS injection. VIR-449 spikes on this case.
    return response.json().then((json) => {
      const data = cloneDeep(json.incident_data)
      data.forEach((note: IncidentNoteDTO, index: number) => {
        /* eslint-disable-next-line  array-callback-return */
        Object.keys(note.valueDetail).map((key) => {
          data[index].valueDetail[key] = JSON.stringify(note.valueDetail[key])
        })
      })
      return data
    })
  })

export const fetchSimilarIncidents = (
  orgSlug: string,
  incidentId: string
): Promise<SimilarIncidentsDTO> =>
  fetchAPI(
    `v1/org/${orgSlug}/ds/similar-incidents/${incidentId}`
  ).then((response) => response.json())

export const fetchCorrelatedIncidents = (
  orgSlug: string,
  incidentId: string
): Promise<CorrelatedIncidentsWithTotalDTO> =>
  fetchAPI(
    `v1/org/${orgSlug}/ds/correlated-incidents/${incidentId}`
  ).then((response) => response.json())

export const postManualIncident = (
  orgSlug: string,
  // fix with https://jira.splunk.com/browse/VOI-116
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any>
) =>
  postAPI(`v1/org/${orgSlug}/incidents`, payload).then((response) =>
    response.json()
  )
