import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TeamDTO, UserDTO } from 'utils'

export interface Stakeholder {
  title?: string
  message?: string
  incidentId: string
  recipients?: UserDTO[]
  recipientTeams?: TeamDTO[]
}

interface StakeholderInitialState {
  [incidentNumber: string]: {
    title: string
    message: string
    recipients: UserDTO[]
    recipientTeams: TeamDTO[]
  }
}
const initialState: StakeholderInitialState = {}

const stakeholdersSlice = createSlice({
  name: 'stakeholders',
  initialState,
  reducers: {
    setStakeholdersFormData: (
      state,
      { payload }: PayloadAction<Stakeholder>
    ) => {
      const { incidentId } = payload
      return { ...state, [incidentId]: { ...state[incidentId], ...payload } }
    },
  },
})
export const { setStakeholdersFormData } = stakeholdersSlice.actions
export const { reducer: stakeholders } = stakeholdersSlice
